import React from "react";
import "./Development.css";
import devbannerimg1 from '../Image/New Developments Photo.png'

import devimg1 from '../Image/image 756.png'
import devimg2 from '../Image/coex_2 1.png'
import devimg3 from '../Image/pexels-rquiros-2219024 1.png'
import devimg4 from '../Image/image 1849 (1).png'
import devimg5 from '../Image/import_export_turkiye_170423 2.png'
import devimg6 from '../Image/agcs-global-industry-solutions-report-construction-stage (1) 2.png'

import mobdevimg1 from '../Image/image 1848 (3).png'
import mobdevimg2 from '../Image/image 1849 (1).png'
import mobdevimg3 from '../Image/image 756.png'
import mobdevimg4 from '../Image/image 832.png'
import mobdevimg5 from '../Image/image 1850.png'
import mobdevimg6 from '../Image/image 1851.png'



function Development() {
    return (
        <div>
            <div className="container-fluid dev-banner-div1">
                <div className="row">
                    <div className="col-lg-6 dev-banner-col-div1">
                        <div className="dev-banner-text-div1">
                            <h1>New Developments</h1>
                            <p>
                                We're excited to announce that Amplify Lead Solutions will soon introduce a new CRM and Bid Board feature to enhance your estimate and sale capabilities!
                                </p>
                                <p>The upcoming CRM tool will simplify your interactions and lead management while the Bid Board will offer a clear, real-time overview of your upcoming bids and existing pipeline.</p>
                            <div className="d-md-none d-block">
                                <img src={devbannerimg1} className="img-fluid" alt="" />
                            </div>
                                <p>
                                Designed for simplicity and effectiveness, these new interactive CRM and bid board features offer tools to keep you organized and informed about your projects' status. These developments will ultimately help streamline your operations without overcomplicating your processes.
                               </p>
                                <p>
                                Our goal is to empower you with the tools that enable smarter, faster decision-making. By integrating these advanced features, Amplify Lead Solutions aims to provide seamless support throughout the bidding process, from managing client relationships to tracking your most critical projects. Stay ahead of the competition with an organized, efficient workflow that helps you focus on winning more bids and growing your business.
                                 </p>
                            
                            <div>
                                <a href="https://portal.amplifyls.com/leads/registration" target='_blank'>
                                    <div>Sign Up</div>
                                </a>
                            </div>

                            <div className='d-none'>
                                <a href="https://portal.amplifyls.com/leads/registration" target='_blank'>
                                    <div>Sign Up</div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 dev-banner-img-div1 d-md-block d-none">
                        {/* <img src={devbannerimg1} className="" alt="" /> */}
                    </div>
                </div>
            </div>















            {/* ################ Section ##### 2 ##################### */}


{/* 
            <div className='container-fluid dev-sec2-div1 d-md-block d-none'>
                <div className="dev-img-main-div1">
                    <div className="dev-img-1">
                        <img src={devimg1} alt="" />
                    </div>
                    <div className="dev-img-2">
                        <img src={devimg2} alt="" />
                    </div>
                    <div className="dev-img-3">
                        <img src={devimg3} alt="" />
                    </div>
                    <div className="dev-img-4">
                        <img src={devimg4} alt="" />
                    </div>
                    <div className="dev-img-5">
                        <img src={devimg5} alt="" />
                    </div>
                    <div className="dev-img-6">
                        <img src={devimg6} alt="" />
                    </div>
                </div>
            </div>
 */}


{/* 
            <div className='container-fluid dev-sec2-div1 d-md-none d-block'>
                <div className="dev-img-main-div1">
                    <div className="dev-img-1">
                        <img src={mobdevimg1} alt="" />
                    </div>
                    <div className="dev-img-2">
                        <img src={mobdevimg2} alt="" />
                    </div>
                    <div className="dev-img-3">
                        <img src={mobdevimg3} alt="" />
                    </div>
                    <div className="dev-img-4">
                        <img src={mobdevimg4} alt="" />
                    </div>
                    <div className="dev-img-5">
                        <img src={mobdevimg5} alt="" />
                    </div>
                    <div className="dev-img-6">
                        <img src={mobdevimg6} alt="" />
                    </div>
                </div>
            </div> */}

        </div>
    );
}

export default Development;
