import React from "react";
import "./Terms.css";

function Terms() {
  return (
    <div>
      <div className="container-fluid terms-div1">
        <div className="container">
          <div>
            <h1 class="c12">
              Terms of Service
            </h1>
            <p class="c2">
              These Terms of Service along with any other terms and policies
              referenced herein, and are incorporated herein by reference and
              form an integral part hereof, as amended from time to time
              ("Terms") constitute a legally binding agreement as of the
              Effective Date (as defined below), governing your access, use,
              registration and receipt of: (i) www.amplifyls.com and any related
              website owned or operated by Amplify ("Sites"); (ii) Amplify
              Services (as defined below) through the Sites, cloud, or through
              any other means; and/or (iii) Amplify Additional Services (as
              defined below) and any ancillary services or products provided in
              connection with the Services. These Terms are between Amplify Lead
              Solutions LLC ("Amplify", "us", "we", "or", "our") and you, either
              individually, or on behalf of your employer or any other entity
              which you represent ( "you" or "your" ). Amplify may use its
              affiliates and third party service providers to process and/or
              collect payment from you. In case you represent your employer or
              any other entity, you hereby represent that (i) you have full
              legal authority to bind your employer or such entity (as
              applicable) to these Terms; and (ii) after reading and
              understanding these Terms, you agree to these Terms on behalf of
              your employer or the respective entity (as applicable), and these
              Terms shall bind your employer or such entity (as the case may
              be). PLEASE NOTE THAT YOU ARE DEEMED AS AN AUTHORIZED
              REPRESENTATIVE OF YOUR EMPLOYER OR AN ENTITY (AS APPLICABLE): (I)
              IF YOU ARE USING YOUR EMPLOYER OR AN ENTITY'S EMAIL ADDRESS IN
              REGISTERING INTO THE SERVICE; OR (II) IF YOU ARE AN ADMIN (AS
              DEFINED BELOW).
            </p>
            <p class="c2">
              AS ELABORATED IN SECTION 2 BELOW, THERE ARE VARIOUS TYPES OF USERS
              IN THE SERVICES, THUS, EXCEPT WHERE INDICATED OTHERWISE
              &ldquo;YOU&rdquo; SHALL REFER TO CUSTOMER AND ALL TYPES OF USERS.
              YOU ACKNOWLEDGE THAT THESE TERMS ARE BINDING, AND YOU AFFIRM AND
              SIGNIFY YOUR CONSENT TO THESE TERMS, BY EITHER: (I) CLICKING ON A
              BUTTON OR CHECKING A CHECKBOX FOR THE ACCEPTANCE OF THESE TERMS;
              OR (II) REGISTERING TO, USING OR ACCESSING THE SERVICES,
              ADDITIONAL SERVICES, SITES OR AMPLIFY MOBILE APPLICATION,
              WHICHEVER IS EARLIER (THE DATE OF SUCH REGISTRATION OR ACCEPTANCE
              BEING THE "EFFECTIVE DATE").
            </p>
            <p class="c2">
              IF YOU DO NOT AGREE TO COMPLY WITH, AND BE BOUND BY, THESE TERMS
              OR DO NOT HAVE AUTHORITY TO BIND YOUR EMPLOYER OR ANY OTHER ENTITY
              (AS APPLICABLE), PLEASE DO NOT ACCEPT THESE TERMS, OR ACCESS OR
              USE THE SERVICES OR THE SITES.
            </p>

            <h2 class="">1. Our Services</h2>

            <p class="c2">
              <span class="">1.1. Our Services</span>
              The Amplify cloud based services includes our platforms, products,
              applications, application programming interface ("API"), tools,
              and any ancillary or supplementary Amplify products and services
              (including Upgrades (as defined below)), offered online
              ("Services").
            </p>
            <p class="c2">
              <span class="">
                1.2. Modification or Discontinuation of Services
              </span>
              We may add, modify or discontinue any feature, functionality or
              any other tool within any Services and/or Sites, at our own
              discretion and without further notice, however, if we make any
              material adverse change in the core functionality of the Services,
              then we will notify you by posting an announcement on the Sites,
              via the Services and/or by sending you an email.
            </p>
            <p class="c2">
              <span class="">
                1.3. No Contingency on Future Releases and Improvements{" "}
              </span>
              You hereby acknowledge that your purchase of the Services and/or
              Third Party Services (as defined below) hereunder are not
              contingent on the delivery by us of any future release of any
              functionality, feature or service, including without limitation:
              (i) the continuance of certain Services or Additional Services (as
              defined below) beyond the current Subscription Term; (ii) the
              availability of Third Party Services, or (iii) dependency on any
              public comments we make, orally or in writing, regarding any
              future functionality, feature or service.
            </p>
            <p class="c2">
              <span class="">1.4. Ability to Accept Terms</span>
              If you access and use the Sites and/or the Services, you represent
              and warrant that you are at least sixteen (16) years old. The
              Sites and/or Services are only intended for individuals aged
              sixteen (16) years or older. We reserve the right to request proof
              of age at any stage so that we can verify compliance with this
              paragraph.
            </p>

            <h2 class="">2. Account Registration and Administration</h2>

            <p class="c2">
              <span class="">2.1. Account Registration</span>
              To register to the Services for the first time, you shall create
              an account for the Services ( "Account"). By creating an Account
              and registering to use the Services you become, either
              individually or on behalf of your employer or any entity, on
              behalf of whom you created the Account, a Amplify customer (
              "Customer" ). The first user of the Account is automatically
              assigned as an Account administrator ( "Admin"). See Section 2.4
              for additional details on Admin's.
            </p>
            <p class="c2">
              <span class="">2.2. Your Registration Information</span>
              When creating an Account or when you are added into an Account and
              creating your user profile ("User Profile"), you: (i) agree to
              provide us with accurate, complete, and current registration
              information about yourself; (ii) acknowledge that it is your
              responsibility to ensure that your password remains confidential
              and secure; (iii) agree that you are fully responsible for all
              activities that occur under your Account, User Profile and
              password, including any integration or any other use of third
              party products or services (and associated disclosure of data) in
              connection with the Services; and (iv) undertake to promptly
              notify us in writing if you become aware of any unauthorized
              access or use of your Account or User Profile and/or any breach of
              these Terms. We may assume that any communications we receive
              under your User Profile have been made by you. You will be solely
              responsible and liable for any losses, damages, liability and
              expenses incurred by us or a third party, due to any unauthorized
              usage of the Account by either you or any other User or third
              party on your behalf.
            </p>
            <p class="c2">
              <span class="">2.3. User Verification</span>. You understand and
              agree that we may require you to provide information that may be
              used to confirm your identity and help ensure the security of your
              Account and/or User Profile. In the event that you or an Admin
              lose access to an Account or otherwise request information about
              an Account, we reserve the right to request from you or from an
              Admin (as the case may be) any verification and/or information,
              including in the form of a release, as we deem necessary in our
              sole discretion, before restoring or providing access to or
              providing information about such Account.
            </p>
            <p class="c2">
              <span class="">2.4. Account Admins</span>. The Admin(s) of an
              Account are, severally and jointly, deemed as the authorized
              representatives of the Customer, and any decision or action made
              by any Admin, is deemed as a decision or action of Customer. An
              Admin may assign or add other members of the Account as Admins,
              which possess important privileges and controls over the use of
              the Services and the Account, including, without limitation: (i)
              control your (and other Users) use of the Account; (ii) purchase,
              upgrade or downgrade Services; (ii) create, monitor or modify
              Users&rsquo; actions and permissions; (iii) manage the access to,
              control, remove, share posts or otherwise change, all or part of
              the Customer Data (as defined below); (iv) assign certain
              privileges to such other Users (as defined below); and (v)
              integrate or disable integration with Third Party Services. You
              also acknowledge that your Account can become managed by a
              representative of the entity that owns or controls the email
              address domain with which your Account was created or registered.
            </p>
            <p class="c2">
              <span class="">2.5. Account Users</span>. There may be several
              types of Account users, such as guests, viewers, members and other
              types of users, all of whom are designated within the Services and
              referred to herein, collectively with the Admin(s) as Users
              &rdquo;. The features, functionalities and privileges available to
              the Users are determined by the respective user role, Services,
              offering and Subscription Plan governing such Account. You shall
              be responsible for ensuring that Users are assigned the
              appropriate user type. If you assign Users not in accordance with
              the appropriate user type (for instance, assigning Users who are
              internal to your organization, your affiliates, subsidiaries
              and/or any other related entities as a guest user, such as having
              guests with such organization&rsquo;s email domain) then Amplify
              shall have the right, in its sole discretion, and without
              derogating any other remedy available hereunder, to reassign such
              user types as appropriate, impose additional restrictions and/or
              charge additional fees.
            </p>
            <p class="c2">
              <span class="">2.5. Customer Responsible for Users</span>.
              Customer is solely liable and responsible for understanding the
              settings, privileges and controls for the Services and for
              controlling whom Customer permits to become a User and what are
              the settings and privileges for such User, including without
              limitation, the right for a User to invite or add other Users
              (either paid or unpaid), the right to incur charges on the
              Account, the right to access, modify or share boards, etc.
              Customer is responsible for the activities of all of its Users,
              including Order Forms they may place and how Users use the
              Customer Data, even if those Users are not from Customer&rsquo;s
              organization or domain. Further, Customer acknowledges that any
              action taken by a User of Customer&rsquo;s Account, is deemed by
              us as an authorized action by Customer, hence Customer shall have
              no claim in this regard.
            </p>

            <h2 class="">3. Your Customer Data</h2>
            <p class="c2">
              <span class="">3.1. Customer Data</span>. Customer Data is any
              data, file attachments, text, images, reports, personal data, or
              any other content, that is uploaded or submitted, transmitted or
              otherwise made available, to or through the Services by you or any
              User and is processed by us on Customer&rsquo;s behalf ( "Customer
              Data"). For the avoidance of doubt, Anonymous Information (as
              defined below) is not regarded as Customer Data. Customer retains
              all right, title, interest and control, in and to the Customer
              Data, in the form submitted to the Services. Subject to these
              Terms, Customer grants us a worldwide, royalty-free, limited
              license to access, use, process, copy, distribute, perform,
              export, and display the Customer Data, and solely to the extent
              that reformatting Customer Data for display in the Services
              constitutes a modification or derivative work, the foregoing
              license also includes the right to make modifications and
              derivative works. The afore-mentioned license is hereby granted
              solely: (i) to maintain and provide you the Services; (ii) to
              prevent or address technical, fraud or security issues and resolve
              support requests; (iii) to investigate when we have a good faith
              belief, or have received a complaint alleging, that such Customer
              Data is in violation of these Terms; (iv) to comply with a valid
              legal subpoena, request, or other lawful process; and (v) as
              expressly permitted in writing by you.
            </p>
            <p class="c2">
              <span class="">
                3.2. Responsibility for Customer Data Compliance
              </span>
              . You represent and warrant that: (i) you have or have obtained
              all rights, licenses, consents, permissions, power and/or
              authority, necessary to grant the rights granted herein, for any
              Customer Data that you submit, post or display on or through the
              Services; (ii) the Customer Data is in compliance with, and
              subject to, our&nbsp;
              <a
                class="c10"
                href="https://www.google.com/url?q=https://monday.com/terms/aup&amp;sa=D&amp;source=editors&amp;ust=1725289878298762&amp;usg=AOvVaw0tet5MreYDlbE8jfnAD4Qj"
              >
                Acceptable Use Policy
              </a>
              ; and (iii) the Customer Data you submit, your use of such
              Customer Data, and our use of such Customer Data, as set forth in
              these Terms, do not and shall not (a) infringe or violate any
              patents, copyrights, trademarks or other intellectual property,
              proprietary or privacy, data protection or publicity rights of any
              third party; (b) violate any applicable local, state, federal and
              international laws, regulations and conventions, including those
              related to data privacy and data transfer and exportation ("Laws"
              ); (c) violate any of your or third party&rsquo;s policies and
              terms governing the Customer Data. Other than our security and
              data protection obligations expressly set forth in Section 6, we
              assume no responsibility or liability for Customer Data, and you
              shall be solely responsible for Customer Data and the consequences
              of using, disclosing, storing, or transmitting it. It is hereby
              clari&#64257;ed that Amplify shall not monitor and/or moderate the
              Customer Data and there shall be no claim against Amplify for not
              doing such.
            </p>
            <p class="c2">
              <span class="">3.3. No Sensitive Data</span>. You shall not submit
              to the Services any data that is protected under a special
              legislation and requires a unique treatment, including, without
              limitations, (i) categories of data enumerated in European Union
              Regulation 2016/679, Article 9(1) or any similar legislation or
              regulation in other jurisdiction; (ii) any protected health
              information subject to the Health Insurance Portability and
              Accountability Act ("HIPAA"), as amended and supplemented, or any
              similar legislation in other jurisdiction; and (iii) credit, debit
              or other payment card data subject to PCI DSS or any other credit
              card schemes.
            </p>
            <h2>4. Public User Submissions</h2>

            <p class="c2">
              <span class="">4.1. Public User Submissions</span>. The Sites may
              have certain features that allow you to submit comments,
              information, and other materials publicly (collectively, Public
              User Submissions) and share such Public User Submissions with
              other Users, or the public. By submitting Public User Submissions
              through the Sites, you grant us a license to access, use, copy,
              reproduce, process, adapt, publish, transmit, host, and display
              that Public User Submissions for any purpose, business, including
              without limitation, for publicizing and promoting Amplify, the
              Services and/or the Sites and for any other lawful purpose, in any
              media format (e.g. in-print, websites. electronically, broadcast),
              and you hereby waive, or to the extent legally prohibited, assign
              to Amplify, any moral rights in your Public User Submissions.
            </p>
            <p class="c2">
              <span class="">
                4.2. Responsibility for Public User Submissions
              </span>
              . You acknowledge and agree that: (i) you have or have obtained
              all rights, licenses, consents, permissions, power and/or
              authority, necessary to grant the rights granted herein, for any
              Public User Submissions that you submit, post or display on or
              through the Services; (ii) we do not control, and are not
              responsible for, other content and/or submissions, posted on our
              Sites and/or Services by others; (iii) by using the Services
              and/or Sites, you may be exposed to content and/or submissions by
              other users or site visitors that is offensive, indecent,
              inaccurate, misleading, or otherwise unlawful; (iv) any Public
              User Submissions are submitted in accordance with, and subject to
              our Acceptable Use Policy.
            </p>
            <h2>5. Intellectual Property Rights; License</h2>
            <p class="c2">
              <span class="">5.1. Our Intellectual Property</span>. The Services
              and Sites, inclusive of materials, such as software, API, apps
              framework, design, design system, text, editorial materials,
              informational text, documentation, photographs, illustrations,
              audio clips, video clips, artwork and other graphic materials, and
              names, logos, trademarks and services marks (excluding Customer
              Data), any and all related or underlying know-how, technology or
              intellectual property, and any modifications, enhancements or
              derivative works of the foregoing (collectively, Amplify
              Materials), are the property of Amplify and its licensors, and may
              be protected by applicable copyright or other intellectual
              property laws and treaties. As between you and Amplify, Amplify
              retains all right, title and interest, including all intellectual
              property rights, in and to the Amplify Materials.
            </p>
            <p class="c2">
              <span class="">5.1. Customer Reference</span>. Customer
              acknowledges and accepts that Amplify has the right to use
              Customer&rsquo;s name and logo to identify Customer as a customer
              of Amplify or as a User of the Services, on Amplify&rsquo;s Sites,
              marketing materials or otherwise by public announcements,
              including, but not limited to, earning statements and calls,
              shareholder materials and similar announcements. Customer may
              revoke such right, at any time, by contacting info@amplifyls.com.
            </p>
            <p class="c2">
              <span class="">5.3. Your Access and Use Rights</span>. Subject to
              the terms and conditions of these Terms, and your compliance
              thereof, and specifically in strict compliance with our Acceptable
              Use Policy , we grant you a limited, worldwide, non-exclusive,
              non-transferable right to access and use the Services and Sites,
              during the applicable Subscription Term, solely for
              Customer&rsquo;s internal business purposes.
            </p>
            <p class="c2">
              <span class="">5.4. Use Restrictions</span>. Except as expressly
              permitted in these Terms, you may not, and shall not allow a User
              or any third party to: (i) give, sell, rent, lease, timeshare,
              sublicense, disclose, publish, assign, market, resell, display,
              transmit, broadcast, transfer or distribute any portion of the
              Services or the Sites to any third party, including, but not
              limited to your affiliates, or use the Services in any service
              bureau arrangement; (ii) circumvent, disable or otherwise
              interfere with security-related features of the Sites or Services
              or features that prevent or restrict use or copying of any content
              or that enforce limitations on use of the Services or Sites; (iii)
              reverse engineer, decompile or disassemble, decrypt or, attempt to
              derive the source code of, the Services or Sites, or any
              components thereof; (iv) copy, modify, translate, patch, improve,
              alter, change or create any derivative works of the Services or
              Sites, or any part thereof; (v) take any action that imposes or
              may impose (at Amplify&rsquo;s sole discretion) an unreasonable or
              disproportionately large load on the Amplify infrastructure or
              infrastructure which supports the Sites or Services (vi) interfere
              or attempt to interfere with the integrity or proper working of
              the Services or Sites, or any related activities; (vii) remove,
              deface, obscure, or alter Amplify&rsquo;s or any third
              party&rsquo;s identification, attribution or copyright notices,
              trademarks, or other proprietary rights affixed to or provided as
              part of the Services or Sites, or use or display logos of the
              Services or Sites without Amplify&rsquo;s prior written approval;
              (viii) use the Services or Sites for competitive purposes,
              including to develop or enhance a competing service or product; or
              (ix) encourage or assist any third party (including other Users)
              to do any of the foregoing.
            </p>
            <p class="c2">
              <span class="">5.5. Feedback</span>. As a User of the Services
              and/or Sites, you may provide suggestions, comments, feature
              requests or other feedback to any of Amplify Materials, the
              Services, the API and/or the Sites ("Feedback"). Such Feedback is
              deemed an integral part of Amplify Materials, and as such, it is
              the sole property of Amplify without restrictions or limitations
              on use of any kind. Amplify may either implement or reject such
              Feedback, without any restriction or obligation of any kind. You
              (i) represent and warrant that such Feedback is accurate,
              complete, and does not infringe on any third party rights; (ii)
              irrevocably assign to Amplify any right, title and interest you
              may have in such Feedback; and (iii) explicitly and irrevocably
              waive any and all claims relating to any past, present or future
              moral rights, artists&rsquo; rights, or any other similar rights,
              worldwide, in or to such Feedback.
            </p>
            <p class="c2">
              <span class="">5.6. API Use</span>. We may offer an API that
              provides additional ways to access and use the Services. Such API
              is considered a part of the Services, and its use is subject to
              all these Terms. Without derogating from Sections thereof, you may
              only access and use our API for Customer&rsquo;s internal business
              purposes, in order to create interoperability and integration
              between the Services and other products, services or systems you
              and/or Customer use internally. We reserve the right at any time
              to modify or discontinue, temporarily or permanently, your and/or
              Customer&rsquo;s access to the API (or any part of it) with or
              without notice. The API is subject to changes and modifications,
              and you are solely responsible to ensure that your use of the API
              is compatible with the current version.
            </p>
            <h2>6. Privacy and Security</h2>
            <p class="c2">
              <span class="">6.1. Security</span>. Amplify implements reasonable
              security measures and procedures designed to assist in protecting
              your Customer Data. We maintain a number of administrative,
              physical, and technological measures to protect the
              confidentiality, privacy, and security of your Customer Data.
              These controls vary based on the nature of the information and
              industry standards. Unfortunately, no website, server or database
              is completely secure or &ldquo;hacker proof.&rdquo; We therefore
              cannot guarantee that Customer Data you provide will not be
              disclosed, misused, or lost by accident or by the unauthorized
              acts of others.
            </p>
            <p class="c2">
              Safeguarding Customer Data is also your responsibility. We
              encourage you to change your passwords often, use a combination of
              letters and numbers, and make sure you are using a secure device
              to access the Services. If you have reason to believe that the
              security of your account might have been compromised, or if you
              suspect someone else is using your account, please let us know
              immediately by sending an email to info@amplifyls.com.
            </p>
            <p class="c2">
              <span class="">6.2. Privacy Policy.</span>
              &nbsp;As a part of accessing or using the Services and the Sites,
              we may collect, access, use and share certain Personal Data (as
              defined in the Privacy Policy ) from, and/or about you. Please
              read our Privacy Policy , which is incorporated herein by
              reference, for a description of such data collection and use
              practices.
            </p>
            <p class="c2">
              <span class="">6.3. Data Processing Addendum</span>. By using the
              Services, Customer also accepts our Data Processing
              Addendum("DPA"), which governs the Processing of Personal Data (as
              both terms are defined in the DPA) on Customer&rsquo;s behalf.
            </p>
            <p class="c2">
              <span class="">6.4. Anonymous Information</span>. Notwithstanding
              any other provision of these Terms, we may collect, use and
              publish Anonymous Information (defined below) relating to, or
              generated by your use of the Services and/or Sites, and disclose
              it for the purpose of providing, operating, improving and
              publicizing our products and services, including the Sites and
              Services, and for other business purposes. &ldquo;Anonymous
              Information&rdquo; means information which does not enable
              identification of an individual, such as de-identified, aggregated
              and/or analytics information. Amplify owns all Anonymous
              Information collected or obtained by Amplify.
            </p>
            <h2>7. Third Party Services; Links</h2>

            <p class="c2">
              <span class="">7.1. Third Party Services</span>. The Services may
              enable you to engage and procure certain third party services,
              products, apps and tools in connection with the Services,
              including, without limitation, third party applications and
              widgets offered via our integrations offering, or which you decide
              to connect through our API, as part of the Services (collectively,
              Third Party Services).
            </p>
            <p class="c2">
              <span class="">7.2. Independent Relationship</span>. You
              acknowledge and agree that regardless of the manner in which such
              Third Party Services may be offered to you, we merely act as an
              intermediary platform between you and such Third Party Services,
              and we do not, in any way, endorse any such Third Party Services,
              or shall be in any way responsible or liable with respect to any
              such Third Party Services. Your relationship with such Third Party
              Services and any terms governing your payment for, and use of,
              such Third Party Services, including without limitation, the
              collection, processing and use of your data by such Third Party
              Services, are subject to a separate contractual arrangement
              between you and the provider of the Third Party Services ("Third
              Party Agreement"). We are not a party to, or responsible, in any
              manner, for the compliance by you or by the provider of the Third
              Party Services with the Third Party Agreement.
            </p>
            <p class="c2">
              <span class="">
                7.3. Integration with a Third Party Service and your Customer
                Data
              </span>
              . Through the Services you may enable integrations with your
              Account, including, dashboards or services within your Account (or
              a portion thereof), with Third Party Services, which will allow an
              exchange, transmission, modification or removal of data between us
              and the Third Party Services, including without limitation, the
              Customer Data, the scope of which is determined by the applicable
              actions set by such integration. You hereby acknowledge that any
              access, collection, transmission, processing, storage or any other
              use of data, including the Customer Data, by a Third Party
              Services, is governed by the Third Party Agreement, including any
              applicable privacy policy, and Amplify is not responsible for any
              access, collection, transmission, processing, storage or any other
              use of data, including the Customer Data, by the Third Party
              Services or for such Third Party Services privacy and security
              actions, inactions or general practices. By integrating and/or
              using the Third Party Services, you acknowledge and agree that:
              (i) you are solely responsible for your compliance with applicable
              privacy restrictions, laws and regulations, including your use of
              the Third Party Services and other data activities you may conduct
              or may permit third parties, including the Third Party Services,
              to conduct; (ii) the activities and use of the data by you and any
              other Users within the Account, may result in a modification
              and/or removal of data, either in the Account (i.e. Customer Data)
              and in the integrated Third Party Services. We shall have no
              obligation of any kind, for any such modification and/or removal
              of data, either in the Account with us and/or the integrated Third
              Party Services.
            </p>
            <p class="c2">
              <span class="">7.4. Use Conditions and Limitations</span>. Both
              Amplify and Third Party Services may impose, each at its sole
              discretion, additional conditions or limitations on your access
              and use of certain Third Party Services, including without
              limitation, imposing a limited quota on the number of actions or
              other uses (as the case may be). Such additional conditions or
              limitations shall be indicated wherever relevant within the
              Services or the Third Party Services or otherwise notified to you
              or to any other relevant User of the Account.
            </p>
            <p class="c2">
              <span class="">7.5. Amplify Marketplace</span>. Amplify may make
              available Third Party Services through the a digital marketplace
              or the Services.
            </p>
            <p class="c2">
              <span class="">7.6. Payment for Third Party Services</span>. Third
              Party Services may be offered free of charge or for a certain fee,
              either charged directly by the Third Party Services or by Amplify.
              Wherever Third Party Services require payment, it shall be
              indicated to you, unless such fee is included within the Fees for
              the Services. Wherever Amplify charges Customer on behalf of the
              Third Party Services, then Customer acknowledges that Amplify
              serves only as an intermediary role in facilitating or collecting
              the applicable fees and taxes from Customer, for the Third Party
              Services, thus all payment related issues, including the payment
              of fees, renewal and refund policy, are governed by the Third
              Party Agreement. Wherever Amplify charges Customer, on behalf of
              itself and not on behalf of the Third Party Services, the payment
              terms, including the payment of fees, renewal and refund policy,
              are governed by Sections{" "}
            </p>
            <p class="c2">
              <span class="">7.7. Change of Fees</span>. Customer acknowledges
              that Amplify and any Third Party Services, may change the fees for
              the Third Party Services from time to time, including imposing a
              new charge on a Third Party Services that was provided for free.
            </p>
            <p class="c2">
              <span class="">7.8. Discontinuation of Third Party Services</span>
              . Each Amplify and the Third Party Services reserves the right to
              discontinue the use or suspend the availability of any Third Party
              Services, for any reason and with no obligation to provide any
              explanation or notice. Such discontinuation may result in the
              inability to utilize certain features and actions of the Third
              Party Services along with our Services.
            </p>
            <p class="c2">
              <span class="">7.9. Links</span>. The Sites, Services and/or any
              Third Party Services may contain links to third party websites
              that are not owned or controlled by us ("Links"). You acknowledge
              that we have no control over, and assume no responsibility for the
              content, privacy policies, or practices of, any third party
              websites. You: (i) are solely responsible and liable for your use
              of and linking to third party websites and any content that you
              may send or post to a third-party website; and (ii) expressly
              release us from any and all liability arising from your, and in
              case of a Customer, all Users&rsquo;, use of any third party
              website. Accordingly, we encourage you to read the terms and
              conditions and privacy policy of each third party website that you
              may choose to visit.
            </p>
            <p class="c2">
              <span class="">7.10. Limitations of Liability</span>. AMPLIFY
              BEARS NO RESPONSIBILITY AND/OR LIABILITY FOR ANY LINKS OR THIRD
              PARTY SERVICES, INCLUDING WITHOUT LIMITATION, SUCH THIRD PARTY
              SERVICES&rsquo; OPERABILITY OR INTEROPERABILITY WITH OUR SERVICES,
              SECURITY, ACCURACY, RELIABILITY, DATA PROTECTION AND PROCESSING
              PRACTICES AND THE QUALITY OF ITS OFFERINGS, AS WELL AS ANY ACTS OR
              OMISSIONS BY THIRD PARTIES. BY ACCESSING AND/OR USING THE THIRD
              PARTY SERVICES, YOU ACKNOWLEDGE THAT YOUR ACCESS AND USE OF THE
              THIRD PARTY SERVICES ARE AT YOUR SOLE DISCRETION AND RISK, AND YOU
              ARE SOLELY RESPONSIBLE FOR ENSURING SUCH THIRD PARTY
              SERVICES&rsquo; OPERATION AND PRACTICES AND ITS RESPECTIVE THIRD
              PARTY AGREEMENT MEET YOUR NEEDS.
            </p>
            <h2>8. Subscription, Fees, Upgrades and Renewals</h2>

            <p class="c2">
              <span class="">8.1. Order Form</span>. The Services may be
              purchased via an order form, e.g. a sales order, purchase document
              or any other instrument as determined by us, which shall be
              completed and placed either&nbsp; online, in-product, offline, or
              in any other form designated by us (Order Form). Such Order Form
              will list, at a minimum, the Services ordered, the associated
              fees, the subscription plan and term, as applicable. If Customer
              provides Amplify a purchase order related to an Order Form, such
              is deemed to incorporate these Terms and if such contains terms in
              regards to the Services then such shall have no force or effect.
            </p>
            <p class="c2">
              <span class="">8.2. Subscription</span>. Unless stated otherwise
              in an Order Form, the Services are provided on a subscription
              basis for the subscription term specified in the Order Form, in
              accordance with the respective subscription plan, as applicable,
              purchased under such Order Form Subscription Term and Subscription
              Plan &rdquo;, respectively, and collectively the &ldquo;
              Subscription.
            </p>
            <p class="c2">
              <span class="">8.3. Fees</span>. In consideration for the
              provision of the Services (except for Trial Services), Customer
              shall pay us the applicable fees, as set forth in the applicable
              Order Form (Fees). Unless indicated otherwise, Fees are stated in
              US dollars. Customer hereby authorizes us, either directly or
              through our payment processing service or our affiliates, to
              charge such Fees via Customer&rsquo;s selected payment method,
              upon due date or shall otherwise remit payment to Amplify upon the
              due date. Unless expressly set forth herein, the Fees are
              non-cancelable and non-refundable. We reserve the right to change
              the Fees at any time, upon notice to Customer if such change may
              affect Customer&rsquo;s existing subscriptions upon renewal. In
              the event of failure to collect the Fees owed by Customer, we may,
              at our sole discretion (but shall not be obligated to) retry to
              collect at a later time, and/or suspend or cancel the Account,
              without notice.
            </p>
            <p class="c2">
              <span class="">8.4. Taxes</span>. The Fees are exclusive of any
              and all taxes (including without limitation, value added tax,
              sales tax, use tax, excise, goods and services tax, etc.), levies,
              or duties, which may be imposed in respect of these Terms and the
              purchase or sale, of the Services hereunder (Taxes). If Customer
              is located in a jurisdiction which requires Customer to deduct or
              withhold Taxes or other amounts from any amounts due to us,
              Customer should notify us, in writing, promptly and we shall join
              efforts to avoid and/or reduce any such Tax withholding, provided,
              however, that in any case, Customer shall bear the sole
              responsibility and liability to pay such Tax and such Tax should
              be deemed as being added on top of the Fees payable by Customer.
            </p>
            <p class="c2">
              <span class="">8.5. Upgrades</span>. Customer may upgrade and/or
              enhance its Services by either: (i) adding Users; (ii) upgrading
              to a higher type of Subscription Plan; (iii) adding additional
              services, products, Add-Ons, features or functionalities; and/or
              (iv) upgrading to a longer Subscription Term (collectively,
              Upgrades). Some Upgrades or other changes may be considered as a
              new purchase, hence will restart the Subscription Term for all or
              some of the Services and some will not, as indicated within the
              Services and/or the Order Form. Upon an Upgrade, Customer will be
              billed for the applicable additional Fees, at our then-current
              rates (unless indicated otherwise in an Order Form), either: (a)
              prorated for the remainder of the then-current Subscription Term,
              or (b) whenever the Subscription Term is being restarted, then the
              Fees already paid by Customer will be reduced from the new
              upgraded Fees, and the difference shall be due and payable by
              Customer upon the date on which the Upgrade was made. Add-Ons
              means additional enhancements and/or additional functionalities
              for the applicable Services for the respective Subscription Plan
              purchased by Customer that are made available for purchase, which
              may be subject to additional Fees.
            </p>
            <p class="c2">
              <span class="">8.6. Adding Users</span>. Customer acknowledges
              that unless it disabled these options: (i) Users within the same
              email domain will be able to automatically join the Account; and
              (ii) Users within Customer&rsquo;s Account may invite other
              persons to be added to the Account as Users (collectively, Users
              Increase ). Any changes to the number of Users within a certain
              Account, will restart the Subscription Term for all or some of the
              Services, and Customer will be billed for the applicable
              additional Fees at our then-current rates and the Fees already
              paid by Customer will be reduced from the new additional Fees,
              unless otherwise agreed in an Order Form in which case Users will
              be added for the remainder of the Subscription Term on a prorata
              basis. We will bill Customer upon the Users increase, unless such
              alternative time is otherwise agreed by us.
            </p>
            <p class="c2">
              <span class="">8.7. Excessive Usage</span>. We shall have the
              right, including without limitation where we, at our sole
              discretion, believe that Customer and/or any of its Users, have
              misused the Services or otherwise use the Services in an excessive
              manner compared to the anticipated standard use at our sole
              discretion (for instance, an excessive number of guests, excessive
              use of automations, etc.), to offer the Services in different
              pricing and/or impose additional fees or other restrictions as for
              the upload, storage, download and/or use of the Services,
              including, without limitation, restrictions on Third Party
              Services, network traffic and bandwidth, size and/or length of
              content, quality and/or format of content, sources of content,
              volume of download time, etc.
            </p>
            <p class="c2">
              <span class="">8.8. Billing</span>. As part of registering, or
              submitting billing information, to the Services, Customer agrees
              to provide us with updated, accurate and complete billing
              information, and Customer authorizes us (either directly or
              through our affiliates, including Amplify Inc. or other third
              parties) to charge, request and collect payment (or otherwise
              charge, refund or take any other billing actions) from
              Customer&rsquo;s payment method or designated banking account, and
              to make any inquiries that we (or our affiliates and/or
              third-parties acting on our behalf) may consider necessary to
              validate Customer&rsquo;s designated payment account or financial
              information, in order to ensure prompt payment, including for the
              purpose of receiving updated payment details from Customer&rsquo;s
              credit card company or banking account (e.g., updated expiry date
              or card number as may be provided to us by Customer&rsquo;s credit
              card company).
            </p>
            <p class="c2">
              <span class="">8.9. Subscription Auto-Renewal</span>. In order to
              ensure that Customer will not experience any interruption or loss
              of services, Customer&rsquo;s Subscription includes an automatic
              renewal option by default. Accordingly, unless Customer cancels
              its Subscription prior to its expiration, which in the case of an
              annual Subscription, such cancellation notice shall be no less
              than 30 days prior to its expiration (unless otherwise permitted
              by Amplify), the Subscription to the underlying Services will
              automatically renew upon the end of the then applicable
              Subscription Term, for a renewal period equal in time to the
              original Subscription Term and, unless otherwise notified to
              Customer, at the same price (subject to applicable Tax changes and
              excluding any discount or other promotional offer provided for the
              first Subscription Term). Accordingly, unless either Customer or
              us cancel the Subscription prior to its expiration, we will
              attempt to automatically charge Customer the applicable Fees upon
              or immediately prior to the expiration of the then applicable
              Subscription Term. If Customer wishes to avoid such auto-renewal,
              Customer shall cancel its Subscription, prior to its expiration,
              at any time through the Account settings or by contacting us at
              info@amplifyls.com&nbsp;team. Except as expressly set forth in
              these Terms, in case a Customer cancels its Subscription, during a
              Subscription Term, the Subscription will not renew for an
              additional period, but Customer will not be refunded or credited
              for any unused period within the Subscription Term.
            </p>
            <p class="c2">
              <span class="">8.10. Discounts and Promotions</span>. Unless
              expressly stated otherwise in a separate legally binding
              agreement, if Customer received a special discount or other
              promotional offer, Customer acknowledges that upon renewal of the
              Subscription to the Services, Amplify will renew such Subscription
              to the Services, at the full applicable Fee at the time of
              renewal.
            </p>
            <p class="c2">
              <span class="">8.11. Credits</span>. Any credits that may accrue
              to Customer&rsquo;s Account for the respective Services, for any
              reason (Credits ), will expire and be of no further force and
              effect, upon the earlier of: (i) the expiration or termination of
              the applicable Subscription under the Account for which such
              Credits were given; or (ii) in case such Credits accrued for an
              Account with Trial Services (as defined below) that was not
              upgraded to a Subscription Plan, then upon the lapse of 90 days of
              such Credits&rsquo; accrual. Unless specifically indicated
              otherwise, Credits may be used to pay for the respective Services
              only and not for any Third Party Services or other payment of
              whatsoever kind. Whenever fees are due for any Services, accrued
              Credits will be first reduced against the Fees and the remainder
              will be charged from Customer&rsquo;s respective payment method.
              Credits shall have no monetary value (except for the purchase of
              Services under the limited terms specified herein) nor exchange
              value, and will not be transferable or refundable.
            </p>

            <h2>9. Refund Policy; Chargeback</h2>

            <p class="c2">
              <span class="">9.1. Refund Policy</span>. If Customer is not
              satisfied with its first purchase of the Services and solely with
              respect thereto (Initial Purchase), then Customer may terminate
              such Initial Purchase of the Services by providing us a written
              notice, within 30 days of such Initial Purchase (Refund Period).
              In the event that Customer terminates such Initial Purchase,
              within the Refund Period, we will refund Customer the prorata
              portion of any unused and unexpired Fees pre-paid by Customer in
              respect of such terminated Services, unless such other sum is
              required by applicable law, in the same currency we were
              originally paid (Refund). The Refund is applicable only to the
              Initial Purchase and does not apply to any additional or
              subsequent purchases, upgrades, modification or renewals of the
              Services (even if such were performed during the Refund Period).
              Please note that we shall not be responsible to Refund any
              differences caused by change of currency exchange rates or fees
              that Customer was charged by third parties, such as wire transfer
              fees. After the Refund Period, the Fees are non-refundable and
              non-cancellable. To the extent permitted by law, if we find that a
              notice of cancellation has been given in bad faith or in an
              illegitimate attempt to avoid payment for Services actually
              received and enjoyed, we reserve our right to reject
              Customer&rsquo;s Refund request. Subject to the foregoing, upon
              termination by Customer under this Section 9.1 all outstanding
              payment obligations shall immediately become due for the used
              Services and Customer will promptly remit to Amplify any fees due
              to Amplify under these Terms.
            </p>
            <p class="c2">
              <span class="">9.2. Non-Refundable Services</span>. Certain
              Services may be non-refundable. In such event we will identify
              such Services as non-refundable, and Customer shall not be
              entitled, and we shall not be under any obligation, to terminate
              the Services and give a Refund.
            </p>
            <p class="c2">
              <span class="">9.3. Chargeback</span>. If, at any time, we record
              a decline, chargeback or other rejection of a charge of any due
              and payable Fees (Chargeback), this will be considered as a breach
              of Customer&rsquo;s payment obligations hereunder, and
              Customer&rsquo;s use of the Services may be suspended, disabled or
              terminated at Amplify&rsquo;s discretion. Customer may not have
              any further access to the Services in the event of such
              suspension, disablement or termination, unless Amplify otherwise
              enables Customer to resume its access to the Services, at its sole
              discretion, subject to Customer&rsquo;s payment of any applicable
              Fees in full, including any fees and expenses incurred by us
              and/or any Third Party Services for each Chargeback received
              (including handling and processing charges and fees incurred by
              the payment processor), without derogating from any other remedy
              that may be applicable to us under these Terms or applicable law.
            </p>

            <h2>10. Trial Services; Pre-Released Services</h2>

            <p class="c2">
              <span class="">10.1. Trial Services and Free Versions</span>. We
              may offer, from time to time, part or all of our Services on a
              free, no-obligation trial and/or in connection with a free
              Subscription Plan to the Services for a limited duration and with
              limited functionality (Trial Services). The term of the Trial
              Services shall be as communicated to you within the Services, in
              an Order Form or separately in writing by Amplify, unless
              terminated earlier by either Customer or us, for any reason or for
              no reason. We reserve the right to modify, cancel and/or limit the
              Trial Services at any time, with or without notice, and without
              liability or explanation to you. In respect of the Trial Services,
              upon termination, we may change the Account web address at any
              time without any prior written notice.
            </p>
            <p class="c2">
              <span class="">10.2. Pre-Released Services</span>. Note that we
              may offer, from time to time, certain Services or parts thereof as
              Alpha or Beta versions (Pre-Released Services) and we use best
              endeavors to identify such Pre-Released Services as such.
              Pre-Released Services are Services that are still under
              development, and as such they may be inoperable or incomplete, and
              may contain bugs, suffer disruptions and/or not operate as
              intended and designated, more than usual.
            </p>
            <p class="c2">
              <span class="">
                10.3. Governing Terms of Trial Services and Pre-Released
                Services
              </span>
              . The Trial Services and Pre-Released Services are governed by
              these Terms, provided that notwithstanding anything in these Terms
              or elsewhere to the contrary, in respect of Trial Services and
              Pre-Released Services (i) such services are licensed hereunder on
              an &ldquo;As-Is&rdquo;, &ldquo;With All Faults&rdquo; &ldquo;As
              Available&rdquo; basis, with no representations and/or warranties,
              express or implied, of any kind; (ii) the indemnity undertaking by
              us set forth in Section 16.2 herein shall not apply; and (iii) IN
              NO EVENT SHALL THE TOTAL AGGREGATE LIABILITY OF Amplify, ITS
              AFFILIATES OR ITS THIRD PARTY SERVICE PROVIDERS, UNDER, OR
              OTHERWISE IN CONNECTION WITH, THESE TERMS (INCLUDING THE SITES,
              THE SERVICES AND THE THIRD PARTY SERVICES), EXCEED US $100. We
              make no promises that any Trial Services and/or Pre-Released
              Services will be made available to you and/or generally available.
            </p>
            <h2>11. Term and Termination; Suspension</h2>

            <p class="c2">
              <span class="">11.1. Term</span>. These Terms are in full force
              and effect, commencing upon the Effective Date, until the end of
              all Subscriptions to the Services underlying the Account, either
              paid or unpaid, unless terminated otherwise in accordance with
              these Terms.
            </p>
            <p class="c2">
              <span class="">11.2. Termination for Cause</span>. Either Customer
              or us may terminate the respective Services and these Terms, as
              applicable, upon written notice, in case that the other party (a)
              is in material breach of these Terms and to the extent, curable,
              fails to cure such breach, within a reasonable cure period, which
              shall not be less than 10 days following a written notice from by
              the non-breaching party; provided, however, that termination by
              Customer shall apply only with respect to those components of the
              Services with respect to which the breach has occurred; or (b)
              ceases its business operations or becomes subject to insolvency
              proceedings and the proceedings are not dismissed within 45 days.
            </p>
            <p class="c2">
              <span class="">11.3. Termination by Customer</span>. Customer may
              terminate its Subscription to the Services by canceling the
              Services and/or deleting the Account, whereby such termination
              shall not derogate from Customer&rsquo;s obligation to pay
              applicable Fees except where such termination is made within the
              Refund Period. In accordance with Section 9 above, unless mutually
              agreed otherwise by Customer and us in a written instrument, the
              effective date of such termination will take effect at the end of
              the then-current Subscription Term, and Customer&rsquo;s
              obligation to pay the Fees throughout the end of such Subscription
              Term shall remain in full force and effect, and Customer shall not
              be entitled to a refund for any pre-paid Fees.
            </p>
            <p class="c2">
              <span class="">11.4. Effect of Termination of Services</span>.
              Upon termination or expiration of these Terms, Customer&rsquo;s
              Subscription and all rights granted to you hereunder shall
              terminate, and we may change the Account&rsquo;s web address. It
              is Customer&rsquo;s sole liability to export the Customer Data
              prior to such termination or expiration. In the event that
              Customer did not delete the Customer Data from the Account, we may
              continue to store and host it until either Customer or we, at our
              sole discretion, delete such Customer Data, and during such
              period, Customer may still be able to make a limited use of the
              Services in order to export the Customer Data ( Read-Only Mode),
              but note that we are not under any obligation to maintain the
              Read-Only Mode period, hence such period may be terminated by us,
              at any time, with or without notice to Customer, and subsequently,
              the Customer Data will be deleted. Customer acknowledges the
              foregoing and its sole responsibility to export and/or delete the
              Customer Data prior to the termination or expiration of these
              Terms, and therefore we shall not have any liability either to
              Customer, nor to any User or third party, in connection thereto.
              Unless expressly indicated herein otherwise, the termination or
              expiration of these Terms shall not relieve Customer from its
              obligation to pay due Fees.
            </p>
            <p class="c2">
              <span class="">11.5. Survival Section</span>. 2.6 (Customer
              Responsibility for Users), 3 (Customer Data), 6 (Privacy and
              Security), 7 (Third Party Services; Links), 8 (Subscription, Fees,
              Upgrades and Renewal) in respect of unpaid Subscription Fees, 10.3
              (Governing Terms of Trial Services and Pre-Released Services), 11
              (Term and Termination; Suspension), 12 (Confidentiality), 13
              (Warranty Disclaimer), 14 (Limitations of Liability), 16
              (Indemnification), 21 (Governing Law and Jurisdiction; Class
              Action Waiver and Arbitration) and 22 (General Provisions), and
              such other sections of these Terms which by their nature are
              intended to survive, shall survive the termination or expiration
              of these Terms, and continue to be in force and effect in
              accordance with their applicable terms.
            </p>
            <p class="c2">
              <span class="">11.6. Suspension</span>. Without derogating from
              our termination rights above, we may decide to temporarily suspend
              the Account and/or a User Profile (including any access thereto)
              and/or our Services, in the following events: (i) we believe, at
              our sole discretion, that you or any third party, are using the
              Services in a manner that may impose a security risk, may cause
              harm to us or any third party, and/or may raise any liability for
              us or any third party; (ii) we believe, at our sole discretion,
              that you or any third party, are using the Services in breach of
              these Terms or applicable Law; (iii) Customer&rsquo;s payment
              obligations, in accordance with these Terms, are or are likely to
              become, overdue; or (iv) Customer&rsquo;s or any of its
              Users&rsquo; breach of the Acceptable Use Policy. The
              afore-mentioned suspension rights are in addition to any remedies
              that may be available to us in accordance with these Terms and/or
              applicable Law.
            </p>
            <h2>12. Confidentiality</h2>

            <p class="c2">
              <span class="">12.1. Confidential Information</span>. In
              connection with these Terms and the Services (including the
              evaluation thereof), each party (Disclosing Party ) may disclose
              to the other party (Receiving Party ), non-public business,
              product, technology and marketing information, including without
              limitation, customers lists and information, know-how, software
              and any other non-public information that is either identified as
              such or should reasonably be understood to be confidential given
              the nature of the information and the circumstances of disclosure,
              whether disclosed prior to or after the Effective Date
              (Confidential Information). For the avoidance of doubt, (i)
              Customer Data is regarded as Customer&rsquo;s Confidential
              Information, and (ii) our Site, Services, Trial Services and/or
              Pre-Released Services, inclusive of their underlying technology,
              and their respective performance information, as well as any data,
              reports and materials we provided to you in connection with your
              evaluation or use of the Services, are regarded as our
              Confidential Information. Confidential Information does not
              include information that (a) is or becomes generally available to
              the public without breach of any obligation owed to the Disclosing
              Party; (b) was known to the Receiving Party prior to its
              disclosure by the Disclosing Party without breach of any
              obligation owed to the Disclosing Party; (c) is received from a
              third party without breach of any obligation owed to the
              Disclosing Party; or (d) was independently developed by the
              Receiving Party without any use or reference to the Confidential
              Information.
            </p>
            <p class="c2">
              <span class="">
                12.2. Confidentiality Undertakings by the Receiving Party
              </span>
              . The Receiving Party will (i) take at least reasonable measures
              to prevent the unauthorized disclosure or use of Confidential
              Information, and limit access to those employees, affiliates,
              service providers and agents, on a need to know basis and who are
              bound by confidentiality obligations at least as restrictive as
              those contained herein; and (ii) not use or disclose any
              Confidential Information to any third party, except as part of its
              performance under these Terms and as required to be disclosed to
              legal or financial advisors to the Receiving Party or in
              connection with a due diligence process that the Receiving Party
              is undergoing, provided that any such disclosure shall be governed
              by confidentiality obligations at least as restrictive as those
              contained herein.
            </p>
            <p class="c2">
              <span class="">12.3. Compelled Disclosure</span>. Notwithstanding
              the above, Confidential Information may be disclosed pursuant to
              the order or requirement of a court, administrative agency or
              other governmental body; provided, however, that to the extent
              legally permissible, the Receiving Party shall make best efforts
              to provide prompt written notice of such court order or
              requirement to the Disclosing Party to enable the Disclosing Party
              to seek a protective order or otherwise prevent or restrict such
              disclosure.
            </p>

            <h2>13. Warranty Disclaimer</h2>

            <p class="c2">
              EXCEPT AS EXPRESSLY SET FORTH IN THESE TERMS, WE MAKE NO
              REPRESENTATIONS AND/OR WARRANTIES AND TO THE FULLEST EXTENT
              PERMITTED BY APPLICABLE LAW:
            </p>
            <p class="c2">
              13.1. THE SITES AND THE SERVICES ARE PROVIDED ON AN &ldquo;AS
              IS&rdquo;, &ldquo;WITH ALL FAULTS&rdquo; AND &ldquo;AS
              AVAILABLE&rdquo; BASIS, AND WITHOUT WARRANTIES OF ANY KIND. WE AND
              OUR AFFILIATES, SUBCONTRACTORS, AGENTS AND VENDORS (INCLUDING, THE
              THIRD PARTY SERVICE PROVIDERS), HEREBY DISCLAIM ANY AND ALL
              REPRESENTATIONS AND WARRANTIES OF ANY KIND, INCLUDING WITHOUT
              LIMITATION, REPRESENTATIONS AND/OR WARRANTIES OF MERCHANTABILITY,
              FUNCTIONALITY, TITLE, FITNESS FOR A PARTICULAR PURPOSE AND
              NON-INFRINGEMENT, WHETHER EXPRESS, IMPLIED OR STATUTORY.
            </p>
            <p class="c2">
              13.2. WE AND OUR VENDORS DO NOT WARRANT, AND EXPRESSLY DISCLAIM
              ANY REPRESENTATIONS AND/OR WARRANTIES THAT THE SERVICES AND SITES,
              INCLUDING THE ACCESS THERETO AND USE THEREOF, WILL BE
              UNINTERRUPTED, TIMELY, SECURED, ERROR FREE, THAT DATA WILL NOT BE
              LOST, THAT DEFECTS WILL BE CORRECTED, OR THAT THE SITES AND/OR
              SERVICES ARE FREE FROM VIRUSES OR OTHER HARMFUL CODE. WE AND OUR
              VENDORS FURTHER DISCLAIM ANY AND ALL LIABILITY OR RESPONSIBILITY
              FOR ANY DELAYS, FAILURES, INTERCEPTION, ALTERATION, LOSS, OR OTHER
              DAMAGES THAT YOU AND/OR YOUR DATA (INCLUDING CUSTOMER DATA) MAY
              SUFFER, THAT ARE BEYOND OUR CONTROL.
            </p>
            <p class="c2">
              13.3. WE DO NOT WARRANT, AND EXPRESSLY DISCLAIM ANY
              REPRESENTATIONS AND/OR WARRANTIES (I) THAT OUR SERVICES AND SITES
              (OR ANY PORTION THEREOF) IS COMPLETE, ACCURATE, OF ANY CERTAIN
              QUALITY, RELIABLE, SUITABLE FOR, OR COMPATIBLE WITH, ANY OF YOUR
              CONTEMPLATED ACTIVITIES, DEVICES, OPERATING SYSTEMS, BROWSERS,
              SOFTWARE OR TOOLS (OR THAT IT WILL REMAIN AS SUCH AT ANY TIME), OR
              COMPLY WITH ANY LAWS APPLICABLE TO YOU; AND/OR (II) REGARDING ANY
              CONTENT, INFORMATION, REPORTS OR RESULTS THAT YOU OBTAIN THROUGH
              THE SERVICES AND/OR THE SITES.
            </p>

            <p class="c2">
              NOTWITHSTANDING ANYTHING IN THESE TERMS OR ELSEWHERE TO THE
              CONTRARY AND TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW:
            </p>
            <p class="c2">
              14.1. IN NO EVENT SHALL EITHER PARTY HERETO AND ITS AFFILIATES,
              SUBCONTRACTORS, AGENTS AND VENDORS (INCLUDING, THE THIRD PARTY
              SERVICE PROVIDERS), BE LIABLE UNDER, OR OTHERWISE IN CONNECTION
              WITH THESE TERMS FOR (I) ANY INDIRECT, EXEMPLARY, SPECIAL,
              CONSEQUENTIAL, INCIDENTAL OR PUNITIVE DAMAGES; (II) ANY LOSS OF
              PROFITS, COSTS, ANTICIPATED SAVINGS; (III) ANY LOSS OF, OR DAMAGE
              TO DATA, USE, BUSINESS, REPUTATION, REVENUE OR GOODWILL; AND/OR
              (IV) THE FAILURE OF SECURITY MEASURES AND PROTECTIONS, WHETHER IN
              CONTRACT, TORT OR UNDER ANY OTHER THEORY OF LIABILITY OR
              OTHERWISE, AND WHETHER OR NOT SUCH PARTY HAS BEEN ADVISED OF THE
              POSSIBILITY OF SUCH DAMAGES IN ADVANCE, AND EVEN IF A REMEDY FAILS
              OF ITS ESSENTIAL PURPOSE.
            </p>
            <p class="c2">
              14.2. EXCEPT FOR THE INDEMNITY OBLIGATIONS OF EITHER PARTY UNDER
              SECTION 16 (INDEMNIFICATION) HEREIN, YOUR PAYMENT OBLIGATIONS
              HEREUNDER OR BREACH OF OUR ACCEPTABLE USE POLICY BY EITHER YOU OR
              IN CASE OF A CUSTOMER, ANY OF THE USERS UNDERLYING ITS ACCOUNT, IN
              NO EVENT SHALL THE TOTAL AGGREGATE LIABILITY OF EITHER PARTY, ITS
              AFFILIATES, SUBCONTRACTORS, AGENTS AND VENDORS (INCLUDING, ITS
              THIRD PARTY SERVICE PROVIDERS), UNDER, OR OTHERWISE IN CONNECTION
              WITH, THESE TERMS (INCLUDING THE SITES AND THE SERVICES), EXCEED
              THE TOTAL AMOUNT OF FEES ACTUALLY PAID BY YOU (IF ANY), DURING THE
              12 CONSECUTIVE MONTHS PRECEDING THE EVENT GIVING RISE TO SUCH
              LIABILITY. THIS LIMITATION OF LIABILITY IS CUMULATIVE AND NOT PER
              INCIDENT.
            </p>

            <h2>15. Specific Laws; Reasonable Allocation of Risks</h2>
            <p class="c2">
              <span class="">15.1. Specific Laws</span>. Except as expressly
              stated in these Terms, we make no representations or warranties
              that your use of the Services is appropriate in your jurisdiction.
              Other than as indicated herein, you are responsible for your
              compliance with any local and/or specific applicable Laws, as
              applicable to your use of the Services.
            </p>
            <p class="c2">
              <span class="">15.2. Reasonable Allocation of Risks</span>. You
              hereby acknowledge and confirm that the limitations of liability
              and warranty disclaimers contained in these Terms are agreed upon
              by you and us and we both find such limitations and allocation of
              risks to be commercially reasonable and suitable for our
              engagement hereunder, and both you and us have relied on these
              limitations and risk allocation in determining whether to enter
              these Terms.
            </p>
            <h2>Indemnification</h2>

            <p class="c2">
              <span class="">16.1. By Customer</span>. Customer hereby agrees to
              indemnify, defend and hold harmless Amplify and its affiliates,
              officers, directors, employees and agents from and against any and
              all claims, damages, obligations, liabilities, losses, reasonable
              expenses or costs (collectively, &ldquo;Losses&rdquo;) incurred as
              a result of any third party claim arising from (i)
              Customer&rsquo;s and/or any of its Users&rsquo;, violation of
              these Terms or applicable Law; and/or (ii) Customer Data,
              including the use of Customer Data by Amplify and/or any of its
              subcontractors, infringes or violates, any third party&rsquo;s
              rights, including, without limitation, intellectual property,
              privacy and/or publicity rights.
            </p>
            <p class="c2">
              <span class="">16.2. By Amplify</span>. Amplify hereby agrees to
              defend Customer, its affiliates, officers, directors, and
              employees, in and against any third party claim or demand against
              Customer, alleging that Customer&rsquo;s authorized use of the
              Services infringes or constitutes misappropriation of any third
              party&rsquo;s copyright, trademark or registered US patent (IP
              Claim ), and we will indemnify Customer and hold Customer harmless
              against any damages and costs finally awarded on such IP Claim by
              a court of competent jurisdiction or agreed to via settlement we
              agreed upon, including reasonable attorneys&rsquo; fees.
              Amplify&rsquo;s indemnity obligations under this Section 16 shall
              not apply if: (i) the Services (or any portion thereof) was
              modified by Customer or any of its Users or any third party, but
              solely to the extent the IP Claim would have been avoided by not
              doing such modification; (ii) if the Services is used in
              combination with any other service, device, software or products,
              including, without limitation, Third Party Services, but solely to
              the extent that such IP Claim would have been avoided without such
              combination; and/or (iii) any IP Claim arising or related to, the
              Customer Data or to any events giving rise to Customer&rsquo;s
              indemnity obligations under Section 16.1 above. Without derogating
              from the foregoing defense and indemnification obligation, if
              Amplify believes that the Services, or any part thereof, may so
              infringe, then Amplify may in its sole discretion: (a) obtain (at
              no additional cost to you) the right to continue to use the
              Services; (b) replace or modify the allegedly infringing part of
              the Services so that it becomes non-infringing while giving
              substantially equivalent performance; or (c) if Amplify determines
              that the foregoing remedies are not reasonably available, then
              Amplify may require that use of the (allegedly) infringing
              Services (or part thereof) shall cease and in such an event,
              Customer shall receive a prorated refund of any Fees paid for the
              unused portion of the Subscription Term. THIS SECTION 16.2 STATES
              Amplify&rsquo;S SOLE AND ENTIRE LIABILITY AND YOUR EXCLUSIVE
              REMEDY, FOR ANY INTELLECTUAL PROPERTY INFRINGEMENT OR
              MISAPPROPRIATION BY AMPLIFY AND/OR ITS SERVICES AND UNDERLYING
              TECHNOLOGY.
            </p>
            <p class="c2">
              <span class="">16.3. Indemnity Conditions</span>. The defense and
              indemnification obligations of the indemnifying party under this
              Section 16 are subject to: (i) the indemnified party promptly
              providing a written notice of the claim for which an
              indemnification is being sought, provided that such
              indemnitee&rsquo;s failure to do so will not relieve the
              indemnifying party of its obligations under this Section 16,
              except to the extent the indemnifying party&rsquo;s defense is
              materially prejudiced thereby; (ii) the indemnifying party being
              given immediate and exclusive control over the defense and/or
              settlement of the claim, provided, however that the indemnifying
              party shall not enter into any compromise or settlement of any
              such claim that requires any monetary obligation or admission of
              liability or any unreasonable responsibility or liability by an
              indemnitee without the prior written consent of the affected
              indemnitee, which shall not be unreasonably withheld or delayed;
              and (iii) the indemnified party providing reasonable cooperation
              and assistance, at the indemnifying party&rsquo;s expense, in the
              defense and/or settlement of such claim and not taking any action
              that prejudices the indemnifying party&rsquo;s defense of, or
              response to, such claim.
            </p>
            <h2>17. Third Party Components within Our Services</h2>

            <p class="c2">
              Our Services includes third party codes and libraries that are
              subject to third party open source license terms (Open Source Code
              and Open Source Terms, respectively). Some of such Open Source
              Terms determine that to the extent applicable to the respective
              Open Source Code licensed thereunder, such terms prevail over any
              conflicting license terms, including these Terms. We use our best
              endeavors to identify such Open Source Code, within our Services,
              hence we encourage Customer to familiarize itself with such Open
              Source Terms. Note that we use best efforts to use only Open
              Source Codes that do not impose any obligation or affect the
              Customer Data or related intellectual property (beyond what is
              stated in the Open Source Terms and herein), on an ordinary use of
              our Services that does not involve any modification, distribution
              or independent use of such Open Source Code. Notwithstanding
              anything to the contrary, we make no warranty or indemnity
              hereunder with respect to any Open Source Codes.
            </p>

            <h2>18. Export Controls; Sanctions</h2>

            <p class="c2">
              The Services may be subject to Israeli, U.S. or foreign export
              controls, Laws and regulations (Export Controls), and you
              acknowledge and confirm that: (i) you are not located or use,
              export, re-export or import the Services (or any portion thereof)
              in or to, any person, entity, organization, jurisdiction or
              otherwise, in violation of the Export Controls; (ii) you are not:
              (a) organized under the laws of, operating from, or otherwise
              ordinarily resident in a country or territory that is the target
              or comprehensive U.S. or Israeli economic or trade sanctions
              (currently, Cuba, Lebanon, Iran, Syria, North Korea, or the Crimea
              region of Ukraine), (b) identified on a list of prohibited or
              restricted persons, such as the U.S. Treasury Department&rsquo;s
              List of Specially Designated Nationals and Blocked Persons, or (c)
              otherwise the target of U.S. sanctions; and Customer is solely
              responsible for complying with applicable Export Controls and
              sanctions which may impose additional restrictions, prohibitions
              or requirements on the use, export, re-export or import of the
              Services and/or the Customer Data; and (iii) Customer Data is not
              controlled under the U.S. International Traffic in Arms
              Regulations or similar Laws in other jurisdictions, or otherwise
              requires any special permission or license, in respect of its use,
              import, export or re-export hereunder.
            </p>

            <h2>19. Modifications</h2>

            <p class="c2">
              Occasionally, we may make changes to these Terms for valid
              reasons, such as adding new functions or features to the Services,
              technical adjustments, typos or error fixing, for legal or
              regulatory reasons or for any other reasons as we deem necessary,
              at our sole discretion. When we make material changes to these
              Terms, we&rsquo;ll provide Customer with notice as appropriate
              under the circumstances, e.g., by displaying a prominent notice
              within the Services or by sending Customer an email. Your
              continued use of the Services after the changes have been
              implemented will constitute your acceptance of the changes.
            </p>

            <h2> 20. Government Use</h2>

            <p class="c2">
              If Customer is part of a U.S. Government agency, department or
              otherwise, either federal, state or local (Government Customer ),
              then Government Customer hereby agrees that the Services under
              these Terms qualifies as &ldquo;Commercial Computer
              Software&rdquo; and &ldquo;Commercial Computer Software
              Documentation&rdquo;, within the meaning of Federal Acquisition
              Regulation (FAR ) 2.101, FAR 12.212, Defense Federal Acquisition
              Regulation Supplement (DFARS ) 227.7201, and DFARS 252.227-7014.
              Government Customer further agrees that the terms of this Section{" "}
              20 shall apply to Customer. Government Customer&rsquo;s technical
              data and software rights related to the Services include only
              those rights customarily provided to the public as specified in
              these Terms in accordance with FAR 12.212, FAR 27.405-3, FAR
              52.227-19, DFARS 227.7202-1 and General Services Acquisition
              Regulation (GSAR) 552.212-4(w) (as applicable). In no event shall
              source code be provided or considered to be a deliverable or a
              software deliverable under these Terms. We grant no license
              whatsoever to any Government Customer to any source code contained
              in any deliverable or a software deliverable. If a Government
              Customer has a need for rights not granted under the Terms, it
              must negotiate with us to determine if there are acceptable terms
              for granting those rights, and a mutually acceptable written
              addendum specifically granting those rights must be included in
              any applicable agreement. Any unpublished-rights are reserved
              under applicable copyright laws. Any provisions contained in these
              Terms that contradict any Law applicable to a Government Customer,
              shall be limited solely to the extent permitted under such
              applicable Law.
            </p>

            <h2>
              21. Governing Law and Jurisdiction; Class Action Waiver and
              Mandatory Arbitration.
            </h2>

            <p class="c2">
              <span class="">21.1 Governing Law; Jurisdiction</span>. These
              Terms and any action related thereto will be governed and
              interpreted by and under the laws of the State of California
              without giving effect to any conflicts of laws principles that
              require the application of the law of a different jurisdiction.
              Courts of competent jurisdiction located in San Diego, California,
              shall have the sole and exclusive jurisdiction and venue over all
              controversies and claims arising out of, or relating to, these
              Terms. You and us mutually agree that the United Nations
              Convention on Contracts for the International Sale of Goods does
              not apply to these Terms. Notwithstanding the foregoing, Amplify
              reserves the right to seek injunctive relief in any court in any
              jurisdiction.
            </p>
            <p class="c2">
              <span class="">21.2. Class Action Waiver</span>. WHERE PERMITTED
              UNDER APPLICABLE LAW, YOU AND AMPLIFY AGREE THAT EACH PARTY MAY
              BRING CLAIMS AGAINST THE OTHER PARTY ONLY IN YOUR OR ITS
              INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY
              PURPORTED CLASS OR REPRESENTATIVE ACTION. Unless both you and
              Amplify mutually agree, no arbitrator or judge may consolidate
              more than one person&rsquo;s claims or otherwise preside over any
              form of a representative or class proceeding.
            </p>
            <p class="c2">
              <span class="">21.3. Arbitration</span>. To the extent permitted
              under applicable Law, you and Amplify hereby irrevocably
            </p>
            <p class="c2">
              <span class="">21.3.1 Dispute resolution and Arbitration</span>.
              Any dispute, claim, or controversy between you and us arising in
              connection with, or relating in any way to, these Terms (whether
              based in contract, tort, statute, fraud, misrepresentation, or any
              other legal theory, and whether the claims arise during or after
              the termination or expiration of these Terms) will be determined
              solely by mandatory binding arbitration. In arbitration there is
              no judge or jury and court review of an arbitration award is
              limited. However, an arbitrator can award on an individual basis
              the same damages and relief as a court (including injunctive and
              declaratory relief or statutory damages) and must follow the terms
              of these Terms as a court would.
            </p>
            <p class="c2">
              <span class="">21.3.2 Exception</span>. Notwithstanding clause
              21.3.1 above, you and Amplify both agree that nothing herein will
              be deemed to waive, preclude, or otherwise limit either of our
              rights, at any time, to seek injunctive relief in a court of law.
              In addition to the above, notwithstanding clause 21.3.1 above,
              Amplify may file a suit in a court of law against you to address
              intellectual property infringement claims.
            </p>
            <p class="c2">
              <span class="">21.3.3 Arbitration Process Rules</span>. Except for
              Litigation Claims (defined below), any dispute, claim, or
              controversy arising out of or relating to this Agreement,
              including, without limitation (a) claims relating to the breach,
              termination, enforcement, interpretation or validity thereof, (b)
              claims alleging tortious conduct (including negligence) in
              connection with the negotiation, execution, or performance
              thereof, or (c) the determination of the scope or applicability of
              this agreement to arbitrate, shall be settled by arbitration
              administered by JAMS pursuant to its Comprehensive Arbitration
              Rules and Procedures and in accordance with the Expedited
              Procedures in those Rules or pursuant to JAMS&rsquo; Streamlined
              Arbitration Rules and Procedures and the substantive laws of the
              State of California. The arbitration shall be heard by a single
              arbitrator in San Diego, California. The arbitration award shall
              be final and binding, and such award may be entered in any court
              having jurisdiction. This section shall not preclude parties from
              seeking provisional remedies in aid of arbitration from a court of
              appropriate jurisdiction. The arbitrator shall have the power to
              award any remedy provided under applicable law, except that the
              arbitrator shall have no power to award: (a) punitive, exemplary,
              or multiple damages under any legal theory, except in the event of
              a party&rsquo;s or its agent&rsquo;s gross negligence or
              intentional misconduct; (b) mandatory or prohibitory injunctive
              relief, except for temporary relief in aid of the arbitration or
              to secure the payment of an award; or (c) any damages in excess of
              the limits set forth in this section or Section 14 (Limitation of
              Liability) of this Agreement.
            </p>
            <p class="c2">
              <span class="">21.3.4 Special Statute of Limitation</span>. Any
              arbitration must be commenced by filing a demand for arbitration
              within 2 years after the date the party asserting the claim first
              knows or reasonably should know of the act, omission, or default
              giving rise to the claim; and there shall be no right to any
              remedy for any claim not asserted within that time period. If
              applicable law prohibits such limitation period for asserting
              claims, any claim must be asserted within the shortest time period
              permitted by applicable Law.
            </p>
            <p class="c2">
              <span class="">21.3.5 Litigation Claims</span>. The following
              claims ( Litigation Claims) shall be litigated and not arbitrated:
              (a) claims against a party to this Agreement under the provisions
              involving claims by third parties; (b) claims by a party for the
              unauthorized use, or the misuse, by the other party of the first
              party&rsquo;s Confidential Information; (c) claims by Amplify to
              collect Fees; and (d) claims for mandatory or prohibitory
              injunctive relief, except for temporary relief in aid of
              arbitration or to secure the payment of an arbitration award under
              this Agreement. The Litigation Claims are not subject to
              arbitration and are expressly excluded by the parties from
              arbitration unless otherwise agreed in writing. Each party
              irrevocably consents to the personal jurisdiction of the state and
              federal courts located in the State of California for purposes of
              any lawsuit seeking to enforce this Agreement, and agrees that the
              exclusive venue for any litigation, action, suits, or proceeding
              arising out of or relating to this Agreement shall lie in the
              County Court in and for San Diego County, California, or, if
              federal jurisdiction exists, in the United States District Court
              for the Southern District of California.
            </p>
            <p class="c2">
              <span class="">21.3.6. Notice; Process</span>. A party who intends
              to seek arbitration must first send a written notice of the
              dispute to the other, by certified mail or Federal Express
              (signature required), or in the event that we do not have a
              physical address on file for you, by electronic mail (Dispute
              Notice ). The Dispute Notice must (i) describe the nature and
              basis of the claim or dispute; and (ii) set forth the specific
              relief sought. We agree to use good faith efforts to resolve the
              claim directly, but if we do not reach an agreement to do so
              within 30 days after the Dispute Notice is received, you or us may
              commence an arbitration proceeding. During the arbitration, the
              amount of any settlement offer made by you or us shall not be
              disclosed to the arbitrator until after the arbitrator makes a
              final decision and award, if any. Without derogating from the
              generality of the confidentiality protection under clause 21.3.3
              above, all documents and information disclosed in the course of
              the arbitration shall be kept strictly confidential by the
              recipient and shall not be used by the recipient for any purpose
              other than for purposes of the arbitration or the enforcement of
              the arbitrator&rsquo;s decision and award and shall not be
              disclosed except in confidence to persons who have a need to know
              for such purposes or as required by applicable Law. Except as
              required to enforce the arbitrator&rsquo;s decision and award,
              neither you nor us shall make any public announcement or public
              comment or originate any publicity concerning the arbitration,
              including, but not limited to, the fact that the parties are in
              dispute, the existence of the arbitration, or any decision or
              award of the arbitrator.
            </p>

            <h2>22. General Provisions</h2>

            <p class="c2">
              <span class="">22.1 Interpretations</span>. Any heading, caption
              or section title contained herein, and/or any explanation or
              summary columns, is provided only for convenience, and in no way
              alters and/or amend the provisions within the Terms nor shall it
              legally bind us in any way. These Terms are written in English,
              and translated into other languages for your convenience. If a
              translated (non-English) version of these Terms conflicts in any
              way with their English version, the provisions of the English
              version shall prevail.
            </p>
            <p class="c2">
              <span class="">22.2. Force Majeure</span>. Neither we nor you will
              be liable by reason of any failure or delay in the performance of
              its obligations on account of events beyond the reasonable control
              of a party, which may include denial-of-service attacks,
              interruption or failure of the internet or any utility service,
              failures in third-party hosting services, strikes, shortages,
              riots, fires, acts of God, war, terrorism, and governmental
              action.
            </p>
            <p class="c2">
              <span class="">
                22.3. Relationship of the Parties; No Third Party Beneficiaries
              </span>
              . The parties are independent contractors. These Terms and the
              Services provided hereunder, do not create a partnership,
              franchise, joint venture, agency, fiduciary or employment
              relationship between the parties. There are no third party
              beneficiaries to these Terms.
            </p>
            <p class="c2">
              <span class="">22.4. Notice</span>. We shall use your contact
              details that we have in our records, in connection with providing
              you notices, subject to this Section 22.4. Our contact details for
              any notices are detailed below. You acknowledge notices that we
              provide you, in connection with these Terms and/or as otherwise
              related to the Services, shall be provided as follows: via the
              Services, including by posting on our Sites or posting in your
              account, text, in-app notification, e-mail, phone or first class,
              airmail, or overnight courier. You further acknowledge that an
              electronic notification satisfies any applicable legal
              notification requirements, including that such notification will
              be in writing. Any notice to you will be deemed given upon the
              earlier of: (i) receipt; or (ii) 24 hours of delivery. Notices to
              us shall be provided to Amplify Lead Solutions LLC 17991 Cowan,
              Irvine, CA 92614.
            </p>
            <p class="c2">
              <span class="">22.5. Assignment</span>. These Terms, and any and
              all rights and obligations hereunder, may not be transferred or
              assigned by you without our written approval, provided that you
              may assign these Terms to your successor entity or person,
              resulting from a merger, acquisition, or sale of all or
              substantially all of your assets or voting rights, except for an
              assignment to a competitor of Amplify, and provided that you
              provide us with prompt written notice of such assignment and the
              respective assignee agrees, in writing, to assume all of your
              obligations under these Terms. We may assign our rights and/or
              obligations hereunder and/or transfer ownership rights and title
              in the Services to a third party without your consent or prior
              notice to you. Subject to the foregoing conditions, these Terms
              shall bind and inure to the benefit of the parties, their
              respective successors, and permitted assigns. Any assignment not
              authorized under this Section 22.5 shall be null and void.
            </p>
            <p class="c2">
              <span class="">22.6. Severability</span>. These Terms shall be
              enforced to the fullest extent permitted under applicable Law. If
              any provision of these Terms is held by a court of competent
              jurisdiction to be contrary to law, the provision will be modified
              by the court and interpreted so as best to accomplish the
              objectives of the original provision to the fullest extent
              permitted by law, and the remaining provisions of these Terms will
              remain in effect.
            </p>
            <p class="c2">
              <span class="">22.7. No Waiver.</span>
              &nbsp;No failure or delay by either party in exercising any right
              under these Terms will constitute a waiver of that right. No
              waiver under these Terms will be effective unless made in writing
              and signed by an authorized representative of the party being
              deemed to have granted the waiver.
            </p>
            <p class="c2">
              <span class="">22.8. No Reliance</span>. You acknowledge and agree
              that you do not rely on, and shall have no remedy in respect of,
              any statement, warranty and/or representation made (including
              whether innocently or negligently) by us or any other person on
              our behalf except as expressly set out in these Terms or an Order
              Form.
            </p>
            <p class="c9">
              <span class="c6">Subscribe to Receive Updates</span>
              <span class="c1 c0">
                <br />
                These Terms may change from time to time in accordance with
                their terms. Please check back frequently for updates or
                subscribe to get notifications about material changes to these
                Terms.
              </span>
            </p>
            <p class="c13">
              <span class="c1 c7"></span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Terms;
