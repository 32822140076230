import React from 'react'
import './About.css'
import aboutimg1 from '../Image/image 756 (1).png'
import aboutsvgimg from '../Image/Frame 48095580 (1).svg'
import aboutimg3 from '../Image/pexels-rezwan-1145434 1.png'
import aboutimg4 from '../Image/Construction 1.png'
import aboutimg5 from '../Image/young-craftsman-building-house 1.png'


import mobaboutimg3 from '../Image/agcs-global-industry-solutions-report-construction-stage (1) 1.png'
import mobaboutimg4 from '../Image/pexels-wdnet-224924 1.png'
import mobaboutimg5 from '../Image/pexels-rezwan-1116035 1.png'
import mobaboutimg6 from '../Image/construction-site-1-2 1 (1).png'



function About() {
  return (
    <div>
      <div className='container-fluid about-banner-div1'>
        <div className='container'>
            <div className='about-banner-text-div1'>
                      <h1>At Amplify Lead Solutions</h1>
            <p> <span>We excel in curating <span>high-quality leads and fostering connections. </span></span>Our industry<br className='d-md-block d-none' /> expertise empowers lasting growth, making us the ideal partner for your business.</p>
            </div>
          {/* <div className='text-center about-sec2-img1'>
            <img src={aboutimg1} className='img-fluid' alt="" />
          </div> */}
        </div>
      </div>




      <div className='container-fluid about-sec2-div1 position-relative'>
        <img src={aboutsvgimg} className='about-sec2-svg' />
        <div className='container'>
          <div className='text-center about-sec2-img1'>
            <img src={aboutimg1} className='img-fluid' alt="" />
          </div>


          <div className='about-sec2-text-div1'>
            <p>Our specialty lies in crafting premium lead generation and networking opportunities for the construction sector, catering to contractors and real estate developers. At the heart of our success, and that of our clients, lies our skill in securing top-tier leads and transforming them into impactful connections that drive lucrative deals.</p>
            <p>By tapping into our extensive industry knowledge, we empower your business to thrive and turn leads into lasting business growth.  Connect with Amplify Lead Solutions for a partnership cultivated in proven excellence and a commitment to your expansion in the construction industry!</p>
            <div>
              <a href="https://portal.amplifyls.com/leads/registration" target='_blank'><div>Sign Up</div></a>
            </div>
            <div className='d-none'>
              <a href="https://portal.amplifyls.com/leads/registration" target='_blank'><div>Sign Up</div></a>
            </div>
          </div>
        </div>
      </div>

















      <div className='container-fluid about-sec3-div1 d-md-block d-none' >
        <div className='about-sec3-div2'>
          <div className='about-sec3-img-div1'>
            <img src={aboutimg3}  />
          </div>

          <div className='about-sec3-img-div2'>
            <img src={aboutimg4}  />
          </div>

          <div className='about-sec3-img-div3'>
            <img src={aboutimg5} />
          </div>
        </div>
      </div>


      <div className='container-fluid about-sec3-div1 d-md-none d-block' >
        <div className='about-sec3-div2 row'>
          <div className='about-sec3-img-div1'>
            <img src={mobaboutimg3} />
          </div>

          <div className='about-sec3-img-div2'>
            <img src={mobaboutimg4} />
          </div>

          <div className='about-sec3-img-div3'>
            <img src={mobaboutimg5} />
          </div>

          <div className='about-sec3-img-div4'>
            <img src={mobaboutimg6} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default About
