import React from "react";
import './DataProcessing.css'

function DataProcessing() {
  return (
    <div>
      <div className="container-fluid data-div11">
        <div className="container">
          <div>
           <h1>Data Processing Addendum (DPA)</h1>
            <p class="c11">
              <span class="c4">This Data Processing Addendum (&ldquo;</span>
              <span class="c1">DPA</span>
              <span class="c4">
                &rdquo;) is incorporated by reference into Amplify&rsquo;s Terms
                of Service&nbsp;or other agreement governing the use of
                Amplify&rsquo;s services (&ldquo;
              </span>
              <span class="c1">Agreement</span>
              <span class="c4">
                &rdquo;) entered by and between you, the Customer (as defined in
                the Agreement) (collectively,&nbsp;&ldquo;
              </span>
              <span class="c1">you</span>
              <span class="c4">&rdquo;,&nbsp;&ldquo;</span>
              <span class="c1">your</span>
              <span class="c4">&rdquo;, &ldquo;</span>
              <span class="c1">Customer</span>
              <span class="c4">
                &rdquo;), and Amplify Lead Solutions LLC (&ldquo;
              </span>
              <span class="c1">Amplify</span>
              <span class="c4">&rdquo;,&nbsp;&ldquo;</span>
              <span class="c1">us</span>
              <span class="c4">&rdquo;,&nbsp;&ldquo;</span>
              <span class="c1">we</span>
              <span class="c4">&rdquo;,&nbsp;&ldquo;</span>
              <span class="c1">our</span>
              <span class="c4">
                &rdquo;) to reflect the parties&rsquo; agreement with regard to
                the Processing of Personal Data by Amplify solely on behalf of
                the Customer. Both parties shall be referred to as the &ldquo;
              </span>
              <span class="c1">Parties</span>
              <span class="c4">&rdquo; and each, a &ldquo;</span>
              <span class="c1">Party</span>
              <span class="c4">&rdquo;.</span>
            </p>
            <p class="c11">
              <span class="c4">
                Capitalized terms not&nbsp;defined herein&nbsp;shall have
                the&nbsp;meanings assigned to such terms in the Agreement.&nbsp;
              </span>
            </p>
            <p class="c11">
              <span class="c4">
                By using the Services, Customer accepts this DPA and you
                represent and warrant that you have full authority to bind the
                Customer to this DPA. If you cannot, or do not agree to, comply
                with and be bound by this DPA, or do not have authority to bind
                the Customer or any other entity, please do not provide Personal
                Data to us.
              </span>
            </p>
            <p class="c11">
              <span class="c4">
                In the event of any conflict between certain provisions of this
                DPA and the provisions of the Agreement, the provisions of this
                DPA shall prevail over the conflicting provisions of the
                Agreement, solely with respect to the Processing of Personal
                Data.
              </span>
              <span class="c6">&nbsp;</span>
            </p>

            <h2>1. DEFINITIONS</h2>
            <p class="c2">
              <span class="c4">(a)&nbsp;&ldquo;</span>
              <span class="c55">Affiliate</span>
              <span class="c4">
                &rdquo; means any entity that directly or indirectly controls,
                is controlled by, or is under common control with the subject
                entity. &ldquo;Control&rdquo;, for purposes of this definition,
                means direct or indirect ownership or control of more than 50%
                of the voting interests of the subject entity.
              </span>
            </p>
            <p class="c2" id="h.gjdgxs">
              <span class="c4">(b) &ldquo;</span>
              <span class="c55">Authorized Affiliate</span>
              <span class="c4">
                &rdquo; means any of Customer&rsquo;s Affiliate(s) which is
                explicitly permitted to use the Services pursuant to the
                Agreement between Customer and Amplify but has not signed its
                own agreement with Amplify and is not a &ldquo;Customer&rdquo;
                as defined under the Agreement.
              </span>
            </p>
            <p class="c2">
              <span class="c4">(c) &ldquo;</span>
              <span class="c55">CCPA</span>
              <span class="c4">
                &rdquo; means the California Consumer Privacy Act of 2018, Cal.
                Civ. Code &sect;&sect; 1798.100 et. Seq, and its implementing
                regulations, as may be amended from time to time.
              </span>
            </p>
            <p class="c2">
              <span class="c4">(d) The terms, &ldquo;</span>
              <span class="c55">Controller</span>
              <span class="c4">&rdquo;, &ldquo;</span>
              <span class="c55">Member State</span>
              <span class="c4">&rdquo;, &ldquo;</span>
              <span class="c55">Processor&rdquo;</span>
              <span class="c4">, &ldquo;</span>
              <span class="c55">Processing</span>
              <span class="c4">&rdquo; and &ldquo;</span>
              <span class="c55">Supervisory Authority</span>
              <span class="c4">
                &rdquo; shall have the same meaning as in the GDPR. The terms
                &ldquo;
              </span>
              <span class="c55">Business</span>
              <span class="c4">&rdquo;, &ldquo;</span>
              <span class="c55">Business Purpose</span>
              <span class="c4">&rdquo;, &ldquo;</span>
              <span class="c55">Consumer</span>
              <span class="c4">&rdquo; and &ldquo;</span>
              <span class="c55">Service Provider</span>
              <span class="c4">
                &rdquo; shall have the same meaning as in the CCPA.&nbsp;
              </span>
            </p>
            <p class="c2">
              <span class="c4">
                For the purpose of clarity, within this DPA &ldquo;
              </span>
              <span class="c1">Controller</span>
              <span class="c4">&rdquo; shall also mean &ldquo;</span>
              <span class="c1">Business</span>
              <span class="c4">&rdquo;, and &ldquo;</span>
              <span class="c1">Processor</span>
              <span class="c4">&rdquo; shall also mean &ldquo;</span>
              <span class="c1">Service Provider</span>
              <span class="c4">
                &rdquo;, to the extent that the CCPA applies. In the same
                manner, Processor&rsquo;s Sub-processor shall also refer to the
                concept of Service Provider.&nbsp;
              </span>
            </p>
            <p class="c2">
              <span class="c4">(e) &ldquo;</span>
              <span class="c55">Data Protection Laws</span>
              <span class="c4">
                &rdquo; means all applicable and binding privacy and data
                protection laws and regulations, including those of the European
                Union, the European Economic Area and their Member States,
                Switzerland, the United Kingdom, Canada, Israel and the United
                States of America, including the GDPR, the UK GDPR, and the
                CCPA, applicable to, and in effect at the time of, the
                Processing of Personal Data hereunder.
              </span>
            </p>
            <p class="c2">
              <span class="c4">(f) &ldquo;</span>
              <span class="c55">Data Subject</span>
              <span class="c4">
                &rdquo; means the identified or identifiable person to whom the
                Personal Data relates.
              </span>
            </p>
            <p class="c2">
              <span class="c4">(g) &ldquo;</span>
              <span class="c55">GDPR</span>
              <span class="c4">
                &rdquo; means the Regulation (EU) 2016/679 of the European
                Parliament and of the Council of 27 April 2016 on the protection
                of natural persons with regard to the processing of personal
                data and on the free movement of such data.
              </span>
            </p>
            <p class="c2">
              <span class="c4">(h) &ldquo;</span>
              <span class="c55">Personal Data</span>
              <span class="c4">&rdquo; or &ldquo;</span>
              <span class="c1">Personal Information</span>
              <span class="c4">
                &rdquo; means any information that identifies, relates to,
                describes, is capable of being associated with, or could
                reasonably be linked, directly or indirectly, to or with an
                identified or identifiable natural person or Consumer, which is
                processed by Amplify solely on behalf of Customer under this DPA
                and the Agreement.
              </span>
            </p>
            <p class="c2">
              <span class="c4">(i) &ldquo;</span>
              <span class="c55">Services</span>
              <span class="c4">
                &rdquo; means the Amplify cloud-based services including our
                platforms, products, services, applications, application
                programming interface (&ldquo;
              </span>
              <span class="c1">API</span>
              <span class="c4">
                &rdquo;), tools, and any ancillary or supplementary Amplify
                products and services (including Upgrades (as defined in the
                Agreement)), offered online and via mobile application (&ldquo;
              </span>
              <span class="c1">Platform</span>
              <span class="c4">
                &rdquo;), and any other services provided to Customer by Amplify
                under the Agreement.&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </p>
            <p class="c2">
              <span class="c4">(j) &ldquo;</span>
              <span class="c55">Security Documentation</span>
              <span class="c4">
                &rdquo; means the security documentation, as updated from time
                to time setting forth the technical and organizational measures
                adopted by Amplify that are applicable to the Processing of
                Personal Data by Amplify under the Agreement and this DPA made
                reasonably available to Customer by Amplify.
              </span>
            </p>
            <p class="c2">
              <span class="c4">(k) &ldquo;</span>
              <span class="c55">Sensitive Data</span>
              <span class="c4">
                &rdquo; means Personal Data that is protected under a special
                legislation and requires unique treatment, such as
                &ldquo;special categories of data&rdquo;, &ldquo;sensitive
                data&rdquo; or other materially similar terms under applicable
                Data Protection Laws, which may include any of the following:
                (a) social security number, tax file number, passport number,
                driver&rsquo;s license number, or similar identifier (or any
                portion thereof); (b) financial or credit information, credit or
                debit card number; (c) information revealing racial or ethnic
                origin, political opinions, religious or philosophical beliefs,
                trade union membership, genetic data or biometric data for the
                purpose of uniquely identifying a natural person, data
                concerning a person&rsquo;s health, sex life or sexual
                orientation, or data relating to criminal convictions and
                offences; (d) Personal Data relating to children; and/or (e)
                account passwords in unhashed form.
              </span>
            </p>
            <p class="c2" id="h.30j0zll">
              <span class="c4">(l) &ldquo;</span>
              <span class="c55">Standard Contractual Clauses</span>
              <span class="c4">
                &rdquo; means (a) in respect of transfers of Personal Data
                subject to the GDPR, the Standard Contractual Clauses between
                controllers and processors (located&nbsp;
              </span>
              <span class="c1 c10 c13">
                <a
                  class="c3"
                  href="https://www.google.com/url?q=https://monday.com/l/privacy/https-monday-com-l-scc-controller-to-processor/&amp;sa=D&amp;source=editors&amp;ust=1725301641439288&amp;usg=AOvVaw1kqiACHRRmRZIccHpnrHAf"
                >
                  here
                </a>
              </span>
              <span class="c4">
                ), and between processors and processors (located&nbsp;
              </span>
              <span class="c1 c13 c10">
                <a
                  class="c3"
                  href="https://www.google.com/url?q=https://monday.com/l/privacy/https-monday-com-l-scc-processor-to-processor/&amp;sa=D&amp;source=editors&amp;ust=1725301641439722&amp;usg=AOvVaw0-Wn994z7gjSfmyXTr5l8u"
                >
                  here
                </a>
              </span>
              <span class="c4">
                ) as approved by the European Commission Implementing Decision
                (EU) 2021/914 of 4 June 2021, including all Annexes I, II and V
                thereto, (&rdquo;
              </span>
              <span class="c1">EU SCCs</span>
              <span class="c4">
                &rdquo;); (b) in respect of transfers of Personal Data subject
                to the UK GDPR, the International Data Transfer Addendum to the
                EU Commission Standard Contractual Clauses of 21 March 2022
                (version B.1.0) (&ldquo;
              </span>
              <span class="c1">IDTA</span>
              <span class="c4">
                &rdquo;), as incorporated into the EU SCCs through Annex III
                thereto (&ldquo;
              </span>
              <span class="c1">UK Addendum</span>
              <span class="c4">
                &rdquo;); and (c) in respect of transfers subject to the Federal
                Act on Data Protection (as revised as of 25 September 2020), the
                terms set forth in Annex IV of the EU SCCs (&ldquo;
              </span>
              <span class="c1">Switzerland Addendum</span>
              <span class="c4">&rdquo;).</span>
            </p>
            <p class="c2">
              <span class="c4">(m) &ldquo;</span>
              <span class="c55">Sub-processor</span>
              <span class="c4">
                &rdquo; means any third party that carries out specific
                Processing activities of Personal Data under the instruction of
                Amplify.
              </span>
            </p>
            <p class="c2">
              <span class="c4">(n) &ldquo;</span>
              <span class="c55">UK GDPR</span>
              <span class="c4">
                &rdquo; means the Data Protection Act 2018, as well as the GDPR
                as it forms part of the law of England and Wales, Scotland and
                Northern Ireland by virtue of section 3 of the European Union
                (Withdrawal) Act 2018 and as amended by the Data Protection,
                Privacy and Electronic Communications (Amendments etc.) (EU
                Exit) Regulations 2019 (SI 2019/419).
              </span>
            </p>

            <h2>2. PROCESSING OF PERSONAL DATA</h2>
            <p class="c2" id="h.1fob9te">
              <span class="c4">2.1.&nbsp;</span>
              <span class="c1">Roles of the Parties</span>
              <span class="c4">
                .&nbsp;The Parties acknowledge and agree that with regard to the
                Processing of Personal Data solely by Amplify on behalf of
                Customer: (a) Customer is the Controller of Personal Data, and
                (b) Amplify is the Processor of such Personal Data. The terms
                &ldquo;Controller&rdquo; and &ldquo;Processor&rdquo; below
                signify Customer and Amplify, respectively.
              </span>
            </p>
            <p class="c2">
              <span class="c4">2.2.&nbsp;</span>
              <span class="c1">Customer&rsquo;s Obligations</span>
              <span class="c4">
                . Customer, in its use of the Services, and Customer&rsquo;s
                instructions to the Processor, shall comply with Data Protection
                Laws, the Agreement and this DPA. Customer shall establish and
                have any and all required legal bases in order to collect,
                Process and transfer to Processor the Personal Data, and to
                authorize the Processing activities conducted by Processor on
                Customer&rsquo;s behalf in accordance with the Agreement and
                this DPA, including the pursuit of a Business Purpose.&nbsp;
              </span>
            </p>
            <p class="c2" id="h.3znysh7">
              <span class="c4">2.3.&nbsp;</span>
              <span class="c1">
                Processor&rsquo;s Processing of Personal Data
              </span>
              <span class="c4">
                . Processor shall Process Personal Data for the following
                purposes: (a) in accordance with the Agreement and this DPA; (b)
                in connection with its provision of the Services; (c) to comply
                with Customer&rsquo;s reasonable and documented instructions,
                where such instructions are consistent with the terms of the
                Agreement and this DPA, and regard the manner in which the
                Processing shall be performed; (d) to share Personal Data with,
                or receive Personal Data from, third parties in accordance with
                Customer&rsquo;s instructions and/or pursuant to
                Customer&rsquo;s use of the Services (e.g. integrations between
                Services and any services provided by third parties as
                configured by or on behalf of Customer); (e) rendering Personal
                Data to be Anonymous Information (as defined in the Agreement);
                and (f) as required under the laws applicable to Processor,
                and/or as required by a court of competent jurisdiction or other
                competent governmental or semi-governmental authority, provided
                that Processor shall inform Customer of the legal requirement
                before Processing, unless such law or order prohibits disclosing
                such information.
              </span>
            </p>
            <p class="c2">
              <span class="c4">
                Processor shall inform Customer without undue delay if, in
                Processor&rsquo;s reasonable opinion, an instruction for the
                Processing of Personal Data given by Customer infringes
                applicable Data Protection Laws, unless Processor is prohibited
                from notifying Customer under applicable Data Protection Laws.
                It is hereby clarified that Processor has no obligation to
                assess whether instructions by Customer infringe any Data
                Protection Laws.&nbsp;
              </span>
            </p>
            <p class="c2">
              <span class="c4">2.4.&nbsp;</span>
              <span class="c1">Details of Processing</span>
              <span class="c4">
                . The subject-matter of Processing of Personal Data by Processor
                is the performance of the Services pursuant to the Agreement and
                this DPA. The details relating to the duration, nature and
                purpose, types of Personal Data and categories of Data Subjects
                Processed under this DPA are further specified in Schedule 1
                (Details of Processing) to this DPA.
              </span>
            </p>
            <p class="c2">
              <span class="c4">2.5.&nbsp;</span>
              <span class="c1">Sensitive Data</span>
              <span class="c4">
                . The Parties agree that the Services are not intended for the
                Processing of Sensitive Data, and that if Customer wishes to use
                the Services to Process Sensitive Data, it must first obtain the
                Amplify&rsquo;s explicit prior written consent and enter into
                any additional agreements as may be required by Amplify.
              </span>
            </p>
            <p class="c2">
              <span class="c4">2.6.&nbsp;</span>
              <span class="c1">
                CCPA Standard of Care; No Sale or Sharing of Personal
                Information
              </span>
              <span class="c4">
                . Processor acknowledges and confirms that it does not receive
                or process any Personal Information as consideration for any
                services or other items that Processor provides to Customer
                under the Agreement or this DPA. Processor certifies that it
                understands the rules, requirements and definitions of the CCPA
                and agrees to refrain from selling or sharing (as such terms are
                defined in the CCPA) any Personal Information Processed
                hereunder, without Customer&rsquo;s prior written consent or
                instruction, nor take any action that would cause any transfer
                of Personal Information to or from Processor under the Agreement
                or this DPA to qualify as &ldquo;selling&rdquo; and/or
                &ldquo;sharing&rdquo; such Personal Information under the CCPA.
                Processor acknowledges that Customer discloses Personal
                Information to Processor only for limited and specified purposes
                set out in this DPA and the Agreement. Processor shall process
                all Personal Information only (a) for such limited and specific
                purpose(s), and (b) in compliance with applicable sections of
                the CCPA. Processor shall not (i) retain, use, or disclose
                Personal Information outside the direct business relationship of
                the Parties, as described in the Agreement, or for any business
                or commercial purpose other than for the specific business
                purpose of performing the Services or as otherwise permitted by
                the CCPA, the Agreement and/or this DPA; nor (ii) combine
                &ndash; by way of logical separation &ndash; personal
                information that Processor processes on behalf of other parties
                with Personal Information of Customer, unless expressly
                permitted under the CCPA, its implementing regulations, the
                Agreement and/or this DPA between the Parties. Processor further
                acknowledges that Customer has the right, upon notice, to take
                reasonable and appropriate steps designed to stop and remediate
                any unauthorized use of Personal Information by Processor.
                Processor shall notify Customer if Processor makes a
                determination that it can no longer meet its obligations under
                the CCPA.&nbsp;
              </span>
            </p>

            <h2>3. DATA SUBJECT REQUESTS</h2>
            
            <p class="c2" id="h.2et92p0">
              <span class="c4">
                If Processor receives a request from a Data Subject or Consumer
                to exercise their rights (to the extent available to them under
                applicable Data Protection Laws), including of access,
                rectification, restriction of Processing, erasure, data
                portability, objection to the Processing, not to be subject to
                automated individual decision making, to opt-out of the sale of
                Personal Information, or not to be discriminated against
                (&ldquo;
              </span>
              <span class="c1">Data Subject Request</span>
              <span class="c4">
                &rdquo;), Processor shall notify Customer or refer Data Subject
                or Consumer to Customer. Taking into account the nature of the
                Processing, Processor shall assist Customer, insofar as this is
                possible and reasonable, to enable Customer to respond to a Data
                Subject Request. Processor may refer Data Subjects or Consumers
                to the Customer&rsquo;s Admin &ndash; for the treatment of such
                request or advise them on using the self-exercising features
                available within the Platform.&nbsp;
              </span>
            </p>

            <h2>4. CONFIDENTIALITY</h2>
           
            <p class="c2">
              <span class="c4">
                Processor shall ensure that its personnel and contractors
                engaged in the Processing of Personal Data have committed
                themselves to confidentiality or are otherwise under an&nbsp;
                statutory obligation of confidentiality.
              </span>
            </p>

            <h2>5. SUB-PROCESSORS</h2>
           
            <p class="c2">
              <span class="c4">5.1.</span>
              <span class="c1 c10">&nbsp;Appointment of Sub-processors </span>
                Customer acknowledges and agrees that (a) Processor&rsquo;s
                Affiliates may be engaged as Sub-processors; and (b) Processor
                and Processor&rsquo;s Affiliates may each engage third party
                Sub-processors in connection with the provision of the Services.
            </p>
            <p class="c2" id="h.3dy6vkm">
              <span class="c4">5.2.</span>
              <span class="c1 c10">
                &nbsp;List of Current Sub-processors and Notification of New
                Sub-processors
              </span>
            </p>
            <p class="c2" id="h.1t3h5sf">
              <span class="c4">
                5.2.1. As of the Effective Date Customer hereby grants Processor
                general written authorization to engage with the Sub-processors
                that may be provided upon request&nbsp;(&ldquo;
              </span>
              <span class="c1">Sub-processor&rsquo;s Page</span>
              <span class="c4">
                &rdquo;), which are currently used by Processor to process
                Personal Data.
              </span>
            </p>
            <p class="c2" id="h.4d34og8">
              <span class="c4">
                5.2.2.&nbsp;The Sub-processor Page offers a mechanism to
                subscribe to notifications of the engagement of new and the
                replacement of existing Sub-processors (&ldquo;
              </span>
              <span class="c1">Sub-processor Notice</span>
              <span class="c4">
                &rdquo;) and Customer acknowledges that it shall subscribe to
                this mechanism upon entering this DPA and that the notifications
                sent through this mechanism fulfils the Processor&rsquo;s
                obligations to notify Customer of the appointment of a new or
                replacement of an existing Sub-processor.&nbsp;
              </span>
            </p>
            <p class="c2">
              <span class="c4">5.3.&nbsp;</span>
              <span class="c1">Objection to New Sub-processors</span>
              <span class="c4">
                . Pursuant to the publication of a new Sub-processor Notice,
                Customer may reasonably object to Processor&rsquo;s use of a new
                or replacement of a Sub-processor, for reasons relating to the
                protection of Personal Data intended to be Processed by such
                Sub-processor. Such objection must be submitted promptly by
                notifying Processor in writing to&nbsp;
              </span>
              <span class="c8">info@amplifyls.com</span>
              <span class="c4">
                &nbsp;within seven (7) days following publication of a new
                Sub-processor Notice, in which Customer shall detail the reasons
                for the objection to using such new Sub-processor. Where
                Customer has not objected within such seven (7) day period in
                the manner described above, the use of the new Sub-Processor
                shall be deemed accepted by Customer. In the event Customer
                reasonably objects to a new Sub-processor, as permitted in the
                preceding sentences, Processor will use reasonable efforts to
                make available to Customer a change in the Services or recommend
                a commercially reasonable change to Customer&rsquo;s
                configuration or use of the Services to avoid Processing of
                Personal Data by the objected-to new Sub-processor without
                unreasonably burdening the Customer. If Processor is unable to
                make available such change within thirty (30) days following
                receipt of the objection, Customer may, as a sole remedy,
                terminate the Agreement and this DPA with respect only to such
                Services and/or those components of the Services which cannot be
                provided by Processor without the use of the objected-to new
                Sub-processor, by providing written notice to Processor. All
                amounts outstanding under the Agreement before the termination
                date with respect to the Processing at issue shall be duly paid
                to Processor. Until a decision is made regarding the new
                Sub-processor, Processor may temporarily avoid or cease the
                Processing of the affected Personal Data and/or suspend access
                to the respective Services. Customer will have no further claims
                against Processor due to the termination of the Agreement
                (including, without limitation, requesting refunds) and/or the
                DPA in the situation described in this paragraph.
              </span>
            </p>
            <p class="c2">
              <span class="c4">5.4.&nbsp;</span>
              <span class="c1">Agreements with Sub-processors</span>
              <span class="c4">
                . Processor or a Processor&rsquo;s Affiliate has entered into a
                written agreement with each existing Sub-processor, and shall
                enter into a written agreement with each new Sub-processor,
                containing the same or materially similar data protection
                obligations as set out in this DPA, in particular obligations to
                implement appropriate technical and organizational measures in
                such a manner that the Processing will meet the requirements of
                the GDPR. Where a Sub-processor fails to fulfil its data
                protection obligations concerning its Processing of Personal
                Data, Processor shall remain responsible to the Customer for the
                performance of the Sub-processor&rsquo;s obligations.
              </span>
            </p>

            <h2>6. SECURITY AUDITS</h2>
           
            <p class="c2">
              <span class="c4">6.1.&nbsp;</span>
              <span class="c1">
                Controls for the Protection of Personal Data
              </span>
              <span class="c4">
                . Processor shall maintain appropriate industry-standard
                technical and organizational measures for protection of Personal
                Data Processed hereunder (including measures against
                unauthorized or unlawful Processing and against accidental or
                unlawful destruction, loss or alteration or damage, unauthorized
                disclosure of, or access to, Personal Data, confidentiality and
                integrity of Personal Data). Upon Customer&rsquo;s reasonable
                request, Processor will reasonably assist Customer, at
                Customer&rsquo;s cost and subject to the provisions of Section{" "}
              </span>
              <span class="c7">&lrm;</span>
              <span class="c4">
                11.1 below, in ensuring compliance with the obligations pursuant
                to Articles 32 to 36 of the GDPR taking into account the nature
                of the Processing and the information available to Processor.
              </span>
            </p>
            <p class="c2" id="h.2s8eyo1">
              <span class="c4">6.2.&nbsp;</span>
              <span class="c1">Audits and Inspections</span>
              <span class="c4">
                . Upon Customer&rsquo;s 14 days prior written request at
                reasonable intervals (but no more than once every 12 months),
                and subject to strict confidentiality undertakings by Customer,
                Processor shall make available to Customer that is not a
                competitor of Processor (or Customer&rsquo;s independent,
                reputable, third-party auditor that is not a competitor of
                Processor and not in conflict with Processor, subject to their
                confidentiality and non-compete undertakings) information
                necessary to demonstrate compliance with this DPA, and allow for
                and contribute to audits, including inspections, conducted by
                them. Processor may satisfy its obligations under this section
                by answering Customer&rsquo;s questionnaire-based audits and/or
                by providing Customer with attestations, certifications and
                summaries of audit reports conducted by accredited third party
                auditors solely related to Processor&rsquo;s compliance with
                this DPA. Any information relating to audits, inspections and
                the results therefrom, including the documents reflecting the
                outcome thereof, shall only be used by Customer to assess
                Processor&rsquo;s compliance with this DPA, and shall not be
                used for any other purpose or disclosed to any third party
                without Processor&rsquo;s prior written approval. Upon
                Processor&rsquo;s first request, Customer shall transfer to
                Processor all records or documentation that was provided by
                Processor or collected and/or generated by Customer (or each of
                its mandated auditors) in the context of the audit and/or the
                inspection.&nbsp;&nbsp;
              </span>
            </p>
            <p class="c2">
              <span class="c4">
                6.3.&nbsp;In the event of an audit or inspections as set forth
                above, Customer shall ensure that it (and each of its mandated
                auditors) will not cause (or, if it cannot avoid, minimize) any
                damage, injury or disruption to Processor&rsquo;s operations,
                premises, equipment, personnel and business, as applicable,
                while conducting such audit or inspection.
              </span>
            </p>
            <p class="c2">
              <span class="c4">6.4.&nbsp;The audit rights set forth in </span>
              <span class="c7">&lrm;</span>
              <span class="c4">
                6.2 above, shall only apply to the extent that the Agreement
                does not otherwise provide Customer with audit rights that meet
                the relevant requirements of Data Protection Laws (including,
                where applicable, article 28(3)(h) of the GDPR or the UK GDPR).
                If and to the extent that the Standard Contractual Clauses
                apply, nothing in this Section 6 varies or modifies the Standard
                Contractual Clauses nor affects any Supervisory
                Authority&rsquo;s or Data Subject&rsquo;s rights under the
                Standard Contractual Clauses.
              </span>
            </p>

            <h2>7. DATA INCIDENT MANAGEMENT AND NOTIFICATION</h2>
           
            <p class="c2">
              <span class="c4">
                7.1. Processor maintains internal security incident management
                policies and procedures and, to the extent required under
                applicable Data Protection Laws, shall notify Customer without
                undue delay after becoming aware of the accidental or unlawful
                destruction, loss, alteration, unauthorized disclosure of, or
                access to Personal Data Processed by Processor on behalf of the
                Customer (&ldquo;
              </span>
              <span class="c1">Data Incident</span>
              <span class="c4">
                &rdquo;). Processor shall make reasonable efforts to identify
                and take those steps as Processor deems necessary and reasonable
                designed to remediate and/or mitigate the cause of such Data
                Incident to the extent the remediation and/or mitigation is
                within Processor&rsquo;s reasonable control. The obligations
                herein shall not apply to Data Incidents that are caused by
                Customer, its Users or anyone who uses the Services on
                Customer&rsquo;s behalf.&nbsp;
              </span>
            </p>
            <p class="c2">
              <span class="c4">
                7.2. Customer will not make, disclose, release or publish any
                finding, admission of liability, communication, notice, press
                release or report concerning any Data Incident which directly or
                indirectly identifies Processor (including in any legal
                proceeding or in any notification to regulatory or supervisory
                authorities or affected individuals) without Processor&rsquo;s
                prior written approval, unless, and solely to the extent that,
                Customer is compelled to do so pursuant to applicable Data
                Protection Laws. In the latter case, unless prohibited by such
                laws, Customer shall provide Processor with reasonable prior
                written notice to provide Processor with the opportunity to
                object to such disclosure and in any case Customer will limit
                the disclosure to the minimum scope required by such laws.
              </span>
            </p>

            <h2>8. RETURN AND DELETION OF PERSONAL DATA</h2>
            
            <p class="c2">
              <span class="c4">
                Following termination of the Agreement and cessation of the
                Services, at the choice of Customer (indicated through the
                Platform or in written notification to Processor), Processor
                upon notice by Customer, shall delete or return to Customer all
                the Personal Data it Processes on behalf of the Customer in the
                manner described in the Agreement, unless laws applicable to
                Processor requires or permits otherwise.&nbsp;
              </span>
            </p>

            <h2>9. CROSS-BORDER DATA TRANSFERS</h2>
            
            <p class="c2">
              <span class="c4">9.1.&nbsp;</span>
              <span class="c1">
                Transfers from the EEA, Switzerland and the United Kingdom to
                countries that offer an adequate level of data protection
              </span>
              <span class="c4">
                . Personal Data may be transferred from EU Member States and
                Norway, Iceland and Liechtenstein&nbsp; (collectively, &ldquo;
              </span>
              <span class="c1">EEA</span>
              <span class="c4">
                &rdquo;), Switzerland and the United Kingdom (&ldquo;
              </span>
              <span class="c1">UK</span>
              <span class="c4">
                &rdquo;) to countries that offer an adequate level of data
                protection under or pursuant to the adequacy decisions published
                by the relevant authorities of the EEA, Switzerland, and/or the
                UK as relevant, including similarly approved mechanisms and
                frameworks (&ldquo;
              </span>
              <span class="c1">Adequacy Decisions</span>
              <span class="c4">
                &rdquo;), as applicable, without any further safeguard being
                necessary. For the avoidance of doubt, &ldquo;Adequacy
                Decisions&rdquo; include the European Commission&rsquo;s
                adequacy decision of 10 July 2023, establishing the EU-US Data
                Privacy Framework.
              </span>
            </p>
            <p class="c2" id="h.17dp8vu">
              <span class="c4">9.2.&nbsp;</span>
              <span class="c1">Direct</span>
              <span class="c4">&nbsp;</span>
              <span class="c1">
                Transfers from the EEA, Switzerland and the United Kingdom to
                other countries
              </span>
              <span class="c4">
                . If the Processing of Personal Data by Processor includes a
                direct transfer from Customer to Amplify:
              </span>
            </p>
            <p class="c2">
              <span class="c4">
                (i) from the EEA to other countries which have not been subject
                to a relevant Adequacy Decision, and such transfers are not
                performed through an alternative compliance mechanism recognized
                by Data Protection Laws (as may be adopted by Processor in its
                own discretion)&nbsp; (&ldquo;
              </span>
              <span class="c1">EEA Transfer</span>
              <span class="c4">
                &rdquo;), the terms set forth in the EU SCCs shall apply;
              </span>
            </p>
            <p class="c2">
              <span class="c4">
                (ii) from the UK to other countries which have not been subject
                to a relevant Adequacy Decision, and such transfers are not
                performed through an alternative compliance mechanism recognized
                by Data Protection Laws (as may be adopted by Processor in its
                own discretion)&nbsp; (&ldquo;
              </span>
              <span class="c1">UK Transfer</span>
              <span class="c4">
                &rdquo;), the terms set forth in the UK Addendum shall apply;
              </span>
            </p>
            <p class="c2" id="h.3rdcrjn">
              <span class="c4">
                (iii) from Switzerland to other countries which have not been
                subject to a relevant Adequacy Decision, and such transfers are
                not performed through an alternative compliance mechanism
                recognized by Data Protection Laws (as may be adopted by
                Processor in its own discretion) (&ldquo;
              </span>
              <span class="c1">Switzerland</span>
              <span class="c4">&nbsp;</span>
              <span class="c1">Transfer</span>
              <span class="c4">
                &rdquo;), the terms set forth in the Switzerland Addendum shall
                apply;
              </span>
            </p>
            <p class="c2" id="h.26in1rg">
              <span class="c4">
                (iv) the terms set forth in Annex V of the EU SCCs (Additional
                Safeguards) shall apply to any EEA Transfer, UK Transfer and
                Switzerland Transfer, where the Standard Contractual Clauses
                apply.
              </span>
            </p>
            <p class="c2">
              <span class="c4">9.3.&nbsp;</span>
              <span class="c1">
                Onward Transfers from the EEA, Switzerland and the United
                Kingdom to other countries.
              </span>
              <span class="c4">
                &nbsp;Where Processor onward transfers Personal Data from
                countries in the EEA, UK and Switzerland to authorized
                Sub-processors, including Processor Affiliates, in countries
                which are not subject to an Adequacy Decision (respectively, the
                Standard Contractual Clauses (Module 3) set out in the Annex of
                Commission Implementing Decision (EU) 2021/914 of 4 June 2021,
                and any applicable annexes thereto (&ldquo;
              </span>
              <span class="c1">SCCs</span>
              <span class="c4">
                &rdquo;)), the IDTA and/or the SCCs, as adjusted pursuant to the
                Swiss Federal Data Protection and Information
                Commissioner&rsquo;s guidance of 27 August 2021 as between
                Processor and its respective Sub-processors and Subsidiaries,
                shall apply.
              </span>
            </p>
            <p class="c0">
              <span class="c6">9.4.&nbsp;</span>
              <span class="c16">Transfers from other countries</span>
              <span class="c6">
                : If the Processing of Personal Data by Processor includes a
                transfer of Personal Data by and/or mandated by Customer to
                Processor from any other jurisdiction which mandates a
                particular compliance mechanism for the lawful transfer of such
                data be established, Customer shall notify Processor of such
                applicable requirements, and the Parties may seek to make any
                necessary amendments to this DPA in accordance with provisions
                of Section 11.2 below.
              </span>
            </p>

            <h2>10. AUTHORIZED AFFILIATES</h2>
            
            <p class="c2">
              <span class="c4">10.1.&nbsp;</span>
              <span class="c1">Contractual Relationship</span>
              <span class="c4">
                . The Parties acknowledge and agree that, by executing this DPA,
                Customer enters into the DPA on behalf of itself and, as
                applicable, in the name and on behalf of its Authorized
                Affiliates, in which case each Authorized Affiliate agrees to be
                bound by the Customer&rsquo;s obligations under this DPA, if and
                to the extent that Processor Processes Personal Data on the
                behalf of such Authorized Affiliates, thus qualifying them as
                the &ldquo;
              </span>
              <span class="c1">Controller</span>
              <span class="c4">
                &rdquo; with respect to the Personal Data Processed on their
                behalf. All access to and use of the Services by Authorized
                Affiliates must comply with the terms and conditions of the
                Agreement and this DPA and any violation of the terms and
                conditions therein by an Authorized Affiliate shall be deemed a
                violation by Customer.
              </span>
            </p>
            <p class="c2">
              <span class="c4">10.2.&nbsp;</span>
              <span class="c1">Communication</span>
              <span class="c4">
                . Customer shall remain responsible for coordinating all
                communication with Processor under the Agreement and this DPA
                and shall be entitled to make and receive any communication in
                relation to this DPA on behalf of its Authorized Affiliates.
              </span>
            </p>

            <h2>11. OTHER PROVISIONS</h2>
            <p class="c2" id="h.lnxbz9">
              <span class="c4">11.1.&nbsp;</span>
              <span class="c1">
                Data Protection Impact Assessment and Prior Consultation
              </span>
              <span class="c4">
                . Upon Customer&rsquo;s reasonable request, Processor shall
                provide Customer, at Customer&rsquo;s cost, with reasonable
                cooperation and assistance needed to fulfil Customer&rsquo;s
                obligation under the GDPR or the UK GDPR (as applicable) to
                carry out a data protection impact assessment related to
                Customer&rsquo;s use of the Services, to the extent Customer
                does not otherwise have access to the relevant information, and
                to the extent such information is available to Processor.
                Processor shall provide, at Customer&rsquo;s cost, reasonable
                assistance to Customer in the cooperation or prior consultation
                with the Supervisory Authority in the performance of its tasks
                relating to this Section 11.1, to the extent required under the
                GDPR or the UK GDPR, as applicable.
              </span>
            </p>
            <p class="c2" id="h.35nkun2">
              <span class="c4">11.2.&nbsp;</span>
              <span class="c1">Modifications</span>
              <span class="c4">
                . Each Party may by at least forty-five (45) calendar days prior
                written notice to the other Party, request in writing any
                variations to this DPA if they are required as a result of any
                change in applicable Data Protection Laws to allow Processing of
                Customer Personal Data to be made (or continue to be made)
                without breach of such Data Protection Laws. Pursuant to such
                notice the Parties shall use commercially reasonable efforts to
                accommodate such required modification, and negotiate in good
                faith with a view to agreeing and implementing those or
                alternative variations designed to address the requirements
                under applicable Data Protection Law as identified in
                Customer&rsquo;s or Processor&rsquo;s notice as soon as is
                reasonably practicable.&nbsp; In addition, Processor may amend
                this DPA from time to time without notice, provided that such
                changes are not adverse in any material aspect with respect to
                the Customer&rsquo;s rights or Processor&rsquo;s obligations
                (i.e. error and typos fixing, making technical adjustments or
                for any other reasons as Processor deems necessary). For
                clarity, if Processor makes any material adverse change to
                Customer&rsquo;s rights or Processor&rsquo;s obligations,
                Processor will notify Customer by posting an announcement on the
                site, via the Platform and/or by sending an email.
              </span>
            </p>

            <h2>SCHEDULE 1 DETAILS OF THE PROCESSING</h2>
            
            <p class="c2">
              <span class="c55">Nature and Purpose of Processing</span>
            </p>
            <p class="c2">
              <span class="c4">1. Providing the Services to Customer;</span>
            </p>
            <p class="c2">
              <span class="c4">
                2. Performing the Agreement, this DPA and/or other contracts
                executed by and between the Parties;
              </span>
            </p>
            <p class="c2">
              <span class="c4">
                3. Acting upon Customer&rsquo;s instructions, where such
                instructions are consistent with the terms of the Agreement;
              </span>
            </p>
            <p class="c2">
              <span class="c4">
                4. Sharing Personal Data with third parties in accordance with
                Customer&rsquo;s instructions and/or pursuant to
                Customer&rsquo;s use of the Services (e.g., integrations between
                the Services and any services provided by third parties, as
                configured by or on behalf of Customer to facilitate the sharing
                of Personal Data between the Services and such third party
                services);
              </span>
            </p>
            <p class="c2">
              <span class="c4">
                5. Rendering Personal Data to be Anonymous Information;
              </span>
            </p>
            <p class="c2">
              <span class="c4">
                6. Complying with applicable laws and regulations;
              </span>
            </p>
            <p class="c2">
              <span class="c4">7. All tasks related to any of the above.</span>
            </p>
            <p class="c2">
              <span class="c55">Duration of Processing</span>
            </p>
            <p class="c2">
              <span class="c4">
                Subject to any section of the DPA and/or the Agreement dealing
                with the duration of the Processing and the consequences of the
                expiration or termination thereof, Processor will Process
                Personal Data for the duration of the Agreement and provision of
                the Services thereunder, unless otherwise agreed upon in
                writing.
              </span>
            </p>
            <p class="c2">
              <span class="c55">Type of Personal Data</span>
            </p>
            <p class="c2">
              <span class="c4">
                Customer may submit Personal Data to the Services, the type and
                extent of which is determined and controlled by Customer in its
                sole discretion.
              </span>
            </p>
            <p class="c2">
              <span class="c55">Categories of Data Subjects</span>
            </p>
            <p class="c2">
              <span class="c4">
                The Categories of Data Subjects relating to the Personal Data
                that will be processed by Processor are dependent on the
                Customer, and may include, but are not limited to, any of the
                following categories:
              </span>
            </p>
            <p class="c2">
              <span class="c7">&#9679;</span>
              <span class="c4">
                &nbsp; Employees, agents, advisors, freelancers of Customer (who
                are natural persons)
              </span>
            </p>
            <p class="c2">
              <span class="c7">&#9679;</span>
              <span class="c4">
                &nbsp; Prospects, customers, business partners and vendors of
                Customer (who are natural persons)
              </span>
            </p>
            <p class="c2">
              <span class="c7">&#9679;</span>
              <span class="c4">
                &nbsp; Employees or contact persons of Customer&rsquo;s
                prospects, customers, business partners and vendors
              </span>
            </p>
            <p class="c2">
              <span class="c7">&#9679;</span>
              <span class="c4">
                &nbsp; Any other third party individual whose Personal Data
                is&nbsp;&nbsp;Processed by the Services.
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DataProcessing;
