import React from "react";
import './Cookies.css'
import { Link } from "react-router-dom";

function Cookies() {
  return (
    <div>
      <div className="container-fluid cookies-div1">
        <div className="container">
          <div>

            <h1>Cookie Policy</h1>
           
            <p class="c3">
              <span class="c0">
                Amplify uses certain monitoring and tracking technologies, such
                as cookies, beacons, pixels, tags, and scripts (collectively,
                &ldquo;
              </span>
              <span class="c7">Cookies</span>
              <span class="c0">
                &rdquo;). These technologies are used in order to provide,
                maintain, and improve our website and platform or any other
                website or webpage operated by us (&ldquo;
              </span>
              <span class="c7">Services</span>
              <span class="c0">
                &rdquo;), to optimize our offerings and marketing activities,
                and to provide our visitors, customers and users (&ldquo;
              </span>
              <span class="c7">you</span>
              <span class="c8 c0">
                &rdquo;) with a better experience (for example, in order to
                track users&rsquo; preferences, to better secure our Services,
                to identify technical issues, and to monitor and improve the
                overall performance of our Services).
              </span>
            </p>
            <p class="c3">
              <span class="c8 c0">
                This page contains information on what Cookies are, the types of
                Cookies used on our Services, how to switch Cookies off in your
                browser, and some useful links for further reading on the
                subject. If you are unable to find the information you were
                looking for, or you have any further questions about the use of
                cookies on our Services, please email&nbsp;info@amplifyls.com.
              </span>
            </p>
            <p class="c3">
              <span class="c0">
                For more information about our general privacy practices, please
                visit our&nbsp;
              </span>
              <span class="c5"><Link to="/privacy-policy">Privacy Policy</Link></span>
              <span class="c8 c0">.</span>
            </p>

            <h2>1. What are Cookies?</h2>
            <p class="c3">
              <span class="c8 c0">
                Cookies are small files containing a string of characters that
                are stored through the browser on your computer or mobile device
                (for example, Google Chrome or Safari). They allow websites to
                store information like user preferences. You can think of
                Cookies as providing a so-called memory for the website, so that
                it can recognize you when you come back and throughout your
                browsing activity and use, and respond appropriately to your
                selected preferences. Cookies are typically classified as either
                &ldquo;session cookies&rdquo; which are automatically deleted
                when you close your browser, or &ldquo;persistent cookies&rdquo;
                which will usually remain on your device until you delete them
                or they expire.
              </span>
            </p>
            <p class="c3">
              <span class="c8 c0">
                Cookies may be set by us (first-party Cookies) or by third-party
                providers who work with us (third-party Cookies), either for the
                duration of your visit (session Cookies) or for longer periods
                (persistent Cookies). The length of time a persistent Cookie
                stays on your device varies between Cookies.
                <br />
                Please note that our access and control over such third-party
                Cookies, beyond the scope of our Services, is limited and
                subject to such other websites, services and providers&rsquo;
                own terms and policies.
              </span>
            </p>
            <p class="c3">
              <span class="c8 c0">
                You are not obligated to accept all Cookies in order to visit
                our website, however, enabling Cookies may allow for a more
                personalized browsing experience and is required for some
                features and functionalities to work.
              </span>
            </p>

            <h2>2. How do we use Cookies?</h2>
            <p class="c3">
              <span class="c8 c0">
                Amplify uses several different types of Cookies on our website
                and platform:
              </span>
            </p>
            <p class="c3">
              <span class="c1">Necessary Cookies</span>
              <span class="c8 c0">
                : These Cookies are necessary to provide you with our Services
                and cannot be disabled through the cookie settings we offer,
                however if you wish you may still remove them through your
                browser for your website&rsquo;s usage (but this would have a
                significant adverse effect on your experience). For example,
                when you register and sign into our Services, we generate
                Cookies that let us know whether you are signed in or not, and
                maintain your login session. Our servers use these Cookies to
                work out which account on our Services you are signed into and
                if you are allowed access to a particular area or feature on
                such account.
              </span>
            </p>
            <p class="c3">
              <span class="c1">Performance and Analytics Cookies</span>
              <span class="c8 c0">
                : Every time you visit our Services, the analytics tools and
                services we use generate or interact with Cookies which can tell
                us (so long as they are allowed and not deleted) whether or not
                you have visited our Services in the past, and provide
                additional information regarding how visitors and users use our
                Services (such as how many visitors we have on a certain landing
                page, how often they visit, or where users tend to click on our
                Services). Your browser will tell us if you have these Cookies
                and, if you don&rsquo;t but do allow new Cookies to be placed,
                we will typically generate and place new ones. These Cookies
                help us to secure and better manage the performance of our
                Services, and remember your preferences for features found on
                the Services, so you don&rsquo;t have to reset them each time
                you visit. Without these cookies, our ability to better
                ourselves and improve your and others&rsquo; experience will be
                reduced.
              </span>
            </p>
            <p class="c3">
              <span class="c1">Marketing &amp; Advertising Cookies</span>
              <span class="c8 c0">
                : These Cookies allow us to know whether or not you&rsquo;ve
                seen an ad or a type of ad online, how you interacted with such
                an ad, and how long it has been since you&rsquo;ve seen it.
              </span>
            </p>
            <p class="c3">
              <span class="c8 c0">
                Our advertising partners may use these Cookies to learn about
                your browsing habits (including your visits to our Services, the
                pages you have visited and the links and advertisements you have
                clicked) and other unique identifiers that may help identify you
                and your interests, in order to retarget you and serve
                advertisements that are relevant to you (for example, if you
                look at a certain page on our Services, an advertisement may be
                delivered to you regarding our Services on other sites, for
                products referenced on that page or for similar products and
                services).
              </span>
            </p>
            <p class="c3">
              <span class="c8 c0">
                We also set Cookies on certain other sites that we advertise on.
                If you receive one of those Cookies, we may use it to identify
                you as having visited that site and viewing our ad there, if you
                later visit our Services. We can then target our advertisements
                based on this information.
              </span>
            </p>
            <p class="c3">
              <span class="c1">Third-Party Integration Cookies</span>
              <span class="c8 c0">
                : On some pages of our Services, other organizations may also
                set their own Cookies. They do this to enable and improve the
                performance and interoperability of their applications, features
                or tools that are integrated with our Services, to track their
                performance, or to customize their services for you.
              </span>
            </p>
            <p class="c3">
              <span class="c8 c0">
                To learn more about the specific Cookies we use and how we
                categorize them, please click the &ldquo;Your Privacy
                Settings&rdquo; button available on our website&rsquo;s footer.
                You can always withdraw your consent to the use of non-necessary
                Cookies by re-opening the settings and modifying your selection.
              </span>
            </p>

            <h2>3. How do we use Cookies?</h2>
            <p class="c3">
              <span class="c8 c0">
                We use Cookies to help our website and online pages work, or
                work more efficiently, as well as to provide us with various
                information on your interactions and activities with our website
                and online pages.
              </span>
            </p>
            <p class="c3">
              <span class="c8 c0">
                Our use of analytics Cookies aims to monitor, study and analyze
                the use of our Services, and how we can improve
                individuals&rsquo; user experience and continue to improve our
                offerings and the overall performance of our Services.
              </span>
            </p>
            <p class="c3">
              <span class="c8 c0">
                Our use of advertising Cookies aims to facilitate and optimize
                our marketing campaigns, ad management and sales operations, and
                manage and deliver advertisements for our products and services
                more effectively, including on other websites and applications.
              </span>
            </p>
            <p class="c3">
              <span class="c8 c0">
                We may combine non-personally identifiable information collected
                through Cookies with other Personal Data that we have about you
                to improve your user experience, for example, to tell us who you
                are or whether you already have an account with us.
              </span>
            </p>
            <p class="c3">
              <span class="c8 c0">
                We may also supplement the information we collect from you with
                information received from third parties in order to enhance our
                Services, or to offer you information that we believe may be of
                interest to you.
              </span>
            </p>
            <p class="c3">
              <span class="c8 c0">
                Where we use Cookies to collect information that is personally
                identifiable or that can become personally identifiable if we
                combine it with other information, our Privacy Policy will apply
                in addition to this Cookie Policy.
              </span>
            </p>

            <h2>4. How can you turn Cookies off (or remove them)?</h2>
            <p class="c3">
              <span class="c8 c0">
                Except for Necessary Cookies, you generally have a right to
                decide whether to accept or decline Cookies. When first visiting
                our website, you may encounter our Cookie Banner, which allows
                you to control your Cookie preferences. You can change your
                Cookie choices any time by clicking the &ldquo;Your Privacy
                Settings&rdquo; button available in our website&rsquo;s footer.
              </span>
            </p>
            <p class="c3">
              <span class="c8 c0">
                Most web browsers allow you to change your Cookie settings. You
                can usually find these settings in the &lsquo;Options&rsquo; or
                &lsquo;Preferences&rsquo; menu of your browser. In order to
                understand these settings, the following links to
                &lsquo;cookies&rsquo; help pages may be helpful or you can use
                the &lsquo;Help&rsquo; option in your browser for more details.
              </span>
            </p>
            <ul class="c4 lst-kix_list_1-0 start">
              <li class="c10 li-bullet-0">
                <span class="c5">
                  <a
                    class="c2"
                    href="https://www.google.com/url?q=https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies%23ie%3Die-10&amp;sa=D&amp;source=editors&amp;ust=1725291510465659&amp;usg=AOvVaw3rzLOaweySJ5DJnFiPtzHd"
                  >
                    Internet Explorer
                  </a>
                </span>
                <span class="c11">
                  <a
                    class="c2"
                    href="https://www.google.com/url?q=https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies%23ie%3Die-10&amp;sa=D&amp;source=editors&amp;ust=1725291510466053&amp;usg=AOvVaw0uIq4AH7vVBXwsc-IAPdXC"
                  >
                    &nbsp;
                  </a>
                </span>
              </li>
              <li class="c6 li-bullet-0">
                <span class="c5">
                  <a
                    class="c2"
                    href="https://www.google.com/url?q=https://support.google.com/chrome/answer/95647?hl%3Den%26ref_topic%3D14666&amp;sa=D&amp;source=editors&amp;ust=1725291510466483&amp;usg=AOvVaw264E6klzHnvRbVzi1HNzca"
                  >
                    Chrome
                  </a>
                </span>
              </li>
              <li class="c6 li-bullet-0">
                <span class="c5">
                  <a
                    class="c2"
                    href="https://www.google.com/url?q=https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer&amp;sa=D&amp;source=editors&amp;ust=1725291510466830&amp;usg=AOvVaw0CEtxRqpQ6kSx0s7_ur_EG"
                  >
                    Mozilla Firefox
                  </a>
                </span>
                <span class="c11">
                  <a
                    class="c2"
                    href="https://www.google.com/url?q=https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer&amp;sa=D&amp;source=editors&amp;ust=1725291510467113&amp;usg=AOvVaw2VO25CxA9yPnBT7wmODsqu"
                  >
                    &nbsp;
                  </a>
                </span>
              </li>
              <li class="c6 li-bullet-0">
                <span class="c5">
                  <a
                    class="c2"
                    href="https://www.google.com/url?q=https://support.apple.com/en-il/guide/safari/sfri11471/mac&amp;sa=D&amp;source=editors&amp;ust=1725291510467520&amp;usg=AOvVaw2E8ow7pomWK8tgb8jKfYwm"
                  >
                    Safari (Desktop)
                  </a>
                </span>
                <span class="c11">
                  <a
                    class="c2"
                    href="https://www.google.com/url?q=https://support.apple.com/en-il/guide/safari/sfri11471/mac&amp;sa=D&amp;source=editors&amp;ust=1725291510467733&amp;usg=AOvVaw1B0Hv27XIWsVzz6vMQLHyY"
                  >
                    &nbsp;
                  </a>
                </span>
              </li>
              <li class="c6 li-bullet-0">
                <span class="c5">
                  <a
                    class="c2"
                    href="https://www.google.com/url?q=https://support.apple.com/en-us/HT201265&amp;sa=D&amp;source=editors&amp;ust=1725291510468048&amp;usg=AOvVaw0yPzeTmrfescETIcjEOFdO"
                  >
                    Safari (Mobile)
                  </a>
                </span>
                <span class="c11">
                  <a
                    class="c2"
                    href="https://www.google.com/url?q=https://support.apple.com/en-us/HT201265&amp;sa=D&amp;source=editors&amp;ust=1725291510468224&amp;usg=AOvVaw2EwQp-Cbu5UZo6wXtXZY7B"
                  >
                    &nbsp;
                  </a>
                </span>
              </li>
              <li class="c6 li-bullet-0">
                <span class="c5">
                  <a
                    class="c2"
                    href="https://www.google.com/url?q=https://support.google.com/nexus/answer/54068?visit_id%3D637243648929008699-3576234356%26hl%3Den%26rd%3D1%26co%3DGENIE.Platform%253DAndroid%26oco%3D1&amp;sa=D&amp;source=editors&amp;ust=1725291510468578&amp;usg=AOvVaw1CE2CeUPBBMm8pHkPlApPO"
                  >
                    Android Browser
                  </a>
                </span>
              </li>
              <li class="c3 c13 li-bullet-0">
                <span class="c5">
                  <a
                    class="c2"
                    href="https://www.google.com/url?q=https://help.opera.com/en/latest/web-preferences/%23cookies&amp;sa=D&amp;source=editors&amp;ust=1725291510468948&amp;usg=AOvVaw2Z1aZTbEtsA1JEw_7e7UV6"
                  >
                    Opera&nbsp;
                  </a>
                </span>
              </li>
            </ul>
            <p class="c3">
              <span class="c8 c0">
                If you are primarily concerned about third-party Cookies
                generated by advertisers, and you live in the USA, Canada or
                Europe, you can also opt out from the collection of your data by
                our advertising partners who participate in the Digital
                Advertising Alliance (DAA), the Network Advertising Initiative
                (NAI), and the European Digital Advertising Alliance (eDAA).
                This does not opt you out of being served advertising entirely
                as you will continue to receive generic advertisements. Opt-out
                by visiting:
              </span>
            </p>
            <ul class="c4 lst-kix_list_2-0 start">
              <li class="c10 li-bullet-0">
                <span class="c5">
                  <a
                    class="c2"
                    href="https://www.google.com/url?q=http://www.aboutads.info/choices&amp;sa=D&amp;source=editors&amp;ust=1725291510469383&amp;usg=AOvVaw17aR2mPCw31Q9EQo9hz5Zk"
                  >
                    http://www.aboutads.info/choices
                  </a>
                </span>
                <span class="c8 c0">&nbsp;(for U.S. users)</span>
              </li>
              <li class="c6 li-bullet-0">
                <span class="c5">
                  <a
                    class="c2"
                    href="https://www.google.com/url?q=https://youradchoices.ca/en/tools&amp;sa=D&amp;source=editors&amp;ust=1725291510469690&amp;usg=AOvVaw096EpxMlOub91XYZ6x1VzZ"
                  >
                    https://youradchoices.ca/en/tools
                  </a>
                </span>
                <span class="c8 c0">&nbsp;(for Canadian users)</span>
              </li>
              <li class="c6 li-bullet-0">
                <span class="c5">
                  <a
                    class="c2"
                    href="https://www.google.com/url?q=https://www.youronlinechoices.eu/&amp;sa=D&amp;source=editors&amp;ust=1725291510470003&amp;usg=AOvVaw37nUhCPoRZlQn4S-A4lJJF"
                  >
                    https://www.youronlinechoices.eu/
                  </a>
                </span>
                <span class="c8 c0">&nbsp;(for European users)</span>
              </li>
              <li class="c3 c13 li-bullet-0">
                <span class="c5">
                  <a
                    class="c2"
                    href="https://www.google.com/url?q=https://www.youronlinechoices.com/uk/your-ad-choices&amp;sa=D&amp;source=editors&amp;ust=1725291510470313&amp;usg=AOvVaw1EOXuA5pCmeT1IMiHR-rzl"
                  >
                    https://www.youronlinechoices.com/uk/your-ad-choices
                  </a>
                </span>
                <span class="c8 c0">&nbsp;(for UK users)</span>
              </li>
            </ul>
            <p class="c3">
              <span class="c8 c0">
                In addition, on your mobile device (e.g., iPhone, iPad or
                Android), you can change your device settings to control whether
                you see online interest-based ads.
              </span>
            </p>

            <h2>5. What is the Google Analytics Cookie and how is it used?</h2>
            
            <p class="c3">
              <span class="c0">
                Our Services use Google Analytics, a web analysis service
                provided by Google Inc. (&ldquo;
              </span>
              <span class="c7">Google</span>
              <span class="c0 c8">
                &rdquo;) which is based on Cookie technology. The information
                generated by the Cookie is usually sent to and stored in a
                Google server in the USA. On behalf of Amplify, Google will use
                the generated information to evaluate your use of the website,
                to compile reports on website activities, and to provide the
                website operator with additional services connected with website
                and Internet use. The IP address transmitted by your browser in
                connection with Google Analytics is not collated with other data
                by Google. Further information about the privacy practices of
                Google Analytics is available at
                www.google.com/policies/privacy/partners/. Further information
                about your option to opt-out of Google Analytics is available at
                https://tools.google.com/dlpage/gaoptout.
              </span>
            </p>


            <h2>6. Do Not Track Signals</h2>
           
            <p class="c3">
              <span class="c8 c0">
                Some web browsers may transmit &ldquo;Do Not Track&rdquo;
                signals to websites with which the browser communicates, telling
                the website not to follow its online movements. Because of
                differences in how web browsers interpret this feature and send
                those signals, and lack of standardization, it is not always
                clear whether visitors and users intend for these signals to be
                transmitted or whether they are even aware of them. Therefore,
                as many other reputable websites and online platform, we
                currently do not respond to such &ldquo;Do Not Track&rdquo;
                signals. However, you can manage your Cookie preferences,
                including whether or not to accept them and how to remove them,
                through your browser settings and our Cookie Banner as
                instructed above. Please bear in mind that disabling Cookies may
                complicate or even prevent you from using the Services. To learn
                more about &ldquo;Do Not Track&rdquo; signals, you can visit
                http://www.allaboutdnt.com/.
              </span>
            </p>

            <h2>7. Opt-Out of Sale/Sharing for Targeted Advertising</h2>
            
            <p class="c3">
              <span class="c8 c0">
                Under some data protection laws in the United States, like the
                California Consumer Privacy Act (CCPA) or the Virginia Consumer
                Data Protection Act, our sharing of certain internet activity
                and device information with third parties through Cookies may be
                considered a &ldquo;sale&rdquo; or &ldquo;sharing&rdquo; of
                personal information for targeted advertising. We do so in
                pursuit of the business and commercial purposes described in
                section 3 above.
              </span>
            </p>
            <p class="c3">
              <span class="c8 c0">
                For the purposes of the CCPA, in the past 12 months we have
                &ldquo;sold&rdquo; or &ldquo;shared&rdquo; (for targeted
                advertising) Internet or Other Electronic Network Activity
                Information, Geolocation Data, and Commercial Information with
                our analytics and advertising partners.
              </span>
            </p>
            <p class="c3">
              <span class="c8 c0">
                You may opt out of all Cookies that may result in a
                &ldquo;sale&rdquo; and/or &ldquo;sharing&rdquo; of your personal
                information for targeted advertising in the following ways:
              </span>
            </p>
            <p class="c3">
              <span class="c8 c0">
                Click the &ldquo;Your Privacy Settings&rdquo; button on the
                footer of our website, or click the &ldquo;Do Not Sell or Share
                My Personal Information&rdquo; link within the cookie consent
                banner and toggle off: &ldquo;Allow sale and sharing of Personal
                Information&rdquo;, then click the &ldquo;Save Settings&rdquo;
                button. Please note: If you visit us from a different device or
                browser, or clear Cookies, then you need to return to this
                screen to re-select your preferences.
              </span>
            </p>
            <p class="c3">
              <span class="c8 c0">
                Set the Global Privacy Control (GPC) for each participating
                browser system that you use to opt out of the use of third-party
                Advertising Cookies (instructions on how to download and use GPC
                are available here).
              </span>
            </p>

            <h2>8. Useful Links</h2>
            <p class="c3">
              <span class="c8 c0">
              
                To find out more about Cookies and their use on the Internet,
                you may find the following websites useful:
              </span>
            </p>
            <p class="c3">
              <span class="c5">
                <a
                  class="c2"
                  href="https://www.google.com/url?q=http://www.allaboutcookies.org&amp;sa=D&amp;source=editors&amp;ust=1725291510472290&amp;usg=AOvVaw1MlWB1MDtIvHH-zUWMQ4FX"
                >
                  www.allaboutcookies.org
                </a>
              </span>
              <span class="c0">
                <br />
              </span>
              <span class="c5">
                <a
                  class="c2"
                  href="https://www.google.com/url?q=http://www.youronlinechoices.com/uk/&amp;sa=D&amp;source=editors&amp;ust=1725291510472573&amp;usg=AOvVaw3x8is8XySU7SPdy6MJqfM1"
                >
                  www.youronlinechoices.com/uk/
                </a>
              </span>
            </p>
            <p class="c9">
              <span class="c8 c16"></span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cookies;
