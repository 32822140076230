import React, { useState, useEffect } from 'react'
import './Event.css'
import eventimg1 from '../Image/Amplify Hangar 24 flier.png'
import eventimg2 from '../Image/Amplify Water Grill flier.jpg'

function Event() {
  const [ads, setads] = useState(true);

  const [events, setEvents] = useState([]);

  useEffect(() => {
    fetch('https://events.amplifyls.com/wp-json/wp/v2/events?acf_format=standard&_fields=id,acf')
      .then((response) => response.json())
      .then((data) => setEvents(data))
      .catch((error) => console.error('Error fetching events:', error));
  }, []);
  return (
    <div>
      <div className='container-fluid event-banner-div1'>
        <div className='container'>
            <div className='event-banner-text-div1'>
            <h1>Explore what’s new & happening</h1>
            <p>With Amplify’s Latest Events</p>
            <div className='event-btn1 d-flex justify-content-center'>
              <a target='_blank' href="https://amplifyleadsolutuions-test.chargebee.com/pages/v4/ENexDbcuHrQnCOFp0x6EzmdIK0uOiBi2p/cart" ><div>Book Event</div></a>
            </div>

            <div className=' event-addons-div11'>
              <div className='row'>
                {events.map((event) => {
                  return (
                    <div className='col-lg-6'>
                      <div className='event-addons-card-div1'>
                        <div className='event-addons-cardtext-div2'>
                          <div className='my-auto'>
                            <h3>{event && event.acf.event_title}</h3>
                            <p className='text-left'>{event && event.acf.event_description}</p>
                          </div>
                          <div className='position-relative'>
                            <div className='price-tag11'>
                              <h4>{`$ ${event && event.acf.event_price}`}</h4>
                            </div>
                            <img src={event && event.acf.event_image} className='' alt="" />
                          </div>
                        </div>

                      </div>
                    </div>
                  )

                })}



              </div>
            </div>
            </div>
        </div>
      </div>
    </div>
  )
}

export default Event
