import React from 'react'
import './Contact.css'
import formimg1 from '../Image/u-s-construction-companies 1.png'
import icon1 from '../Image/Group 1000002492.svg'
import icon2 from '../Image/Combined Shape.svg'
import icon3 from '../Image/Path 76.png'


function Contact() {
  return (
    <div>
      <div className='container-fluid contact-banner-div1'>
        <div className='container'>
          <div className='contact-banner-text-div1'>
            <h1>Contact us</h1>
            <p>Have questions, suggestions, or just want to chat? We're all <br className='d-md-block d-none' /> ears! Fill out the form below and we'll get back to you shortly.</p>
          </div>
        </div>
      </div>



      <div className='container-fluid contact-form-div1'>
        <div className='container'>
          <div className='row contact-form-row-div1 '>
            <div className='col-lg-6'>
              <div className='contact-form-div2'>
                <form className="contact-form" >
                  <div class="row ">
                    <div class="col-lg-12 ">
                      <div class="d-flex form-input-field-div1">
                        <img src={icon1} alt="" />
                        <input
                          type="text"
                          name="Name"
                          class="form-control form-input-field"
                          placeholder="Name"
                        />
                      </div>
                    </div>

                    <div class="col-lg-12 ">
                      <div class="d-flex form-input-field-div1">
                        <img src={icon2} alt="" />
                        <input
                          type="email"
                          name="email"
                          class="form-control form-input-field"
                          placeholder="Email"
                        />
                      </div>
                    </div>

                    <div class="col-lg-12 ">
                      <div class="d-flex form-input-field-div1">
                        <img src={icon3} alt="" />
                        <input
                          type="number"
                          name="phone"
                          class="form-control form-input-field"
                          placeholder="Phone"
                        />
                      </div>
                    </div>

                    <div class="col-lg-12 ">
                      <div class="d-flex ">
                        <textarea
                          type="text"
                          name="message"
                          class="form-control form-textarea-field1"
                          placeholder="Message Here"
                          rows={7}
                        ></textarea>
                      </div>
                    </div>

                    <div className=' col-12 form-btn1-div1'>
                      <button className='form-btn1'>Send Message</button>
                    </div>

                  </div>
                </form>
              </div>
            </div>
            {/* <div className='col-lg-6 contact-col2-div1 d-md-block d-none'>
              <div>
                <img src={formimg1} className='img-fluid ' alt="" />
              </div>
            </div> */}

            {/* <div className='col-lg-6 contact-col2-div1 d-md-none d-block'>
              <div>
                <img src={formimg1} className='img-fluid mb-4' alt="" />
              </div>
            </div> */}

          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact
