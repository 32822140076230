import React, { useState, useEffect } from 'react'
import './Home.css'
import bannerimg1 from '../Image/webp/excavator-digging-ground-day-light 1.webp'

import logo1 from '../Image/2017-SnyderLangston_full-logo_large.png'
import logo2 from '../Image/3826111_Kiewit-Ca-color.svg'
import logo3 from '../Image/CW-Driver_Companies-LOGO.svg'
import logo4 from '../Image/camden-property-trust-logo.svg'
import logo5 from '../Image/five-point-logo.svg'
import logo6 from '../Image/Grey-Star-300x300.svg'
import logo7 from '../Image/img-2-1.svg'
import logo8 from '../Image/img-6.svg'
import logo9 from '../Image/img-7.svg'
import logo10 from '../Image/img-8.svg'
import logo11 from '../Image/img-9.svg'
import logo12 from '../Image/img-10.svg'
import logo13 from '../Image/img-11.svg'
import logo14 from '../Image/img-12.svg'
import logo15 from '../Image/img-13.svg'
import logo16 from '../Image/img-14.svg'
import logo17 from '../Image/Lennar-Homes-of-California.svg'
import logo18 from '../Image/logo-suffolk.svg'
import logo19 from '../Image/shea-logo.svg'
import logo20 from '../Image/Wilson-Meany.svg'


import cardsvg1 from '../Image/Group 1000002450.svg'
import cardsvg2 from '../Image/Group 1000002451.svg'
import cardsvg3 from '../Image/Group 1000002466.svg'


import playbtn1 from '../Image/Group 1000002484.png'
import videoimg from '../Image/Rectangle 40778.jpg'


import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

import featureimg1 from '../Image/Insider Access Photo  1.png'
import featureimg4 from '../Image/medium-shot-engineer-looking-phone 1.png'
import featureimg3 from '../Image/Detailed Project Insights 1.png'
import featureimg5 from '../Image/Amplify Project Info.png'
import featureimg2 from '../Image/Exclusive Networking Events Photo.png'

import Mobfeature from './Mobfeature.js'

import { Fade } from "react-awesome-reveal";

import aboutsvgimg from '../Image/Frame 48095580 (1).svg'
import {Link} from 'react-router-dom'



function Home() {






  return (
    <div>
      <div className='container-fluid home-banner-div1'>
        <div className='container-fluid home-banner-div2 text-center'>
          <div className='home-banner-color-div'>
            <div className='container'>
              <div className='home-banner-text-div1 text-center'>
                <Fade delay={200} triggerOnce={true}>
                  <h1>Amplify Your Construction Leads</h1>
                </Fade>
                <Fade delay={400} triggerOnce={true}>
                  <h2>Unlock Exclusive Access to Premium Leads</h2>
                </Fade>
                <Fade delay={600} triggerOnce={true}>
                  <div className='d-flex justify-content-center '>
                    <a href="https://portal.amplifyls.com/leads/registration" target='_blank'>
                      <div className='home-schedule-demo-btn11'>Get Started</div>
                    </a>
                    <Link to="/contact">
                      <div className='home-schedule-demo-btn22'>Schedule a Demo</div>
                    </Link>
                  </div>
                </Fade>
              </div>
            </div>
          </div>

        </div>
      </div>














      {/* #################### core  feature ### 1 ############### */}


      <div className='container-fluid home-core-features-div1' id='features'>
        <div className='container'>
          <h1 className='core-texts-12'>Core <span>Features</span></h1>
          <div className='row home-core-features-div2'>
            <div className='col-lg-6 d-flex align-items-center'>
              <div className='home-core-feature-text-div1'>
                <h1>Insider Access to
                  Premium Leads</h1>
                <div className='d-md-none d-block'>
                  <img src={featureimg1} className='img-fluid feature-img112233' alt="" />
                </div>
                <p>With Amplify Lead Solutions, we offer you special access to a curated portfolio of private construction leads.</p>
                <p>Our service opens doors to select opportunities, ensuring you get the first look at projects that are usually kept out of the public eye.</p>
                <p>Gain insider access to prestigious projects for unmatched growth potential!</p>

              </div>
            </div>
            <div className='col-lg-6 d-md-flex d-none align-items-center '>
              <div>
                <img src={featureimg1} className='img-fluid feature-img112233' alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>




      {/* #################### Core Features ### 2 ############### */}


      <div className='container-fluid home-core-features-div1'>
        <div className='container'>
          <div className='row home-core-features-div2'>
            <div className='col-lg-6 d-md-flex d-none align-items-center '>
              <div >
                <img src={featureimg2} className='img-fluid feature-img112233' alt="" />
              </div>
            </div>
            <div className='col-lg-6 d-flex align-items-center'>
              <div className='home-core-feature-text-div22'>
                <h1>Exclusive <br />
                  Networking Events</h1>
                <div className='d-md-none d-block'>
                  <img src={featureimg2} className='img-fluid feature-img112233' alt="" />
                </div>
                <p>Amplify Lead Solutions' marketing events go beyond typical networking. They are strategic platforms offering a unique opportunity to meet face-to-face with individuals listed on our leads.</p>
                <p>Esteemed real estate developers and general contractors are always there, providing you with the opportunity to engage directly with industry leaders and potential business partners.</p>
                <p>By attending, you're doing more than just participating in an event; you're stepping into a realm filled with potential for collaboration and insight, positioning yourself among the top professionals in the construction sector and directly connecting with key players related to your upcoming projects.</p>
 
              </div>
            </div>
          </div>
        </div>
      </div>




      {/* #################### Core Features ### 3 ############### */}





      {/* #################### Core Features ### 4 ############### */}


      <div className='container-fluid home-core-features-div1'>
        <div className='container'>
          <div className='row home-core-features-div2'>
            
            <div className='col-lg-6 d-flex align-items-center'>
              <div className='home-core-feature-text-div1'>
                <h1>Detailed <br />
                  Project Insights</h1>
                <div className='d-md-none d-block'>
                  <img src={featureimg5} className='img-fluid feature-img112233' alt="" />
                </div>
                <p>Equip yourself with all the essential information included with our exclusive leads. Our comprehensive project profile provides everything you need from scope and budget to timelines and key contacts.</p>
                <p>Having access to all of the pertinent project details spares you the legwork of conducting research on your own.</p>
                <p>We empower you with the knowledge you need for informed decision-making!</p>

              </div>
            </div>

            <div className='col-lg-6 d-md-flex d-none align-items-center '>
              <div>
                <img src={featureimg5} className='img-fluid feature-img112233' alt="" />
              </div>
            </div>
            
          </div>
        </div>
      </div>




      {/* #################### Core Features ### 5 ############### */}


      <div className='container-fluid last-home-core-features-div1'>
        <div className='container'>
          <div className='row home-core-features-div2'>

            <div className='col-lg-6 d-md-flex d-none align-items-center '>
              <div>
                <img src={featureimg3} className='img-fluid feature-img112233' alt="" />
              </div>

            </div>
            <div className='col-lg-6 d-flex align-items-center'>
              <div className='home-core-feature-text-div22'>
                <h1>Direct <br />
                  Contact Feature</h1>
                <div className='d-md-none d-block'>
                  <img src={featureimg3} className='img-fluid feature-img112233' alt="" />
                </div>
                <p>A standout addition to Amplify Lead Solutions is the direct contact feature, a tool specifically designed to enhance your lead management process by facilitating immediate communication between you and our team.</p>
                <p>This feature is not just about obtaining additional information but also about fostering a collaborative relationship with Amplify to ensure that the leads you pursue are as detailed and accurate as possible.</p>
                <p>With this functionality, you can quickly clarify project specifics, ask follow-up questions, or engage in discussions to better understand the opportunity at hand.</p>

              </div>
            </div>
            
          </div>
        </div>
      </div>










      {/* #################### Section No ### 4 ############### */}


      <div className='container-fluid home-work-div1' id='howworks'>
        <div className='container'>
          <div className='home-work-text-div1'>
            <Fade delay={200} triggerOnce={true}>
              <h1><span>HOW IT</span> Works</h1>
            </Fade>
            <Fade delay={400} triggerOnce={true}>
              <p>Amplify Lead Solutions helps you increase the amount <br className='d-md-block d-none' /> of sales leads at your disposal. More leads means more <br className='d-md-block d-none' /> bids and more bids means, well, you get the picture.</p>
            </Fade>

            <div className='row home-work-row-div1'>
              <div className='col-lg-4'>
                <Fade direction='up' triggerOnce={true}>
                  <div className='home-work-card-div1'>
                    <div>
                      <img src={cardsvg2} alt='' />
                    </div>
                    <h3>Maximize Your Profit</h3>
                    <p>We provide the industry's most extensive database of private construction leads. Each project is carefully qualified, streamlining your bidding process.</p>
                  </div>
                </Fade>
              </div>

              <div className='col-lg-4'>
                <Fade direction='up' delay={200} triggerOnce={true}>
                  <div className='home-work-card-div1'>
                    <div>
                      <img src={cardsvg1} alt='' />
                    </div>
                    <h3>Minimize Your Time</h3>
                    <p>Boost productivity with immediate access to our projects. Let us find your leads efficiently so you can focus on bidding. Save time, seize opportunities – that's a win-win.</p>
                  </div>
                </Fade>
              </div>


              <div className='col-lg-4'>
                <Fade direction='up' delay={400} triggerOnce={true}>
                  <div className='home-work-card-div1'>
                    <div>
                      <img src={cardsvg3} alt='' />
                    </div>
                    <h3>Simplify Your Experience</h3>
                    <p>We provide lead management tool that’s no fuss. Our streamlined interface houses easy lead discovery and access to coveted industry contacts and projects. No learning curve, just results.</p>
                  </div>
                </Fade>
              </div>

            </div>
          </div>
        </div>
      </div>




    </div >
  )
}

export default Home
