import Home from './Component/Home'
import NavBar from './Component/NavBar'
import Footer from './Component/Footer'
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Development from './Component/Development';
import About from './Component/About'
import Contact from './Component/Contact';
import Plans from './Component/Plans';
import ScrollToTop from './Component/ScrollToTop.js'
import Event from './Component/Event.js';
import Privacy from './Component/Privacy.js';
import Terms from './Component/Terms.js';
import Cookies from './Component/Cookies.js';
import DataProcessing from './Component/DataProcessing.js';


function App() {
  return (
    <div>

      <BrowserRouter>
        <ScrollToTop></ScrollToTop>

        <NavBar></NavBar>
        <Routes>

          <Route path="/" element={<Home></Home>} />
          <Route path="/new-development" element={<Development></Development>} />
          <Route path="/about" element={<About></About>} />
          <Route path="/plans" element={<Plans></Plans>} />
          <Route path="/contact" element={<Contact></Contact>} />
          <Route path="/events" element={<Event></Event>} />
          <Route path="/cookie-policy" element={<Cookies></Cookies>} />
          <Route path="/data-processing" element={<DataProcessing></DataProcessing>} />
          <Route path="/privacy-policy" element={<Privacy></Privacy>} />
          <Route path="/terms-of-services" element={<Terms></Terms>} />







        </Routes>
        <Footer></Footer>
      </BrowserRouter>

    </div>
  
  );
}

export default App;
