import React, { useEffect, useState } from 'react'
import './NavBar.css'
import navlogo from '../Image/Group 1000002519.svg'
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom'
import { HashLink } from "react-router-hash-link";   


function NavBar() {

  const location = useLocation()
  const [toggle, settoggle] = useState(true);
  const [nav, setnav] = useState(false);


  useEffect(() => {
    if (location.pathname == '/') {
      setnav(true)
    } else {
      setnav(false)
    }
  }, [location]);

  return (
    <div>

      <div>
        <nav class={nav ? "navbar navbar-expand-lg navbar-light transparent-navbar-div1 d-md-flex d-none" : "navbar navbar-expand-lg navbar-light navbar-div1 d-md-flex d-none"} >

          <Link class="navbar-brand" to="/">
            <img src={navlogo} className="navbar-logo1" alt="" />
          </Link>
          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div
            class="collapse navbar-collapse mx-auto navbar-ul-div1"
            id="navbarNav"
          >
            <ul class="navbar-nav navbar-ul1 ">

              <li class="nav-item">
                <Link class="nav-link" to="/">
                  Home
                </Link>
              </li>


              <li class="nav-item">
                <Link class="nav-link" to="/about">
                  About
                </Link>
              </li>


              <li class="nav-item">
                <HashLink
                  class="nav-link"
                  to="/#features"
                  spy={true}
                  smooth={true}
                  duration={600}
                >
                  Features
                </HashLink>
              </li>


              <li class="nav-item">
                <HashLink
                class="nav-link"
                to="/#howworks"
                spy={true}
                smooth={true}
                offset={-100}
                duration={600}
              >
                  How It Works
                </HashLink>
              </li>


              <li class="nav-item">
                <Link class="nav-link" to="/new-development">
                  New Developments
                </Link>
              </li>

              <li class="nav-item">
                <Link class="nav-link" to="/plans">
                  Pricing
                </Link>
              </li>

              <li class="nav-item">
                <Link class="nav-link" to="/contact">
                  Contact
                </Link>
              </li>

              <li class="nav-item">
                <a class="nav-link" href="https://portal.amplifyls.com/leads/registration" target='_blank'>
                  Sign In
                </a>
              </li>

            </ul>
          </div>
          <div>
            <a  href="https://portal.amplifyls.com/leads/registration" target='_blank' >
              <div className="navbar-contact-btn1">
                SIGN UP
              </div>
            </a>
          </div>

        </nav>
      </div>







































      {/* ####### // mobile Nav Bar ###############*/}

      <nav class={nav ? "navbar transparent-mobile-navbar-div1 d-flex d-lg-none navbar-expand-lg navbar-light" : "navbar mobile-navbar-div1 d-flex d-lg-none navbar-expand-lg navbar-light"}>
        <Link class="navbar-brand "
          onClick={() => { settoggle(true); }} to="/">
          <img src={navlogo} alt="" className="" width="100" />
        </Link>

        <button
          class="navbar-toggler mobile-nav-toogler"
          type="button"
          // data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={() => settoggle(!toggle)}
        >
         <div className='mobile-btn-lin1'></div>
          <div className='mobile-btn-lin2'></div>
         <div className='mobile-btn-lin2'></div>
        </button>

       
        

        <div
          class={`${toggle
            ? "navbar-collapse mobile-navbar-div2"
            : "navbar-collapse mobile-navbar-div2 show"
            } `}
          id="navbarSupportedContent"
        >
          <ul class="navbar-nav mx-auto">
            <li className="nav-item mobile-nav-item home1">
              <Link
                class="nav-link"
                to="/"
                onClick={() => {
                  settoggle(!toggle);
                }}
              >
                Home
              </Link>
            </li>

            <li className="nav-item mobile-nav-item home1">
              <div className='mobilenav-lines-svg'></div>
            </li>

            <li className="nav-item mobile-nav-item home1">
              <Link
                class="nav-link"
                to="/about"
                onClick={() => {
                  settoggle(!toggle);
                }}
              >
                About Us
              </Link>
            </li>

            <li className="nav-item mobile-nav-item home1">
              <div className='mobilenav-lines-svg'></div>
            </li>

            <li className="nav-item mobile-nav-item home1">
              
                <HashLink
                  class="nav-link"
                  to="/#features"
                  onClick={() => {
                    settoggle(!toggle);
                  }}
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={600}
                >
                Features
              </HashLink>
            </li>

            <li className="nav-item mobile-nav-item home1">
              <div className='mobilenav-lines-svg'></div>
            </li>

            <li className="nav-item mobile-nav-item home1">
              <Link
                class="nav-link"
                to="/new-development"
                onClick={() => {
                  settoggle(!toggle);
                }}
              >
                New Development
              </Link>
            </li>

            <li className="nav-item mobile-nav-item home1">
              <div className='mobilenav-lines-svg'></div>
            </li>

            <li className="nav-item mobile-nav-item home1">
              <HashLink
                class="nav-link"
                to="/#howworks"
                onClick={() => {
                  settoggle(!toggle);
                }}
                spy={true}
                smooth={true}
                offset={-100}
                duration={600}
              >
                How Works
              </HashLink>
            </li>

            <li className="nav-item mobile-nav-item home1">
              <div className='mobilenav-lines-svg'></div>
            </li>

            <li className="nav-item mobile-nav-item home1">
              <Link
                class="nav-link"
                to="/plans"
                onClick={() => {
                  settoggle(!toggle);
                }}
              >
                Pricing
              </Link>
            </li>

            <li className="nav-item mobile-nav-item home1">
              <div className='mobilenav-lines-svg'></div>
            </li>

            <li className="nav-item mobile-nav-item home1">
              <Link
                class="nav-link"
                to="/events"
                onClick={() => {
                  settoggle(!toggle);
                }}
              >
                Events
              </Link>
            </li>

            <li className="nav-item mobile-nav-item home1">
              <div className='mobilenav-lines-svg'></div>
            </li>

            <li className="nav-item mobile-nav-item home1">
              <Link
                class="nav-link"
                to="/contact"
                onClick={() => {
                  settoggle(!toggle);
                }}
              >
                Contact Us
              </Link>
            </li>

            <li className="nav-item mobile-nav-item home1">
              <div className='mobilenav-lines-svg'></div>
            </li>


            <li className="nav-item mobile-nav-item home1 d-none">
              <a
                class=""
                href="https://portal.amplifyls.com/leads/registration" target='_blank'
                onClick={() => {
                  settoggle(!toggle);
                }}
              >
                <div className='mobile-qoute-btn122'>
                  Watch Our Free Demo
                </div>
              </a>
            </li>


            <li className="nav-item mobile-nav-item home1">
              <a
                class=""
                href="https://portal.amplifyls.com/leads/registration" target='_blank'
                onClick={() => {
                  settoggle(!toggle);
                }}
              >
                <div className='mobile-qoute-btn111'>
                  Sign Up
                </div>
              </a>
            </li>




          </ul>
        </div>
      </nav>

    </div>
  )
}

export default NavBar
