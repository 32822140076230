import React, { useState, useEffect } from 'react'
import './Plans.css'
import eventimg1 from '../Image/Amplify Hangar 24 flier.59f13728ed606fd7a17f (3).png'
import eventimg2 from '../Image/Amplify Water Grill flier.38f369f83fd5437e9680 (2).png'


const Plans = () => {

    const [ads, setads] = useState(true);

    const [events, setEvents] = useState([]);

    useEffect(() => {
        fetch('https://events.amplifyls.com/wp-json/wp/v2/events?acf_format=standard&_fields=id,acf')
            .then((response) => response.json())
            .then((data) => setEvents(data))
            .catch((error) => console.error('Error fetching events:', error));
    }, []);
    return (
        <div>

            <div className='container-fluid plans-div1'>
                <div className='container'>
                    <div className='plans-text-div1'>
                        <h1>Plans and Pricing</h1>
                        <p>Ready to take your business to the next level? Explore our flexible pricing options tailored to suit your needs. <br className='d-md-block d-none' /> Discover our range of features and select the perfect package to unlock your full potential. Let's get started!</p>
                    </div>
                    <div>
                        <div className='d-flex justify-content-center'>
                            <div className='with-ads-btndiv1' >
                                <button className={ads ? 'with-ads-btn1' : 'with-ads-btn2'} onClick={() => setads(true)}>Bill Annually <div className='bill-annual-text'>-10%</div></button>
                                <button className={!ads ? 'with-ads-btn1' : 'with-ads-btn2'} onClick={() => setads(false)}>Monthly</button>
                            </div>
                        </div>
                        <div className='row plan-card-rows-div11'>

                            <div className='col-lg-6'>
                                <div className='price-plan-card-div1'>
                                    <div className='price-plan-card-text-div1'>
                                        <h3>Connect Package</h3>
                                        <h4>{ads ? '$337.00/m' : '$375.00/m'}</h4>
                                    </div>
                                    <div className='price-plan-card-text-div2'>
                                        <p><svg xmlns="http://www.w3.org/2000/svg" width="12" height="9" viewBox="0 0 12 9" fill="none">
                                            <path d="M11.7251 0.260447C11.6457 0.17792 11.5513 0.112417 11.4472 0.0677155C11.3432 0.0230142 11.2316 0 11.1189 0C11.0062 0 10.8946 0.0230142 10.7905 0.0677155C10.6865 0.112417 10.592 0.17792 10.5126 0.260447L4.15139 6.82889L1.47882 4.06416C1.3964 3.98206 1.29911 3.91751 1.1925 3.87419C1.08589 3.83086 0.972054 3.80962 0.857483 3.81166C0.742911 3.81371 0.629852 3.839 0.524761 3.8861C0.419669 3.9332 0.324603 4.00118 0.244991 4.08617C0.165378 4.17116 0.102779 4.27148 0.0607656 4.38141C0.0187524 4.49135 -0.00185163 4.60874 0.000130571 4.72688C0.00211277 4.84503 0.0266423 4.96161 0.0723181 5.06998C0.117994 5.17835 0.183922 5.27638 0.266338 5.35848L3.54515 8.73955C3.62453 8.82208 3.71897 8.88758 3.82302 8.93229C3.92707 8.97699 4.03867 9 4.15139 9C4.26411 9 4.37572 8.97699 4.47977 8.93229C4.58382 8.88758 4.67826 8.82208 4.75763 8.73955L11.7251 1.55477C11.8118 1.47231 11.881 1.37225 11.9283 1.26086C11.9756 1.14948 12 1.0292 12 0.907606C12 0.786009 11.9756 0.66573 11.9283 0.554349C11.881 0.442967 11.8118 0.342898 11.7251 0.260447Z" fill="#111827" />
                                        </svg>  List of Construction Leads</p>
                                        <p><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                            <path d="M1 11L11 1M1 1L11 11" stroke="#B8B8B8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>CRM</p>
                                        <p><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                            <path d="M1 11L11 1M1 1L11 11" stroke="#B8B8B8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg> Bid Board</p>
                                    </div>
                                    <div>
                                        <div >
                                            <a href="https://portal.amplifyls.com/leads/registration" target='_blank'>
                                                <div className='plans-btn1'>Get Started</div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>





                            <div className='col-lg-6'>
                                <div className='price-plan-card-div1'>
                                    <div className='price-plan-card-text-div1'>
                                        <h3>Connect Pro <br className='d-md-none d-block' /> Package</h3>
                                        <div className='price-plan-comingsoon-text1'>
                                            <p>Coming Soon</p>
                                        </div>
                                    </div>
                                    <div className='price-plan-card-text-div22'>
                                        <p><svg xmlns="http://www.w3.org/2000/svg" width="12" height="9" viewBox="0 0 12 9" fill="none">
                                            <path d="M11.7251 0.260447C11.6457 0.17792 11.5513 0.112417 11.4472 0.0677155C11.3432 0.0230142 11.2316 0 11.1189 0C11.0062 0 10.8946 0.0230142 10.7905 0.0677155C10.6865 0.112417 10.592 0.17792 10.5126 0.260447L4.15139 6.82889L1.47882 4.06416C1.3964 3.98206 1.29911 3.91751 1.1925 3.87419C1.08589 3.83086 0.972054 3.80962 0.857483 3.81166C0.742911 3.81371 0.629852 3.839 0.524761 3.8861C0.419669 3.9332 0.324603 4.00118 0.244991 4.08617C0.165378 4.17116 0.102779 4.27148 0.0607656 4.38141C0.0187524 4.49135 -0.00185163 4.60874 0.000130571 4.72688C0.00211277 4.84503 0.0266423 4.96161 0.0723181 5.06998C0.117994 5.17835 0.183922 5.27638 0.266338 5.35848L3.54515 8.73955C3.62453 8.82208 3.71897 8.88758 3.82302 8.93229C3.92707 8.97699 4.03867 9 4.15139 9C4.26411 9 4.37572 8.97699 4.47977 8.93229C4.58382 8.88758 4.67826 8.82208 4.75763 8.73955L11.7251 1.55477C11.8118 1.47231 11.881 1.37225 11.9283 1.26086C11.9756 1.14948 12 1.0292 12 0.907606C12 0.786009 11.9756 0.66573 11.9283 0.554349C11.881 0.442967 11.8118 0.342898 11.7251 0.260447Z" fill="#111827" />
                                        </svg>  List of Construction Leads</p>
                                        <p><svg xmlns="http://www.w3.org/2000/svg" width="12" height="9" viewBox="0 0 12 9" fill="none">
                                            <path d="M11.7251 0.260447C11.6457 0.17792 11.5513 0.112417 11.4472 0.0677155C11.3432 0.0230142 11.2316 0 11.1189 0C11.0062 0 10.8946 0.0230142 10.7905 0.0677155C10.6865 0.112417 10.592 0.17792 10.5126 0.260447L4.15139 6.82889L1.47882 4.06416C1.3964 3.98206 1.29911 3.91751 1.1925 3.87419C1.08589 3.83086 0.972054 3.80962 0.857483 3.81166C0.742911 3.81371 0.629852 3.839 0.524761 3.8861C0.419669 3.9332 0.324603 4.00118 0.244991 4.08617C0.165378 4.17116 0.102779 4.27148 0.0607656 4.38141C0.0187524 4.49135 -0.00185163 4.60874 0.000130571 4.72688C0.00211277 4.84503 0.0266423 4.96161 0.0723181 5.06998C0.117994 5.17835 0.183922 5.27638 0.266338 5.35848L3.54515 8.73955C3.62453 8.82208 3.71897 8.88758 3.82302 8.93229C3.92707 8.97699 4.03867 9 4.15139 9C4.26411 9 4.37572 8.97699 4.47977 8.93229C4.58382 8.88758 4.67826 8.82208 4.75763 8.73955L11.7251 1.55477C11.8118 1.47231 11.881 1.37225 11.9283 1.26086C11.9756 1.14948 12 1.0292 12 0.907606C12 0.786009 11.9756 0.66573 11.9283 0.554349C11.881 0.442967 11.8118 0.342898 11.7251 0.260447Z" fill="#111827" />
                                        </svg>CRM</p>
                                        <p><svg xmlns="http://www.w3.org/2000/svg" width="12" height="9" viewBox="0 0 12 9" fill="none">
                                            <path d="M11.7251 0.260447C11.6457 0.17792 11.5513 0.112417 11.4472 0.0677155C11.3432 0.0230142 11.2316 0 11.1189 0C11.0062 0 10.8946 0.0230142 10.7905 0.0677155C10.6865 0.112417 10.592 0.17792 10.5126 0.260447L4.15139 6.82889L1.47882 4.06416C1.3964 3.98206 1.29911 3.91751 1.1925 3.87419C1.08589 3.83086 0.972054 3.80962 0.857483 3.81166C0.742911 3.81371 0.629852 3.839 0.524761 3.8861C0.419669 3.9332 0.324603 4.00118 0.244991 4.08617C0.165378 4.17116 0.102779 4.27148 0.0607656 4.38141C0.0187524 4.49135 -0.00185163 4.60874 0.000130571 4.72688C0.00211277 4.84503 0.0266423 4.96161 0.0723181 5.06998C0.117994 5.17835 0.183922 5.27638 0.266338 5.35848L3.54515 8.73955C3.62453 8.82208 3.71897 8.88758 3.82302 8.93229C3.92707 8.97699 4.03867 9 4.15139 9C4.26411 9 4.37572 8.97699 4.47977 8.93229C4.58382 8.88758 4.67826 8.82208 4.75763 8.73955L11.7251 1.55477C11.8118 1.47231 11.881 1.37225 11.9283 1.26086C11.9756 1.14948 12 1.0292 12 0.907606C12 0.786009 11.9756 0.66573 11.9283 0.554349C11.881 0.442967 11.8118 0.342898 11.7251 0.260447Z" fill="#111827" />
                                        </svg> Bid Board</p>
                                    </div>
                                    <div>
                                        <div >
                                            <a>
                                                <div className='plans-btn1'>Coming Soon</div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>








                    <div className=' event-addons-div11'>
                        <h2 className='event-addons-text1'>Event Add-Ons</h2>
                        <div className='row'>
                            {events.map((event) => {
                                return (
                                    <div className='col-lg-6'>
                                        <div className='event-addons-card-div1'>
                                            <div className='event-addons-cardtext-div2'>
                                                <div className='my-auto'>
                                                    <h3>{event && event.acf.event_title}</h3>
                                                    <p>{event && event.acf.event_description}</p>
                                                </div>
                                                <div className='position-relative'>
                                                    <div className='price-tag11'>
                                                        <h4>{`$ ${event && event.acf.event_price}`}</h4>
                                                    </div>
                                                    <img src={event && event.acf.event_image} className='' alt="" />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                )

                            })}



                        </div>
                    </div>


                </div>
            </div>
        </div>
    )
}

export default Plans

























// < p className = 'plans-add-text1' > Event Add - ons</p >

//                                     <div className='plans-check-box-div1'>
//                                         <div className='plans-check-box-div2'>
//                                             <div>
//                                                 <input type="checkbox" name="" id="" />
//                                             </div>
//                                             <div>
//                                                 <h4>Hops for Hope Beer and <br className='d-md-none d-block' /> Wine Festival- Irvine</h4>
//                                                 <p>Charity Event- All monies donated to the <br className='d-md-block d-none' /> City of Hope</p>
//                                             </div>
//                                         </div>

//                                         <div className='plans-check-box-div3'>
//                                             <h3>$200.00</h3>
//                                         </div>
//                                     </div>



//                                     <div className='plans-check-box-div1'>
//                                         <div className='plans-check-box-div2'>
//                                             <div>
//                                                 <input type="checkbox" name="" id="" />
//                                             </div>
//                                             <div>
//                                                 <h4>Water Grill Event- Costa Mesa</h4>
//                                                 <p> Networking Event- includes cocktails and <br className='d-md-block d-none' /> Appetizers</p>
//                                             </div>
//                                         </div>
//                                         <div className='plans-check-box-div3'>
//                                             <h3>$200.00</h3>
//                                         </div>
//                                     </div>








                            //  <div div className = 'col-lg-6' >
                            //      <div className='event-addons-card-div1'>
                            //          <div className='event-addons-cardtext-div2'>
                            //              <div>
                            //                  <img src={eventimg1} className='' alt="" />
                            //              </div>
                            //              <div className='my-auto'>
                            //                  <h3>Hops for Hope Beer and Wine Festival- Irvine</h3>
                            //                  <p>Charity Event- All monies donated to the City of Hope</p>
                            //                 <h4>$ 200.00</h4>
                            //              </div>
                            //          </div>

                            //      </div>
                            //                          </div >




                            //  <div className='col-lg-6'>
                            //      <div className='event-addons-card-div1'>
                            //          <div className='event-addons-cardtext-div2'>
                                         
                            //              <div >
                            //                  <img src={eventimg2} className='' alt="" />
                            //              </div>
                            //              <div>
                            //                  <h3>Water Grill Event- Costa Mesa</h3>
                            //                  <p>Networking Event- includes cocktails and Appetizers</p>
                            //              </div>
                            //          </div>

                            //          <div className='event-addons-cardtext-div3'>
                            //              <div>
                            //                  <h4>$ 200.00</h4>
                            //              </div>
                            //          </div>

                            //      </div>
                            //  </div>