import React from "react";
import "./Privacy.css";
import { Link } from "react-router-dom";

function Privacy() {
  return (
    <div>
      <div className="container-fluid privacy-div1">
        <div className="container">
          <div>
           <h1>Privacy Policy</h1>
           
            <p class="c2">
              <span class="c3">
                This Privacy Policy describes how Amplify Lead Solutions LLC and
                its subsidiaries and affiliates (&ldquo;
              </span>
              <span class="c7">Amplify</span>
              <span class="c3">&rdquo;, &ldquo;</span>
              <span class="c7">we</span>
              <span class="c3">&rdquo;, &ldquo;</span>
              <span class="c7">our</span>
              <span class="c3">&rdquo; or &ldquo;</span>
              <span class="c7">us</span>
              <span class="c1">
                &rdquo;) collects, stores, uses and discloses the following
                categories of personal data:
              </span>
            </p>
            <p class="c2">
              <span class="c3">(i)&nbsp;</span>
              <span class="c7">Customer Data</span>
              <span class="c3">
                : personal data that we collect, process and manage on behalf of
                our business customers (&ldquo;
              </span>
              <span class="c7">Customers</span>
              <span class="c3">
                &rdquo;), submitted to the Amplify cloud-based services,
                including our platforms, products, applications, application
                programming interface (&ldquo;
              </span>
              <span class="c7">API</span>
              <span class="c3">
                &rdquo;), tools, and any ancillary or supplementary Amplify
                products and services (including Upgrades (as defined in the
                Terms of Service)), offered online (collectively, &ldquo;
              </span>
              <span class="c7">Platform</span>
              <span class="c1">&rdquo;).</span>
            </p>
            <p class="c2">
              <span class="c3">
                We process such Customer Data on behalf and under the
                instruction of the respective Customer in our capacity as a
                &ldquo;
              </span>
              <span class="c3 c16">data processor</span>
              <span class="c1">
                &rdquo;, in accordance with our&nbsp;Data Processing
                Addendum&nbsp;with them. For more information, please refer to
                Section 9 below.
              </span>
            </p>
            <p class="c2">
              <span class="c3">
                Accordingly, this Privacy Policy &ndash; which describes
                Amplify&rsquo;s independent privacy and data processing
                practices as a &ldquo;
              </span>
              <span class="c3 c16">data controller</span>
              <span class="c3">
                &rdquo; &ndash; with respect to the Platform, Sites (as defined
                below) and any other services provided to Customer by Amplify
                (&ldquo;
              </span>
              <span class="c7">Services</span>
              <span class="c3">
                &rdquo;), and does not apply to the processing of Customer Data.
                If you have any questions or requests regarding Customer Data,
                please contact your account administrator(s) (&ldquo;
              </span>
              <span class="c7">Account Admin</span>
              <span class="c1">&rdquo;) directly.</span>
            </p>
            <p class="c2">
              <span class="c3">(ii)&nbsp;</span>
              <span class="c7">User Data</span>
              <span class="c3">
                : personal data concerning our Customers&rsquo; internal focal
                persons who directly engage with Amplify concerning their
                Amplify account (e.g. billing contacts and authorized
                signatories), Customers&rsquo; Account Admins, and authorized
                users of the Platform (collectively, &ldquo;
              </span>
              <span class="c7">Users</span>
              <span class="c1">&rdquo;);</span>
            </p>
            <p class="c2">
              <span class="c3">(iii)&nbsp;</span>
              <span class="c7">Prospect Data</span>
              <span class="c3">
                : data relating to visitors of our websites (including but not
                limited to&nbsp;
              </span>
              <span class="c8">
                <a
                  class="c11"
                  href="https://www.google.com/url?q=http://www.amplifyls.com&amp;sa=D&amp;source=editors&amp;ust=1725288615326233&amp;usg=AOvVaw0aqSTjU3bIFexoRnvhcdfQ"
                >
                  www.amplifyls.com
                </a>
              </span>
              <span class="c3">
                ), participants at events, and any other prospective customer,
                user or partner (collectively, &ldquo;
              </span>
              <span class="c7">Prospects</span>
              <span class="c3">
                &rdquo;) who visit or otherwise interact with our programs,
                marketing and social activities and our websites, digital ads
                and content, emails, integrations or communications under our
                control (&ldquo;
              </span>
              <span class="c7">Sites</span>
              <span class="c1">&rdquo;).</span>
            </p>
            <p class="c2">
              <span class="c3">(iv)</span>
              <span class="c7">&nbsp;Technology Partner Data</span>
              <span class="c3">
                : data relating to individuals participating and/or engaging as
                a participant, candidate, applicant or any other prospective or
                existing technology partners (including as developer or
                technology ambassadors) (collectively, &ldquo;
              </span>
              <span class="c7">Technology Partner</span>
              <span class="c1">
                &rdquo;) who interact with our Platform, Sites, events and/or
                other platforms utilized by Amplify.
              </span>
            </p>
            <p class="c2">
              <span class="c1">
                If you are a Customer, User, Prospect or Technology Partner,
                please read this Privacy Policy carefully and make sure that you
                fully understand it.
              </span>
            </p>
            <p class="c2">
              <span class="c1">
                You are not legally required to provide us with any of your
                personal data, and may do so (or avoid doing so) at your own
                free will. If you do not wish to provide us with your personal
                data, or to have it processed by us or any of our services
                providers, please simply do not visit or interact with our
                Sites, nor use our Services.
              </span>
            </p>
            <p class="c2">
              <span class="c1">
                You may also choose not to provide us with
                &ldquo;optional&rdquo; personal data (i.e. &ldquo;not
                required&rdquo; fields on forms), but please keep in mind that
                without it we may not be able to provide you with the full range
                of our Services or with the best user experience when using our
                Services.
              </span>
            </p>
            <p class="c2">
              <span class="c3">
                Any capitalized but undefined term in this Privacy Policy shall
                have the meaning given to it in our&nbsp;Terms of Service
                (&ldquo;
              </span>
              <span class="c7">Terms</span>
              <span class="c1">&rdquo;).</span>
            </p>

            <h2> 1. Data Collection Processing</h2>

            <p class="c2">
              <span class="c1">
                When we use the term &ldquo;personal data&rdquo; in this Privacy
                Policy, we mean information that identifies, relates to,
                describes, is reasonably capable of being associated with, or
                could reasonably be linked, directly or indirectly, to an
                individual. It does not include aggregated or anonymized
                information that is maintained in a form that is not reasonably
                capable of being associated with or linked to an individual.
              </span>
            </p>
            <p class="c2">
              <span class="c1">
                We collect or generate the following categories of personal data
                in relation to the Services:
              </span>
            </p>
            <ul class="c18 lst-kix_list_2-0 start">
              <li class="c15 li-bullet-0">
                <span class="c7">
                  Usage and device information concerning our Users, Prospects
                  and Technology Partners
                </span>
                <span class="c1">:</span>
              </li>
            </ul>
            <p class="c4">
              <span class="c1">
                Connectivity, technical and usage data, such as IP addresses and
                approximate general locations derived from such IP addresses,
                device and application data (like type, operating system, mobile
                device or app id, browser version, location and language
                settings used), activity logs, the relevant cookies and pixels
                installed or utilized on your device, and the recorded activity
                (sessions, clicks, use of features, logged activities and other
                interactions) of Prospects, Users and Technology Partners in
                connection with our Services.
                <br />
                We collect and generate this information automatically,
                including through the use of analytics tools (including cookies
                and pixels) &ndash; which collect data such as: how often
                Prospects or Technology Partners visit or use the Sites, which
                pages they visit and when, which website, ad or email message
                brought them there, and how Users interact with and use the
                Platform and its various features.
              </span>
            </p>
            <ul class="c18 lst-kix_list_2-0">
              <li class="c15 li-bullet-0">
                <span class="c7">
                  Contact and profile information concerning our Customers,
                  Users, Prospects and Technology Partners
                </span>
                <span class="c1">:</span>
              </li>
            </ul>
            <p class="c4">
              <span class="c3">
                Name, email, phone number, position, workplace, profile picture,
                login credentials, contractual and billing details, and any
                other information submitted by Account Admins and Users or
                otherwise available to us when they sign up or log in to the
                Platform (either directly or through their social media or
                organizational Single-Sign-On account), when creating their
                individual profile (&ldquo;
              </span>
              <span class="c7">User Profile</span>
              <span class="c1">
                &rdquo;), or by updating their account.
                <br />
                We collect this information directly from you, or from other
                sources and third parties such as our Customer (your employer),
                Users and colleagues related to your organizational Amplify
                account, organizers of events or promotions that both you and us
                were involved in, and through the use of tools and channels
                commonly used for connecting between companies and individual
                professionals in order to explore potential business and
                employment opportunities.
              </span>
            </p>
            <ul class="c18 lst-kix_list_2-0">
              <li class="c15 li-bullet-0">
                <span class="c7">
                  Communications with our Customers, Users, Prospects and
                  Technology Partners
                </span>
                <span class="c1">:</span>
              </li>
            </ul>
            <p class="c2 c19">
              <span class="c1">
                Personal data contained in any forms and inquiries that you may
                submit to us, including support requests, interactions through
                social media channels and instant messaging apps, registrations
                to events that we host, organize or sponsor, and participation
                in our online and offline communities and activities); surveys,
                feedback and testimonials received; expressed, presumed or
                identified needs, preferences, attributes and insights relevant
                to our potential or existing engagement; and sensory information
                including phone call and video conference recordings (e.g., with
                our customer experience or product consultants), as well as
                written correspondences, screen recordings, screenshots,
                documentation and related information that may be automatically
                recorded, tracked, transcribed and analyzed, for purposes
                including analytics, quality control and improvements, training,
                and record-keeping purposes.
              </span>
            </p>
            <p class="c2">
              <span class="c3">
                For the purposes of the California Consumer Privacy Act (&ldquo;
              </span>
              <span class="c7">CCPA</span>
              <span class="c1">
                &rdquo;), specifically in the last twelve (12) months, we have
                collected the following categories of Personal Information:
                Identifiers; Professional or Employment-Related Information;
                Internet or other Electronic Network Activity Information;
                Customer Records Information; and Geolocation Information. We do
                not use or disclose sensitive personal information as defined in
                the CCPA.
              </span>
            </p>

            <h2>2. Data Uses Legal Bases</h2>

            <p class="c2">
              <span class="c3">
                We use personal data as necessary for the performance of our
                Services (&ldquo;
              </span>
              <span class="c7">Performance of Contract</span>
              <span class="c3">
                &rdquo;); to comply with our legal and contractual obligations
                (&ldquo;
              </span>
              <span class="c7">Legal Obligations</span>
              <span class="c3">
                &rdquo;); and to support our legitimate interests in maintaining
                and improving our Services, e.g. in understanding how our
                Services are used and how our campaigns are performing, and
                gaining insights which help us dedicate our resources and
                efforts more efficiently; in marketing, advertising and selling
                our Services to you and others; providing customer services and
                technical support; and protecting and securing our Users,
                Customers, Prospects and Technology Partners, ourselves and our
                Services (&ldquo;
              </span>
              <span class="c7">Legitimate Interests</span>
              <span class="c1">&rdquo;).</span>
            </p>
            <p class="c2">
              <span class="c3">
                If you reside or are using the Services in a territory governed
                by privacy laws under which &ldquo;consent&rdquo; is the only or
                most appropriate legal basis for processing personal data as
                described in this Privacy Policy (either in general, based on
                the types of personal data you expect or elect to process or
                have processed by us or via the Services, or due to the nature
                of such processing) (&ldquo;
              </span>
              <span class="c7">Consent</span>
              <span class="c3">
                &rdquo;), your acceptance of our&nbsp;Terms&nbsp;and of this
                Privacy Policy will be deemed as your consent to the processing
                of your personal data for all purposes detailed in this Privacy
                Policy, unless applicable law requires a different form of
                consent. If you wish to revoke such consent, please contact us
                at&nbsp;
              </span>
              <span class="c8">
                <a class="c11" href="mailto:info@amplifyls.com">
                  info@amplifyls.com
                </a>
              </span>
              <span class="c1">. </span>
            </p>
            <p class="c2">
              <span class="c1">
                Specifically, we use personal data for the following purposes
                (and in reliance on the legal bases for processing noted next to
                them, as appropriate):
              </span>
            </p>
            <p class="c2">
              <span class="c1">Customer and User personal data</span>
            </p>
            <ul class="c18 lst-kix_list_3-0 start">
              <li class="c6 li-bullet-0">
                <span class="c3">
                  To facilitate, operate, enhance, secure and provide our
                  Services;&nbsp;
                </span>
                <span class="c3 c12">
                  (Performance of Contract; Legitimate Interests)
                </span>
              </li>
              <li class="c5 li-bullet-0">
                <span class="c3">To invoice and process payments (</span>
                <span class="c3 c12">
                  Performance of Contract; Legitimate Interests);{" "}
                </span>
                <span class="c1">and</span>
              </li>
              <li class="c4 c17 li-bullet-0">
                <span class="c3">
                  To personalize our Services, including by recognizing an
                  individual and remembering their information when they return
                  to our Services, and to provide further localization and
                  personalization capabilities&nbsp;
                </span>
                <span class="c3 c12">
                  (Performance of Contract; Legitimate Interests)
                </span>
                <span class="c1">.</span>
              </li>
            </ul>
            <p class="c2 mt-3">
              <span class="c1">
                Customer, User, Prospect and Technology Partner personal data
              </span>
            </p>
            <ul class="c18 lst-kix_list_4-0 start">
              <li class="c6 li-bullet-0">
                <span class="c3">
                  To provide our Prospects, Users, Technology Partners and
                  Customers with assistance and support, to test and monitor the
                  Services, diagnose or fix technical issues, and to train our
                  Customers&rsquo; and Customer-facing staff&nbsp;
                </span>
                <span class="c3 c12">
                  (Performance of Contract; Legitimate Interests)
                </span>
                <span class="c1">;</span>
              </li>
              <li class="c5 li-bullet-0">
                <span class="c3">
                  To gain a better understanding of how Users, Prospects and
                  Technology Partners evaluate, use, and interact with our
                  Services, to utilize such information to continuously improve
                  our Services, the overall performance, user-experience and
                  value generated therefrom. We collect such information
                  automatically through their usage of the Services{" "}
                </span>
                <span class="c3 c12">(Legitimate Interests)</span>
                <span class="c1">;</span>
              </li>
              <li class="c5 li-bullet-0">
                <span class="c3">
                  To create aggregated, statistical data, inferred non-personal
                  data or anonymized or pseudonymized data (rendered
                  non-personal), which we or others may use to provide and
                  improve our respective Services, or for any other business
                  purpose such as business intelligence&nbsp;
                </span>
                <span class="c3 c12">(Legitimate Interests)</span>
                <span class="c1">;</span>
              </li>
              <li class="c5 li-bullet-0">
                <span class="c3">
                  To facilitate and optimize our marketing campaigns, ad
                  management and sales operations, and to manage and deliver
                  advertisements for our Services more effectively, including on
                  other websites and applications. Such activities allow us to
                  highlight the benefits of using our Services, and thereby to
                  increase your engagement and overall satisfaction with our
                  Services. This includes contextual, behavioral and
                  interests-based advertising based on User, Prospect and
                  Technology Partner activities, preferences or other data
                  available to us or to our Services Providers (as defined
                  below), and business partners&nbsp;
                </span>
                <span class="c3 c12">(Legitimate Interests; Consent)</span>
                <span class="c1">;</span>
              </li>
              <li class="c5 li-bullet-0">
                <span class="c3">
                  To contact our Customers, Users, Prospects and Technology
                  Partners with general or personalized Services-related
                  messages, as well as promotional messages that may be of
                  specific interest to them&nbsp;
                </span>
                <span class="c3 c12">
                  (Performance of Contract; Legitimate Interests; Consent)
                </span>
                <span class="c1">;</span>
              </li>
              <li class="c5 li-bullet-0">
                <span class="c3">
                  To support and enhance our data security measures, including
                  for the purposes of preventing and mitigating the risks of
                  fraud, error or any illegal or prohibited activity&nbsp;
                </span>
                <span class="c3 c12">
                  (Performance of Contact; Legitimate Interests; Legal
                  Obligation)
                </span>
                <span class="c1">;</span>
              </li>
              <li class="c5 li-bullet-0">
                <span class="c3">
                  To explore and pursue growth opportunities by facilitating a
                  stronger local presence and tailored experiences, including
                  through partnerships with local distributors, resellers,
                  business partners and providers of professional services
                  related to our Services (&ldquo;
                </span>
                <span class="c7">Partners</span>
                <span class="c3">
                  &rdquo;, as further described in Section 4 below)&nbsp;
                </span>
                <span class="c3 c12">(Legitimate Interests)</span>
                <span class="c1">;</span>
              </li>
              <li class="c5 li-bullet-0">
                <span class="c3">
                  To facilitate, sponsor and offer certain events, webinars,
                  contests and promotions&nbsp;
                </span>
                <span class="c3 c12">(Legitimate Interests)</span>
                <span class="c1">;</span>
              </li>
              <li class="c5 li-bullet-0">
                <span class="c3">
                  To publish your feedback and submissions to our Sites, public
                  forums and blogs&nbsp;
                </span>
                <span class="c3 c12">
                  (Performance of Contract; Legitimate Interests)
                </span>
                <span class="c1">;</span>
              </li>
              <li class="c5 li-bullet-0">
                <span class="c3">
                  To comply with our contractual and legal obligations and
                  requirements, and maintain our compliance with applicable
                  laws, regulations and standards&nbsp;
                </span>
                <span class="c3 c12">
                  (Performance of Contract; Legitimate Interests; Legal
                  Obligation)
                </span>
                <span class="c1">; and</span>
              </li>
              <li class="c4 c17 li-bullet-0">
                <span class="c3">
                  For any other lawful purpose, or other purpose that you
                  consent to in connection with provisioning our Services.&nbsp;
                </span>
                <span class="c3 c12">(Legal Obligation; Consent)</span>
                <span class="c1">.</span>
              </li>
            </ul>

            <h2>3. Data Location Retention</h2>

            <p class="c2">
              <span class="c0">Data Location</span>
              <span class="c1">
                : We and our authorized Service Providers (defined below)
                maintain, store and process personal data in the United States
                (US) and other locations as reasonably necessary for the proper
                performance and delivery of our Services, or as may be required
                by applicable law.
              </span>
            </p>
            <p class="c2">
              <span class="c1">
                While privacy laws vary between jurisdictions, Amplify, its
                affiliates and Service Providers are each committed to protect
                personal data in accordance with this Privacy Policy, customary
                and reasonable industry standards,&nbsp;and such appropriate
                lawful mechanisms and contractual terms requiring adequate data
                protection,&nbsp;regardless of any lesser legal requirements
                that may apply in the jurisdiction to which such data is
                transferred.
              </span>
            </p>
            <p class="c2">
              <span class="c3">
                Please note that where Amplify processes personal data on behalf
                of a Customer, such personal data (included in their Customer
                Data) may only be processed in accordance with, and in the
                locations as agreed in our&nbsp;
              </span>
              <span class="c16 c21"><Link to='/data-processing'> Data Processing Addendum</Link></span>
              <span class="c1">
                &nbsp;and other commercial agreements with such Customer (as
                further described in Section 9 below).
              </span>
            </p>
            <p class="c2">
              <span class="c0">Data Retention</span>
              <span class="c3">
                : We may retain your personal data for as long as it is
                reasonably needed to maintain and expand our relationship and
                provide you with our Services and offerings; in order to comply
                with our legal and contractual obligations; or to protect
                ourselves from any potential disputes (e.g. as required by laws
                applicable to log-keeping, records and bookkeeping, and in order
                to have proof and evidence concerning our relationship, should
                any legal issues arise following your discontinuance of use),
                all in accordance with our data retention policy and at our
                reasonable discretion. To determine the appropriate retention
                period for personal data, we consider the amount, nature, and
                sensitivity of such data, the potential risk of harm from
                unauthorized use or disclosure of such data, the purposes for
                which we process it, and the applicable legal requirements. If
                you have any questions about our data retention policy, please
                contact us by email at&nbsp;
              </span>
              <span class="c8">
                <a class="c11" href="mailto:info@amplifyls.com">
                  info@amplifyls.com
                </a>
              </span>
              <span class="c1">.</span>
            </p>

            <h2>4. Data Disclosure</h2>

            <p class="c2">
              <span class="c1">
                We may disclose personal data in the following instances:
              </span>
            </p>
            <p class="c2">
              <span class="c0">Service Providers</span>
              <span class="c3">
                : We engage selected third-party companies and individuals as
                &ldquo;Service Providers&rdquo;, to perform services on our
                behalf or complementary to our own. These include providers of
                Third Party Services (as defined in the&nbsp;
              </span>
              <span class="c21 c16"><Link to='/terms-of-services'>Terms</Link></span>
              <span class="c1">
                ), such as: hosting and server co-location services,
                communications and content delivery networks (CDNs), data and
                cyber security services, billing and payment processing
                services, fraud detection, investigation and prevention
                services, web and mobile analytics, email and communication
                distribution and monitoring services, session or activity
                recording services, call recording, analytics and transcription
                services, event production and hosting services, remote access
                services, performance measurement, data optimization and
                marketing services, social and advertising networks, content,
                lead generating and data enrichment providers, email,
                voicemails, video conferencing solutions, support and customer
                relation management systems, third-party customer support
                providers, and our legal, compliance and financial advisors and
                auditors.
              </span>
            </p>
            <p class="c2">
              <span class="c1">
                Our Service Providers may have access to personal data,
                depending on each of their specific roles and purposes in
                facilitating and enhancing our Services or other activities, and
                may only use the data as determined in our agreements with them.
              </span>
            </p>
            <p class="c2">
              <span class="c0">Partnerships</span>
              <span class="c1">
                : We may engage selected business and channel partners,
                resellers, distributors and providers of professional services
                related to our Services, which allow us to explore and pursue
                growth opportunities by facilitating a stronger local presence
                and tailored experiences for our prospective and existing
                Customers and Users. In such instances, we may disclose relevant
                contact, business and usage details to the respective Partner,
                to allow them to engage with those Customers and Users for such
                purposes. If you directly engage with any of our Partners,
                please note that any aspect of that engagement which is not
                directly related to the Services and directed by Amplify is
                beyond the scope of Amplify&rsquo;s Terms and Privacy Policy,
                and may therefore be governed by the Partner&rsquo;s terms and
                privacy policy.
              </span>
            </p>
            <p class="c2">
              <span class="c0">Application Providers and Event Sponsors</span>
              <span class="c1">
                : If so instructed or permitted by you or your Account Admin, we
                may disclose your personal data (such as your User Profile and
                contact details, as well as relevant usage data) to the
                provider(s) of any third-party applications or integrations
                added to your Account. Similarly, if you register to any event
                that we host, organize or sponsor, then with your permission we
                may disclose your registration details to others, including the
                hosts, organizers, speakers, services providers and sponsors of
                that event, so that they may contact you with relevant
                information and offers, or to fulfill any promotions related to
                the event.
              </span>
            </p>
            <p class="c2">
              <span class="c0">Customers and other Users</span>
              <span class="c1">
                : Your personal data may be disclosed to the Customer owning the
                Account to which you are subscribed as a User (including data
                and communications concerning your User Profile), as well as
                other Users of that Account. Your personal data and activity
                within the Services may also be monitored, processed and
                analyzed by the Account Admin. This includes instances where you
                contact us for help in resolving an issue specific to a team of
                which you are a member (and which is managed by the same
                Customer).
              </span>
            </p>
            <p class="c2">
              <span class="c1">
                If you register or access the Services using an email address at
                a domain that is owned by your employer or organization (our
                Customer), and another team within such Customer&rsquo;s
                organization wishes to establish an account on the Services,
                certain information about you including your name, profile
                picture, contact info and general use of your Account will
                become accessible to the Account Admin and Users.
              </span>
            </p>
            <p class="c2">
              <span class="c0">Services integrations</span>
              <span class="c1">
                : You or your Account Admin may choose to integrate your Account
                on the Services with third-party Services (provided that such
                integration is supported by our Services). The provider of such
                integrated third-party Services may receive certain relevant
                data about or from your Account on the Services, or disclose
                certain relevant data from the account on the third-party
                provider&rsquo;s Services with our Services, depending on the
                nature and purpose of such integration. Note that we do not
                receive or store your passwords for any of these third-party
                Services (but do typically require your API key in order to
                integrate with them). If you do not wish your data to be
                disclosed to such third-party Services(s), please contact your
                Account Admin.
              </span>
            </p>
            <p class="c2">
              <span class="c0">Feedback or Recommendations</span>
              <span class="c3">
                : If you submit a public review or feedback, note that we may
                (at our discretion) store and present your review publicly, on
                our Sites and Services. If you wish to remove your public
                review, please contact us at&nbsp;
              </span>
              <span class="c8">
                <a class="c11" href="mailto:info@amplifyls.com">
                  info@amplifyls.com
                </a>
              </span>
              <span class="c1">
                . If you choose to send others an email or message inviting them
                to use the Services, we may use the contact information you
                provide us to automatically send such invitation email or
                message on your behalf. Your name and email address may be
                included in the invitation email or message.
              </span>
            </p>
            <p class="c2">
              <span class="c0">Community Forum</span>
              <span class="c1">
                :&nbsp;Our Sites may include public blogs or forums, such as
                Amplify Community and Startup for Startup. We also manage and
                participate in various social channels and communities on other
                platforms. Any information you submit on these forums, blogs and
                communities &ndash; including profile information associated
                with the User Profile you use to post the information &ndash;
                may be read, collected, and used by others who access these
                Sites.&nbsp;Due to the nature of such public forums, your posts
                and certain profile information may remain visible to all even
                after you terminate your User Profile.&nbsp;To request removal
                of your information from publicly accessible Sites operated by
                us, please contact us as provided in Section 10 below and note
                the Sites from which you would like your information to be
                removed. In some cases, we may not be able to remove your
                information, in which case we&nbsp;will&nbsp;let you know if we
                are unable to and why.
              </span>
            </p>
            <p class="c2">
              <span class="c0">Legal Compliance</span>
              <span class="c1">
                : In exceptional circumstances, we may disclose or allow
                government and law enforcement officials access to your personal
                data, in response to a subpoena, search warrant or court order
                (or similar requirement), or in compliance with applicable laws
                and regulations. Such disclosure or access may occur if we
                believe in good faith that: (a) we are legally compelled to do
                so; (b) disclosure is appropriate in connection with efforts to
                investigate, prevent, or take action regarding actual or
                suspected illegal activity, fraud, or other wrongdoing; or (c)
                such disclosure is required to protect the security or integrity
                of our&nbsp;products and Services.
              </span>
            </p>
            <p class="c2">
              <span class="c0">Protecting Rights and Safety</span>
              <span class="c1">
                : We may disclose your personal data to others if we believe in
                good faith that this will help protect the rights, property or
                safety of Amplify, any of our Users or Customers, or any members
                of the general public.
              </span>
            </p>
            <p class="c2">
              <span class="c0">Amplify&nbsp;Subsidiaries</span>
              <span class="c1">
                : We disclose personal data internally within our group of
                companies, for the purposes described in this Privacy Policy. In
                addition, should Amplify or any of its subsidiaries undergo any
                change in control, including by means of merger, acquisition or
                purchase of substantially all of its assets, your personal data
                may be disclosed with the parties involved in such an event. If
                we believe that such change in control might materially affect
                your personal data then stored with us, we will notify you of
                this event and the choices you may have via email or prominent
                notice on our Services.
              </span>
            </p>
            <p class="c2">
              <span class="c1">
                For the avoidance of doubt, Amplify may disclose your personal
                data in additional manners, pursuant to your explicit approval,
                if we are legally obligated to do so, or if we have successfully
                rendered such data non-personal and anonymous.
              </span>
            </p>
            <p class="c2">
              <span class="c1">
                For the purposes of the CCPA, in the past twelve (12) months, we
                may have disclosed Identifiers; Professional or
                Employment-Related Information; Internet or other Electronic
                Network Activity Information; Customer Records Information; and
                Geolocation Information to the third parties listed above.
              </span>
            </p>

            <h2>5. Cookies and Tracking Technologies</h2>

            <p class="c2">
              <span class="c3">
                Our Sites and Services (including some of our Services
                Providers) utilize &ldquo;cookies&rdquo;, anonymous identifiers,
                pixels, container tags and other technologies in order for us to
                provide and monitor our Services and Sites, to ensure that they
                perform properly, to analyze our performance and marketing
                activities, and to personalize your experience. Such cookies and
                similar files or tags may also be temporarily placed on your
                device. Certain cookies and other technologies serve to recall
                personal data, such as an IP address, as indicated by a
                Prospect, User or Technology Partner. To learn more about our
                practices concerning cookies and tracking, please see our&nbsp;
              </span>
              <span class="c21 c16"><Link to='/cookie-policy'>Cookie Policy</Link></span>
              <span class="c1">
                . You may also use the &ldquo;Cookie settings&rdquo; feature
                available in our Services depending on your location and
                activity on our Services, as applicable.
              </span>
            </p>
            <p class="c2">
              <span class="c1">
                Please note that we do not change our practices in response to a
                &ldquo;Do Not Track&rdquo; signal in the HTTP header from a
                browser or mobile application, however, most browsers allow you
                to control cookies, including whether or not to accept them and
                how to remove them. You may set most browsers to notify you if
                you receive a cookie, or to block or remove cookies altogether.
              </span>
            </p>

            <h2>6. Communications</h2>

            <p class="c2">
              <span class="c1">
                We engage in Services and promotional communications, through
                email, phone, SMS and notifications.
              </span>
            </p>
            <p class="c2">
              <span class="c0">Services Communications</span>
              <span class="c1">
                : We may contact you with important information regarding our
                Services. For example, we may send you notifications (through
                any of the means available to us) of changes or updates to our
                Services, billing issues, log-in attempts or password reset
                notices, etc. Our Customers, and other Users on the same
                Account, may also send you notifications, messages and other
                updates regarding their or your use of the Services. You can
                control your communications and notifications settings from your
                User Profile settings, or otherwise in accordance with the
                instructions that may be included in the communications sent to
                you. However, please note that you will not be able to opt-out
                of receiving certain Services communications which are integral
                to your use (like password resets or billing notices).
              </span>
            </p>
            <p class="c2">
              <span class="c0">Promotional Communications</span>
              <span class="c3">
                : We may also notify you about new features, additional
                offerings, events and special opportunities or any other
                information we think you will find valuable, as our Customer,
                User, Prospect or Technology Partner. We may provide such
                notices through any of the contact means available to us (e.g.
                phone, mobile or email), through the Services, or through our
                marketing campaigns on any other sites or platforms. If you do
                not wish to receive such promotional communications, you may
                notify Amplify at any time by sending an email to{" "}
              </span>
              <span class="c8">
                <a class="c11" href="mailto:info@amplifyls.com">
                  info@amplifyls.com
                </a>
              </span>
              <span class="c1">
                , changing your communications preferences in your User Profile
                settings,&nbsp;or by following the &ldquo;unsubscribe&rdquo;,
                &ldquo;stop&rdquo;, &ldquo;opt-out&rdquo; or &ldquo;change email
                preferences&rdquo; instructions contained in the promotional
                communications you receive.
              </span>
            </p>

            <h2>7. Data Security</h2>

            <p class="c2">
              <span class="c1">
                In order to protect your personal data held with us, we use
                industry-standard physical, procedural and technical security
                measures, including encryption as appropriate. However, please
                be aware that regardless of any security measures used, we
                cannot and do not guarantee the absolute protection and security
                of any personal data stored with us or with any third parties as
                described in Section 4 above.
              </span>
            </p>

            <h2>8. Data Subject Rights</h2>

            <p class="c2">
              <span class="c3">
                If you wish to exercise your privacy rights under applicable law
                (including the EU or UK GDPR, Swiss Federal Data Protection Act
                or the CCPA), such as (each to the extent applicable to you
                under the laws which apply to you) &ndash; the right to
                know/request access to (specific pieces of personal data
                collected; categories of personal data collected; categories of
                sources from whom the personal data was collected; purpose of
                collecting personal data; categories of third parties with whom
                we have disclosed personal data), to request rectification or
                erasure of your personal data held with Amplify, or to restrict
                or object to such personal data&rsquo;s processing (including
                the right to direct us not to sell your personal data to third
                parties now or in the future), or to obtain a copy or port such
                personal data, or the right to equal Services and prices (e.g.
                freedom from discrimination) &ndash; please contact us by email
                at&nbsp;
              </span>
              <span class="c8">
                <a class="c11" href="mailto:info@amplifyls.com">
                  info@amplifyls.com
                </a>
              </span>
              <span class="c1">
                . If you are a GDPR-protected individual, you also have the
                right to lodge a complaint with the relevant supervisory
                authority in the EEA or the UK, as applicable.
              </span>
            </p>
            <p class="c2">
              <span class="c1">
                You may designate an authorized agent, in writing or through a
                power of attorney, to request to exercise your privacy rights on
                your behalf. The authorized agent may submit a request to
                exercise these rights by emailing us. In such cases, we may
                request further information to verify such power of attorney and
                authorization.
              </span>
            </p>
            <p class="c2">
              <span class="c1">
                Please note that when you ask us to exercise any of your rights
                under this Privacy Policy or applicable law, we may instruct you
                on how to fulfill your request independently through your User
                Profile settings; refer you to your Account Admin; or require
                additional information and documents, including certain personal
                data and credentials in order to process your request in a
                proper manner (e.g. in order to authenticate and validate your
                identity so that we know which data in our systems relates to
                you, and where necessary, to better understand the nature and
                scope of your request). Such additional information will be then
                retained by us for legal purposes (e.g. as proof of the identity
                of the person submitting the request, and of how each request
                was handled), in accordance with Section 3 above.
              </span>
            </p>
            <p class="c2">
              <span class="c1">
                We may redact from the data which we make available to you, any
                personal or confidential data related to others.
              </span>
            </p>

            <h2>9. Data Controller/Processor</h2>

            <p class="c2">
              <span class="c1">
                Certain data protection laws and regulations, such as the GDPR
                or the CCPA, typically distinguish between two main roles for
                parties processing personal data: the &ldquo;data
                controller&rdquo; (or under the CCPA, &ldquo;business&rdquo;),
                who determines the purposes and means of processing; and the
                &ldquo;data processor&rdquo; (or under the CCPA, &ldquo;service
                provider&rdquo;), who processes such data on behalf of the data
                controller (or business). Below we explain how these roles apply
                to our Services, to the extent that such laws and regulations
                apply.
              </span>
            </p>
            <p class="c2">
              <span class="c1">
                Ampfliy LS LLC is the &ldquo;data controller&rdquo; of its
                Prospects&rsquo;, Users&rsquo;, Technology Partner&rsquo;s and
                Customers&rsquo; personal data, as detailed in Section 1 above.
                Accordingly, we assume the responsibilities of a data controller
                (solely to the extent applicable under law), as set forth in
                this Privacy Policy.
              </span>
            </p>
            <p class="c2">
              <span class="c3">
                Amplify is the &ldquo;data processor&rdquo; of personal data
                contained in Customer Data, as submitted by our Customers and
                their Users to their Account&rsquo;s boards, items and docs. We
                process such data on behalf of our Customer (who is the
                &ldquo;data controller&rdquo; of such data) and in accordance
                with its reasonable instructions, subject to our Terms,
                our&nbsp;
              </span>
              <span class="c21 c16"><Link to='/data-processing'>Data Processing Addendum</Link></span>
              <span class="c1">
                &nbsp;(to the extent applicable) and other commercial agreements
                with such Customer.
              </span>
            </p>
            <p class="c2">
              <span class="c1">
                Our Customers are solely responsible for determining whether and
                how they wish to use our Services, and for ensuring that all
                individuals using the Services on the Customer&rsquo;s behalf or
                at their request, as well as all individuals whose personal data
                may be included in Customer Data processed through the Services,
                have been provided with adequate notice and given informed
                consent to the processing of their personal data, where such
                consent is necessary or advised, and that all legal requirements
                applicable to the collection, use or other processing of data
                through our Services are fully met by the Customer. Our
                Customers are also responsible for handling data subject rights
                requests under applicable law, by their Users and other
                individuals whose data they process through the Services.
              </span>
            </p>
            <p class="c2">
              <span class="c1">
                If you would like to make any requests or queries regarding
                personal data we process as a data processor on our
                Customer&rsquo;s behalf, including accessing, correcting or
                deleting your data, please contact the Customer&rsquo;s Account
                Admin directly.
              </span>
            </p>

            <h2>10. Additional Notices</h2>

            <p class="c2">
              <span class="c0">Updates and Amendments</span>
              <span class="c1">
                : We may update and amend this Privacy Policy from time to time
                by posting an amended version on our Services. The amended
                version will be effective as of the date it is published. When
                we make material changes to this Privacy Policy, we will give
                notice as appropriate under the circumstances, e.g., by
                displaying a prominent notice within the Services or by sending
                an email. Your continued use of the Services after the changes
                have been implemented will constitute your acceptance of the
                changes.
              </span>
            </p>
            <p class="c2">
              <span class="c0">Accessibility</span>
              <span class="c3">
                : Upon request, this Privacy Policy can be read by a screen
                reader. For more information, please contact&nbsp;
              </span>
              <span class="c8">
                <a class="c11" href="mailto:info@amplifyls.com">
                  info@amplifyls.com
                </a>
              </span>
              <span class="c1">.</span>
            </p>
            <p class="c2">
              <span class="c0">US State Law Requirements</span>
              <span class="c3">
                : This Privacy Policy describes the categories of personal
                information we may collect and the sources of such information
                (in Section
              </span>
              <span class="c13">&lrm;</span>
              <span class="c3">1 above), and our retention (Section </span>
              <span class="c13">&lrm;</span>
              <span class="c3">
                3) and deletion (Section 8) practices. We also included
                information about how we may process your information (in
                Sections{" "}
              </span>
              <span class="c13">&lrm;</span>
              <span class="c3">1 through </span>
              <span class="c13">&lrm;</span>
              <span class="c3">
                7), which includes &ldquo;business purposes&rdquo; under the
                CCPA. We do not sell your personal information for the intents
                and purposes of CCPA. We do share and disclose personal data to
                third parties or allow them to collect personal data from our
                Services as described in Sections{" "}
              </span>
              <span class="c13">&lrm;</span>
              <span class="c1">
                4 and 5 above, if those third parties are authorized Service
                Providers or business partners who have agreed to our
                contractual limitations as to their retention, use, and
                disclosure of such personal data, or if you integrate the
                Services of third parties with our Services, or direct us to
                disclose your personal data to third parties, or as otherwise
                described in Section 4 above.
              </span>
            </p>
            <p class="c2">
              <span class="c3">
                If you have any questions or would like to exercise your rights
                under the CCPA, you can contact&nbsp;
              </span>
              <span class="c8">
                <a class="c11" href="mailto:info@amplifyls.com">
                  info@amplifyls.com
                </a>
              </span>
              <span class="c1">.</span>
            </p>
            <p class="c2">
              <span class="c0">Third Party Websites and Services</span>
              <span class="c3">
                : Our Services includes links to third party websites and
                services, and integrations with Third Party Services (as defined
                in the&nbsp;
              </span>
              <span class="c21 c16"><Link to='/terms-of-services'>Terms</Link></span>
              <span class="c1">
                ). Such websites, services and Third Party Services, and any
                information you process, submit, transmit or otherwise use with
                or to such websites, services and Third Party Services, are
                governed by such third party&rsquo;s terms and privacy practices
                and policies, and not by this Privacy Policy. We encourage you
                to carefully read the terms and privacy policies of such
                websites, services and Third Party Services.
              </span>
            </p>
            <p class="c2">
              <span class="c0">
                Our Services are not directed to children under the age of 16
              </span>
              <span class="c3">
                : We do not knowingly collect personal data from children and do
                not wish to do so. If we learn that a person under the age of 16
                is using the Services, we will attempt to prohibit and block
                such use and will make our best efforts to promptly delete any
                personal data stored with us with regard to such child. If you
                believe that we might have any such data, please contact us by
                email at&nbsp;
              </span>
              <span class="c8">
                <a class="c11" href="mailto:info@amplifyls.com">
                  info@amplifyls.com
                </a>
              </span>
              <span class="c1">.</span>
            </p>
            <p class="c2">
              <span class="c0">Questions, concerns or general complaints</span>
              <span class="c3">
                : If you have any comments or questions regarding our privacy
                policy or practices, or if you have any concerns regarding your
                personal data held with us, or if you wish to make a complaint
                about how your personal data is being processed by Amplify,
                please contact Amplify&rsquo;s support at&nbsp;
              </span>
              <span class="c8">
                <a class="c11" href="mailto:info@amplifyls.com">
                  info@amplifyls.com
                </a>
              </span>
              <span class="c1">.</span>
            </p>
            <p class="c9">
              <span class="c14"></span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Privacy;
